import {
  ButtonV2,
  CheckBoxV2,
  ChipButton,
  ColWidth,
  Component,
  Header,
  MoreHorizMenuForTable,
  PAGINATE_HEIGHT,
  PageHeaderV2,
  SearchListV2,
  SelectBoxV2,
  TextFieldV2,
  styleForFullExpansion,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  AuthorityCategoryDef,
  AuthorityDefCategory,
  BaseAuthority,
  BaseDto,
  BaseFunctionToggleCode,
  Identification,
  SkillTag,
  User,
  UserSortColumn,
  UserType,
  WishTag,
  WithBaseId,
  isBaseFunctionToggleEnabled,
  toFullName
} from '@atomica.co/irori';
import { DateStr, Gap, Index, Name, Offset, Phone, Sort, Time, UserId, Word } from '@atomica.co/types';
import {
  EMPTY,
  FIRST_INDEX,
  builder,
  embedIdInPath,
  hasLength,
  isLessThanZero,
  isUndefined,
  noop,
  setTimeToDate,
  toBeginningOfDay,
  toEndOfDay
} from '@atomica.co/utils';
import { Link } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { addDays, format } from 'date-fns';
import React, { Key, useEffect, useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import styled from 'styled-components';
import { SearchUsersForWishForAdminParams } from '../../__generated/model';
import SkillTags from '../../components/tags/SkillTags';
import WishTags from '../../components/tags/WishTags';
import { USER_ACTION_OPTIONS } from '../../constants/user-v2-const';
import { UserAction } from '../../enums/users-enum';
import { Labels } from '../../models/common-model';
import useCachedUserList from '../../redux/hooks/useCachedUserList';
import usePath from '../../redux/hooks/usePath';
import useSearchedUsers from '../../redux/hooks/useSearchedUsers';
import { PATH_IDS, Path } from '../../router/Routes';
import { IDENTIFICATION_LABELS, USER_ACTION_LABEL, USER_TYPE_LABELS } from '../../texts/user-text';
import { getBasePICV2 } from '../../utils/user-util';
import RegisterPersonInChargeModal from './modal/RegisterPersonInChargeModal';

const LIMIT = 50;

const COLUMN_WIDTH: ColWidth = { name: 100, phone: 100, email: 200, authority: 120 };

const HEADER: Header = {
  name: { label: '名前' },
  phone: { label: '電話番号' },
  email: { label: 'メールアドレス' },
  authority: { label: '権限' }
};

enum CHECKIN_DATE_CHIPS_ENUM {
  TODAY = 'today',
  YEASTERDAY = 'yeasterday'
}

interface UserRow {
  id: UserId;
  name: Name;
  phone: Phone;
  email: Name;
  authority: Name;
}

interface P {
  base: BaseDto;
  user: User;
}

const DEFAULT_FILTER_CONDITIONS = {
  userWord: EMPTY,
  userTypes: [],
  identifications: [],
  editableSkillTags: false,
  skillTags: [],
  wishWord: EMPTY,
  introducingOtherWord: EMPTY,
  editableWishTags: false,
  wishTags: [],
  todaySelected: false,
  yesterdaySelected: false,
  startDate: EMPTY,
  startTime: EMPTY,
  endDate: EMPTY,
  endTime: EMPTY,
  companyName: EMPTY,
  searchingWord: EMPTY
};

const UserListV2Screen: React.FC<P> = React.memo(props => {
  const { base } = props;

  const unmountRef = useUnmountRef();
  const [searchCond, setSearchCond] = useSafeState<WithBaseId<SearchUsersForWishForAdminParams>>(unmountRef, {
    baseId: base.baseId,
    limit: LIMIT,
    offset: 0
  });

  const { searchedUsers: users, totalCount: totalRecordCount, search } = useSearchedUsers();

  const rows = useMemo(() => {
    return users.map(user => {
      const personInCharges = getBasePICV2(user, base, AuthorityDefCategory.USER_AUTHORITY);
      return builder<UserRow>()
        .id(user.userId)
        .name(toFullName(user))
        .phone(user.phone || EMPTY)
        .email(user.email)
        .authority(
          hasLength(personInCharges) && personInCharges[FIRST_INDEX].authority
            ? personInCharges[FIRST_INDEX].authority.authorityName
            : '未設定'
        )
        .build();
    });
  }, [base, users]);

  // Facet
  const [userTypes, setUserTypes] = useSafeState<UserType[]>(unmountRef, DEFAULT_FILTER_CONDITIONS.userTypes);
  const [identifications, setIdentifications] = useSafeState<Identification[]>(
    unmountRef,
    DEFAULT_FILTER_CONDITIONS.identifications
  );
  const [editableSkillTags, setEditableSkillTags] = useSafeState<boolean>(
    unmountRef,
    DEFAULT_FILTER_CONDITIONS.editableSkillTags
  );
  const [skillTags, setSkillTags] = useSafeState<SkillTag[]>(unmountRef, DEFAULT_FILTER_CONDITIONS.skillTags);
  const [wishWord, setWishWord] = useSafeState<Word>(unmountRef, DEFAULT_FILTER_CONDITIONS.wishWord);
  const [introducingOtherWord, setIntroducingOtherWord] = useSafeState<Word>(
    unmountRef,
    DEFAULT_FILTER_CONDITIONS.introducingOtherWord
  );
  const [editableWishTags, setEditableWishTags] = useSafeState<boolean>(
    unmountRef,
    DEFAULT_FILTER_CONDITIONS.editableWishTags
  );
  const [wishTags, setWishTags] = useSafeState<WishTag[]>(unmountRef, DEFAULT_FILTER_CONDITIONS.wishTags);
  const [startDate, setStartDate] = useSafeState<DateStr>(unmountRef, DEFAULT_FILTER_CONDITIONS.startDate);
  const [startTime, setStartTime] = useSafeState<Time>(unmountRef, DEFAULT_FILTER_CONDITIONS.startTime);
  const [endDate, setEndDate] = useSafeState<DateStr>(unmountRef, DEFAULT_FILTER_CONDITIONS.endDate);
  const [endTime, setEndTime] = useSafeState<Time>(unmountRef, DEFAULT_FILTER_CONDITIONS.endTime);
  const [companyName, setCompanyName] = useSafeState<Name>(unmountRef, DEFAULT_FILTER_CONDITIONS.companyName);
  const [searchingWord, setSearchingWord] = useSafeState<Word>(unmountRef, DEFAULT_FILTER_CONDITIONS.searchingWord);
  const isResetFilterButtonShown = useMemo<boolean>(() => {
    const isSameDefaultCondition =
      shallowEqual(userTypes, DEFAULT_FILTER_CONDITIONS.userTypes) &&
      shallowEqual(identifications, DEFAULT_FILTER_CONDITIONS.identifications) &&
      shallowEqual(editableSkillTags, DEFAULT_FILTER_CONDITIONS.editableSkillTags) &&
      shallowEqual(skillTags, DEFAULT_FILTER_CONDITIONS.skillTags) &&
      shallowEqual(wishWord, DEFAULT_FILTER_CONDITIONS.wishWord) &&
      shallowEqual(introducingOtherWord, DEFAULT_FILTER_CONDITIONS.introducingOtherWord) &&
      shallowEqual(editableWishTags, DEFAULT_FILTER_CONDITIONS.editableWishTags) &&
      shallowEqual(wishTags, DEFAULT_FILTER_CONDITIONS.wishTags) &&
      shallowEqual(startDate, DEFAULT_FILTER_CONDITIONS.startDate) &&
      shallowEqual(startTime, DEFAULT_FILTER_CONDITIONS.startTime) &&
      shallowEqual(endDate, DEFAULT_FILTER_CONDITIONS.endDate) &&
      shallowEqual(endTime, DEFAULT_FILTER_CONDITIONS.endTime) &&
      shallowEqual(companyName, DEFAULT_FILTER_CONDITIONS.companyName) &&
      shallowEqual(searchingWord, DEFAULT_FILTER_CONDITIONS.searchingWord);
    return !isSameDefaultCondition;
  }, [
    userTypes,
    identifications,
    editableSkillTags,
    skillTags,
    wishWord,
    introducingOtherWord,
    editableWishTags,
    wishTags,
    startDate,
    startTime,
    endDate,
    endTime,
    companyName,
    searchingWord
  ]);

  const generateCheckInDateCondition = useSafeCallback(
    (
      checkInDate: CHECKIN_DATE_CHIPS_ENUM
    ): { startDateStr: DateStr; startTimeStr: DateStr; endDateStr: DateStr; endTimeStr: DateStr } => {
      const date = addDays(new Date(), checkInDate === CHECKIN_DATE_CHIPS_ENUM.YEASTERDAY ? -1 : 0);
      const beginningOfDay = toBeginningOfDay(new Date(date))!;
      const endOfDay = toEndOfDay(new Date(date))!;

      const startDateStr = format(beginningOfDay, 'yyyy/MM/dd');
      const startTimeStr = format(beginningOfDay, 'HH:mm');
      const endDateStr = format(endOfDay, 'yyyy/MM/dd');
      const endTimeStr = format(endOfDay, 'HH:mm');
      return {
        startDateStr,
        startTimeStr,
        endDateStr,
        endTimeStr
      };
    },
    []
  );

  const isSelectedCheckInDateButton = useSafeCallback(
    (checkInDate: CHECKIN_DATE_CHIPS_ENUM): boolean => {
      const { startDateStr, startTimeStr, endDateStr, endTimeStr } = generateCheckInDateCondition(checkInDate);
      return (
        startDate === startDateStr && startTime === startTimeStr && endDate === endDateStr && endTime === endTimeStr
      );
    },
    [generateCheckInDateCondition, startDate, startTime, endDate, endTime]
  );

  const todaySelected = useMemo<boolean>(
    () => isSelectedCheckInDateButton(CHECKIN_DATE_CHIPS_ENUM.TODAY),
    [isSelectedCheckInDateButton]
  );
  const yesterdaySelected = useMemo<boolean>(
    () => isSelectedCheckInDateButton(CHECKIN_DATE_CHIPS_ENUM.YEASTERDAY),
    [isSelectedCheckInDateButton]
  );

  // List
  const { openPath, openPathInNewTab } = usePath();
  const { saveCachedUserList } = useCachedUserList();
  const [sortKey, setSortKey] = useSafeState<UserSortColumn>(unmountRef, UserSortColumn.PERSON_IN_CHARGE);
  const [sort, setSort] = useSafeState<Sort>(unmountRef, Sort.ASC);
  const [offset, setOffset] = useSafeState<Offset>(unmountRef, 0);
  const [selectedUserIds, setSelectedUserIds] = useSafeState<UserId[]>(unmountRef, []);
  const [selectedUsers, setSelectedUsers] = useSafeState<User[]>(unmountRef, []);
  const [selectedAction, setSelectedAction] = useSafeState<UserAction>(unmountRef, UserAction.OPERATE_ALL);
  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, false);
  const [isSelectedAll, setIsSelectedAll] = useSafeState<boolean>(unmountRef, false);
  const [isModalOpen, setIsModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [categoryCode, setCategoryCode] = useSafeState<AuthorityDefCategory>(
    unmountRef,
    AuthorityDefCategory.USER_AUTHORITY
  );

  const baseAuthorities = useMemo<BaseAuthority[]>(() => {
    if (!base || !base.authorities) return [];
    return base.authorities.filter(auth => auth.authorityDef?.category?.categoryCode === categoryCode);
  }, [base, categoryCode]);

  const resourceAuthorities = useMemo<BaseAuthority[]>(() => {
    if (!hasLength(baseAuthorities)) return [];
    return baseAuthorities.filter(
      auth => auth.authorityDef?.category?.categoryCode === AuthorityDefCategory.RESOURCE_AUTHORITY
    );
  }, [baseAuthorities]);

  const category = useMemo<AuthorityCategoryDef | undefined>(() => {
    if (!hasLength(baseAuthorities)) return;
    return baseAuthorities[FIRST_INDEX].authorityDef?.category;
  }, [baseAuthorities]);

  const dateError = useMemo<boolean>(() => {
    return startDate > endDate && startDate && endDate ? true : false;
  }, [startDate, endDate]);

  const timeError = useMemo<boolean>(() => {
    return startDate && endDate && startTime && endTime && startDate === endDate && startTime > endTime ? true : false;
  }, [startDate, endDate, startTime, endTime]);

  // Facet Function
  const handleSearchClicked = useSafeCallback(() => {
    const startOfLastCheckInDate =
      !!startDate && !!startTime ? setTimeToDate(new Date(startDate), startTime) : undefined;
    const endOfLastCheckInDate = !!endDate && !!endTime ? setTimeToDate(new Date(endDate), endTime) : undefined;

    const searchCond = builder<WithBaseId<SearchUsersForWishForAdminParams>>()
      .baseId(base.baseId)
      .userWord(searchingWord)
      .userTypes(userTypes)
      .identifications(identifications)
      .skillTagIds(skillTags.map(tag => tag.skillTagId))
      .wishWord(wishWord)
      .introducingOtherWord(introducingOtherWord)
      .wishTagIds(wishTags.map(tag => tag.wishTagId))
      .startOfLastCheckInDate(startOfLastCheckInDate!)
      .endOfLastCheckInDate(endOfLastCheckInDate!)
      .companyName(companyName)
      .picWord(EMPTY)
      .build();
    setSelectedUserIds([]);
    setSelectedUsers([]);
    setOffset(0);
    setSearchCond(searchCond);
  }, [
    base.baseId,
    companyName,
    endDate,
    endTime,
    identifications,
    introducingOtherWord,
    searchingWord,
    setOffset,
    setSearchCond,
    setSelectedUserIds,
    setSelectedUsers,
    skillTags,
    startDate,
    startTime,
    userTypes,
    wishTags,
    wishWord
  ]);

  const handleTypeClicked = useSafeCallback(
    (typeToToggle: UserType) => {
      setUserTypes(prevTypes => {
        const prevType = prevTypes.find(type => type === typeToToggle);
        return prevType ? prevTypes.filter(type => type !== typeToToggle) : [...prevTypes, typeToToggle];
      });
    },
    [setUserTypes]
  );

  const handleIdentificationClicked = useSafeCallback(
    (identificationToToggle: Identification) => {
      setIdentifications(prevIdentifications => {
        const prevIdentification = prevIdentifications.find(
          identification => identification === identificationToToggle
        );
        return prevIdentification
          ? prevIdentifications.filter(identification => identification !== identificationToToggle)
          : [...prevIdentifications, identificationToToggle];
      });
    },
    [setIdentifications]
  );

  const handleSkillTagSelected = useSafeCallback((): void => {
    setEditableSkillTags(editable => {
      editable && setSkillTags([]);
      return !editable;
    });
  }, [setEditableSkillTags, setSkillTags]);

  const handleWishTagSelected = useSafeCallback((): void => {
    setEditableWishTags(editable => {
      editable && setWishTags([]);
      return !editable;
    });
  }, [setEditableWishTags, setWishTags]);

  const handleCheckinDateChipClicked = useSafeCallback(
    (checkInDate: CHECKIN_DATE_CHIPS_ENUM, selected: boolean): void => {
      const { startDateStr, startTimeStr, endDateStr, endTimeStr } = generateCheckInDateCondition(checkInDate);
      setStartDate(selected ? startDateStr : EMPTY);
      setStartTime(selected ? startTimeStr : EMPTY);
      setEndDate(selected ? endDateStr : EMPTY);
      setEndTime(selected ? endTimeStr : EMPTY);
    },
    [generateCheckInDateCondition, setStartDate, setStartTime, setEndDate, setEndTime]
  );

  const handleClickSortTh = useSafeCallback(
    (sortKey: Key, sort: Sort): void => {
      setSortKey(sortKey as UserSortColumn);
      setSort(sort);
    },
    [setSortKey, setSort]
  );

  const handleClickRow = useSafeCallback(
    (index: Index): void => {
      const userId: UserId = users[index].userId;
      openPathInNewTab(embedIdInPath(Path.USER_OVERVIEW, PATH_IDS, [base.baseCode, userId]));
    },
    [users, base, openPathInNewTab]
  );

  const searchUsers = useSafeCallback(async (): Promise<void> => {
    setIsLoaderShown(true);
    const request = builder<WithBaseId<SearchUsersForWishForAdminParams>>()
      .baseId(base.baseId)
      .limit(LIMIT)
      .offset(offset)
      .userWord(searchCond.userWord || EMPTY)
      .userTypes(searchCond.userTypes || [])
      .identifications(searchCond.identifications || [])
      .skillTagIds(searchCond.skillTagIds || [])
      .wishWord(searchCond.wishWord || EMPTY)
      .wishTagIds(searchCond.wishTagIds || [])
      .introducingOtherWord(searchCond.introducingOtherWord || EMPTY)
      .startOfLastCheckInDate(searchCond.startOfLastCheckInDate!)
      .endOfLastCheckInDate(searchCond.endOfLastCheckInDate!)
      .companyName(searchCond.companyName || EMPTY)
      .picWord(searchCond.picWord || EMPTY)
      .build();

    await search(request);

    setIsLoaderShown(false);
  }, [
    setIsLoaderShown,
    base.baseId,
    offset,
    searchCond.userWord,
    searchCond.userTypes,
    searchCond.identifications,
    searchCond.skillTagIds,
    searchCond.wishWord,
    searchCond.wishTagIds,
    searchCond.introducingOtherWord,
    searchCond.startOfLastCheckInDate,
    searchCond.endOfLastCheckInDate,
    searchCond.companyName,
    searchCond.picWord,
    search
  ]);

  useEffect(() => {
    searchUsers();
  }, [searchUsers]);

  useEffect(() => {
    saveCachedUserList({
      users: selectedUsers,
      selectedUserIds: selectedUserIds,
      offset,
      searchingWord,
      sortKey,
      sort,
      totalRecordCount
    });
  }, [
    offset,
    selectedUserIds,
    saveCachedUserList,
    users,
    searchingWord,
    sortKey,
    sort,
    totalRecordCount,
    selectedUsers
  ]);

  useEffect(() => {
    setSelectedUsers(selectedUsers => {
      const existingSelectedUsers = [...selectedUsers];
      selectedUserIds.forEach(selectedUserId => {
        if (existingSelectedUsers.every(selectedUser => selectedUser.userId !== selectedUserId)) {
          const userToAdd = users.find(user => user.userId === selectedUserId);
          if (userToAdd) existingSelectedUsers.push(userToAdd);
        }
      });
      const newSelectedUsers = existingSelectedUsers.filter(user => selectedUserIds.includes(user.userId));
      return newSelectedUsers;
    });
  }, [selectedUserIds, users, setSelectedUsers]);

  const handleActionSelected = useSafeCallback(
    (selectedAction: UserAction) => {
      setSelectedAction(selectedAction);
      switch (selectedAction) {
        case UserAction.DETAIL_ALL:
          openPath(embedIdInPath(Path.USER_BULK_UPDATE, PATH_IDS, [base.baseCode, selectedUserIds[0]]));
          break;
        case UserAction.UPDATE_USER_AUTHORITY:
          setIsModalOpen(true);
          setCategoryCode(AuthorityDefCategory.USER_AUTHORITY);
          break;
        case UserAction.UPDATE_RANK:
          setIsModalOpen(true);
          setCategoryCode(AuthorityDefCategory.RANK);
          break;
        case UserAction.UPDATE_RESOURCE_AUTHORITY:
          setIsModalOpen(true);
          setCategoryCode(AuthorityDefCategory.RESOURCE_AUTHORITY);
          break;
        // case UserAction.DELETE_ALL:
        //   noop();
        //   break;
        case UserAction.OPERATE_ALL:
        default:
          noop();
      }
    },
    [base, openPath, selectedUserIds, setIsModalOpen, setCategoryCode, setSelectedAction]
  );

  const moreHorizMenusForTable = useMemo<MoreHorizMenuForTable[]>(() => [], []);

  const handleModalClosed = useSafeCallback(async (): Promise<void> => {
    setIsModalOpen(false);
    setSelectedAction(UserAction.OPERATE_ALL);
    await searchUsers();
  }, [searchUsers, setIsModalOpen, setSelectedAction]);

  const [userActionOptions, userActionLabel] = useMemo<[UserAction[], Labels]>(() => {
    const tempUserActionOptions: UserAction[] = USER_ACTION_OPTIONS;
    const tempUserActionLabel: Labels = USER_ACTION_LABEL;

    if (!isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USER_RANK)) {
      if (!isLessThanZero(tempUserActionOptions.indexOf(UserAction.UPDATE_RANK))) {
        tempUserActionOptions.splice(tempUserActionOptions.indexOf(UserAction.UPDATE_RANK), 1);
      }
      delete tempUserActionLabel[UserAction.UPDATE_RANK];
    }

    if (
      !isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_RESOURCE_AUTHORITY) ||
      !hasLength(resourceAuthorities)
    ) {
      if (!isLessThanZero(tempUserActionOptions.indexOf(UserAction.UPDATE_RESOURCE_AUTHORITY))) {
        tempUserActionOptions.splice(tempUserActionOptions.indexOf(UserAction.UPDATE_RESOURCE_AUTHORITY), 1);
      }
      delete tempUserActionLabel[UserAction.UPDATE_RESOURCE_AUTHORITY];
    }
    return [tempUserActionOptions, tempUserActionLabel];
  }, [base, resourceAuthorities]);

  const handleResetFilterButtonClicked = useSafeCallback((): void => {
    setUserTypes(DEFAULT_FILTER_CONDITIONS.userTypes);
    setIdentifications(DEFAULT_FILTER_CONDITIONS.identifications);
    setEditableSkillTags(DEFAULT_FILTER_CONDITIONS.editableSkillTags);
    setSkillTags(DEFAULT_FILTER_CONDITIONS.skillTags);
    setWishWord(DEFAULT_FILTER_CONDITIONS.wishWord);
    setIntroducingOtherWord(DEFAULT_FILTER_CONDITIONS.introducingOtherWord);
    setEditableWishTags(DEFAULT_FILTER_CONDITIONS.editableWishTags);
    setWishTags(DEFAULT_FILTER_CONDITIONS.wishTags);
    setStartDate(DEFAULT_FILTER_CONDITIONS.startDate);
    setStartTime(DEFAULT_FILTER_CONDITIONS.startTime);
    setEndDate(DEFAULT_FILTER_CONDITIONS.endDate);
    setEndTime(DEFAULT_FILTER_CONDITIONS.endTime);
    setCompanyName(DEFAULT_FILTER_CONDITIONS.companyName);
    setSearchingWord(DEFAULT_FILTER_CONDITIONS.searchingWord);
  }, [
    setUserTypes,
    setIdentifications,
    setEditableSkillTags,
    setSkillTags,
    setWishWord,
    setIntroducingOtherWord,
    setEditableWishTags,
    setWishTags,
    setStartDate,
    setStartTime,
    setEndDate,
    setEndTime,
    setCompanyName,
    setSearchingWord
  ]);

  return (
    <Component style={styleForFullExpansion} className='user-v2-list-screen'>
      <Container data-testid='user-v2-list-screen'>
        <FacetContent>
          <Facet>
            <HeaderWrapper>
              <Heading>フィルター</Heading>
              {isResetFilterButtonShown && (
                <ResetFilterButton as='button' onClick={handleResetFilterButtonClicked}>
                  条件解除
                </ResetFilterButton>
              )}
            </HeaderWrapper>
            <Filter data-testid='user-type'>
              <FilterHeading>タイプ</FilterHeading>
              <CheckboxGroup>
                {Object.values(UserType).map((type, index) => (
                  <CheckBoxV2
                    key={`checkbox-${index}`}
                    checked={userTypes.includes(type)}
                    onChange={() => handleTypeClicked(type)}
                  >
                    <FilterText>{USER_TYPE_LABELS[type]}</FilterText>
                  </CheckBoxV2>
                ))}
              </CheckboxGroup>
            </Filter>
            {isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USER_IDENTIFICATION) && (
              <Filter data-testid='identification'>
                <FilterHeading>本人確認状況</FilterHeading>
                <CheckboxGroup>
                  {Object.values(Identification).map((identification, index) => (
                    <CheckBoxV2
                      key={`checkbox-${index}`}
                      checked={identifications.includes(identification)}
                      onChange={() => handleIdentificationClicked(identification)}
                    >
                      <FilterText>{IDENTIFICATION_LABELS[identification]}</FilterText>
                    </CheckBoxV2>
                  ))}
                </CheckboxGroup>
              </Filter>
            )}
            <Filter gap={0} data-testid='skill-tags-wrapper'>
              <FilterHeading>得意なこと</FilterHeading>
              <ChipButton
                variant='outlined'
                label='タグで検索'
                icon={<StyledAddIcon selected={editableSkillTags} />}
                selected={editableSkillTags}
                onClick={handleSkillTagSelected}
              />
              {editableSkillTags && (
                <SkillTags editable base={base} skillTags={skillTags} setSkillTags={setSkillTags} />
              )}
            </Filter>
            <Filter data-testid='wish-tags-wrapper'>
              <FilterHeading>Wish</FilterHeading>
              <TextFieldV2 placeholder='テキスト検索' onChange={setWishWord} value={wishWord} />
              <ChipButton
                variant='outlined'
                label='タグで検索'
                icon={<StyledAddIcon selected={editableWishTags} />}
                selected={editableWishTags}
                onClick={handleWishTagSelected}
              />
              {editableWishTags && <WishTags base={base} editable wishTags={wishTags} setWishTags={setWishTags} />}
            </Filter>
            <Filter data-testid='introducing-other'>
              <FilterHeading>他己紹介</FilterHeading>
              <TextFieldV2 value={introducingOtherWord} placeholder='テキスト検索' onChange={setIntroducingOtherWord} />
            </Filter>
            <Filter gap={0} data-testid='check-in-date'>
              <FilterHeading>最終チェックイン</FilterHeading>
              <ChipsWrapper>
                <ChipButton
                  font='bold'
                  label='本日'
                  variant='outlined'
                  selected={todaySelected}
                  onClick={() => handleCheckinDateChipClicked(CHECKIN_DATE_CHIPS_ENUM.TODAY, !todaySelected)}
                />
                <ChipButton
                  font='bold'
                  label='昨日'
                  variant='outlined'
                  selected={yesterdaySelected}
                  onClick={() => handleCheckinDateChipClicked(CHECKIN_DATE_CHIPS_ENUM.YEASTERDAY, !yesterdaySelected)}
                />
              </ChipsWrapper>
              <DateWrapper>
                <DateRow>
                  <TextFieldV2
                    inputWidth={128}
                    placeholder={format(new Date(), 'yyyy/MM/dd')}
                    value={startDate}
                    hasError={dateError}
                    onChange={setStartDate}
                  />
                  <TextFieldV2
                    inputType='time'
                    inputWidth={80}
                    placeholder='00:00'
                    value={startTime}
                    hasError={timeError}
                    onChange={setStartTime}
                  />
                  <FilterText>〜</FilterText>
                </DateRow>
                <DateRow>
                  <TextFieldV2
                    inputWidth={128}
                    placeholder={format(new Date(), 'yyyy/MM/dd')}
                    value={endDate}
                    hasError={dateError}
                    onChange={setEndDate}
                  />
                  <TextFieldV2
                    inputType='time'
                    inputWidth={80}
                    placeholder='23:59'
                    value={endTime}
                    hasError={timeError}
                    onChange={setEndTime}
                  />
                </DateRow>
              </DateWrapper>
            </Filter>
            <Filter data-testid='company'>
              <FilterHeading>所属</FilterHeading>
              <TextFieldV2 value={companyName} placeholder='テキスト検索' onChange={setCompanyName} />
            </Filter>
            <Filter data-testid='free-word'>
              <FilterHeading>フリーワード</FilterHeading>
              <TextFieldV2
                value={searchingWord}
                placeholder='名前,電話番号,メールアドレスなど'
                onChange={setSearchingWord}
              />
            </Filter>
            <ButtonV2 isFullWidth type='primary' label='フィルターを適用' onClick={handleSearchClicked} />
          </Facet>
        </FacetContent>
        <ListContent data-testid='user-table'>
          <PageHeaderV2 title='ユーザー' />
          <SearchListV2
            name='ユーザー'
            title={`${totalRecordCount}名のユーザー`}
            colWidth={COLUMN_WIDTH}
            header={HEADER}
            rows={rows}
            paginateProps={{ offset, limit: LIMIT, totalCount: totalRecordCount, setOffset }}
            isLoaderShown={isLoaderShown}
            selectedIds={selectedUserIds}
            isSelectedAll={isSelectedAll}
            moreHorizMenusForTable={moreHorizMenusForTable}
            tableRightComponent={
              <SelectBoxV2
                options={userActionOptions}
                labels={userActionLabel}
                onChange={handleActionSelected}
                value={selectedAction}
              />
            }
            onClickRow={handleClickRow}
            onClickSortCol={handleClickSortTh}
            setSelectedIds={setSelectedUserIds}
            setIsSelectedAll={setIsSelectedAll}
          />
        </ListContent>
        {category && (
          <RegisterPersonInChargeModal
            isOpen={isModalOpen}
            base={base}
            baseAuthorities={baseAuthorities}
            selectedUsers={selectedUsers}
            category={category}
            onClose={handleModalClosed}
          />
        )}
      </Container>
    </Component>
  );
});

UserListV2Screen.displayName = 'UserListV2Screen';
export default UserListV2Screen;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;
const FacetContent = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const Facet = styled.div`
  width: 290px;
  padding: ${themeV2.mixins.v2.spacing * 3}px;
  background-color: ${themeV2.mixins.v2.color.font.white};
  border-right: 1px solid ${themeV2.mixins.v2.color.font.lightGray};
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;
const Heading = styled.div`
  ${themeV2.mixins.v2.typography.title.xLarge};
  color: ${themeV2.mixins.v2.color.font.gray};
  font-size: 19px;
`;
const Filter = styled.div<{ gap?: Gap }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (isUndefined(gap) ? themeV2.mixins.v2.spacing : gap)}px;
`;
const FilterHeading = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV2.mixins.v2.color.font.gray};
  font-weight: 700;
`;
const FilterText = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
`;
const CheckboxGroup = styled.div`
  cursor: pointer;
`;
const ChipsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing}px;
`;
const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;
const DateRow = styled.div`
  display: flex;
  gap: ${themeV2.mixins.v2.spacing}px;
  align-items: center;
`;

const ListContent = styled.div`
  width: 100%;
  height: calc(100% - ${PAGINATE_HEIGHT}px);
  flex: 1;
  display: flex;
  flex-flow: column;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  ${themeV2.mixins.v2.scrollbarInvisible};
`;

const StyledAddIcon = styled(AddIcon)<{ selected: boolean }>`
  color: ${({ selected }) => (selected ? themeV2.mixins.v2.color.font.white : themeV2.mixins.v2.color.font.gray)};
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ResetFilterButton = styled(Link)`
  color: ${themeV2.mixins.v2.color.font.gray};
  font-family: Noto Sans JP;
  font-size: 12px;
`;
