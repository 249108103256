import {
  BackButtonV2,
  ButtonV2,
  CircularLoader,
  Component,
  DialogV2,
  IconButtonV2,
  InformationPanel,
  LabelV2,
  QRCode,
  ScreenLoaderV2,
  SnackbarStatus,
  StatusV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  BaseFunctionToggleCode,
  BaseId,
  CHECKOUT_SPACE_RESERVATION,
  CHECK_SPACE_RESERVATION_LIMIT,
  CREATE_QRCODES_FOR_EACH_SPACE_RESERVATION,
  CancelPolicy,
  CheckSpaceReservationLimitRequest,
  CheckSpaceReservationLimitResponse,
  CheckoutSpaceReservationRequest,
  CheckoutSpaceReservationResponse,
  ContractV2,
  CreateQrCodesForEachSpaceReservationRequest,
  CreateQrCodesForEachSpaceReservationResponse,
  DELETE_SPACE_PARTICIPANT,
  DeleteSpaceParticipantRequest,
  DeleteSpaceParticipantResponse,
  FETCH_CANCEL_POLICIES,
  FETCH_CONTRACT_V2_BY_USER,
  FETCH_SPACE_RESERVATION,
  FETCH_SPACE_USAGES,
  FETCH_STRIPE_CHECKOUT_SESSION_URL,
  FetchCancelPoliciesRequest,
  FetchCancelPoliciesResponse,
  FetchContractV2ByUserRequest,
  FetchContractV2ByUserResponse,
  FetchSpaceReservationRequest,
  FetchSpaceReservationResponse,
  FetchSpaceUsagesRequest,
  FetchSpaceUsagesResponse,
  FetchStripeCheckoutSessionUrlRequest,
  FetchStripeCheckoutSessionUrlResponse,
  KeyOperation,
  KeyOperationResult,
  KeyOperationStatus,
  OPERATE_SPACE_KEY,
  OperateSpaceKeyRequest,
  OperateSpaceKeyResponse,
  ReservationPaymentMethod,
  SAVE_SPACE_PARTICIPANT,
  SHARED_SPACE_RESERVATION_END_AT,
  SHARED_SPACE_RESERVATION_ID,
  SHARED_SPACE_RESERVATION_START_AT,
  SPACE_RESERVATION_ID,
  SaveSpaceParticipantRequest,
  SaveSpaceParticipantResponse,
  SpaceId,
  SpaceManagement,
  SpaceParticipant,
  SpaceParticipantDiv,
  SpaceReservation,
  SpaceReservationId,
  SpaceReservationOption,
  SpaceReservationStatus,
  SpaceReservationType,
  SpaceUsagesForTime,
  UPDATE_SPACE_RESERVATION_STATUS,
  UpdateSpaceReservationStatusRequest,
  UpdateSpaceReservationStatusResponse,
  User,
  constructExternalLink,
  isBaseFunctionToggleEnabled,
  toFullName,
  toSpaceName
} from '@atomica.co/irori';
import { Code, DateStr, Description, Label, Message, Milliseconds, Name, Remarks, URL } from '@atomica.co/types';
import {
  BREAK,
  EMPTY,
  FIRST_INDEX,
  MINUS_ONE,
  ONE,
  builder,
  copyText,
  embedIdInPath,
  hasLength,
  isLessThan,
  toBeginningOfDay,
  toEndOfDay
} from '@atomica.co/utils';
import CloseIcon from '@material-ui/icons/Close';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { addMinutes, differenceInMinutes, format, isBefore, isEqual, min, subDays, subMinutes } from 'date-fns';
import { ja } from 'date-fns/locale';
import React, { CSSProperties, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import mojaco_break from '../../../assets/mojaco/mojaco_break.png';
import mojaco_sorry from '../../../assets/mojaco/mojaco_sorry.png';
import ListV2, { ListRow } from '../../../components/list/List';
import DefaultModal from '../../../components/modal/DefaultModal';
import { MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH } from '../../../constants/common-const';
import { toQrCode } from '../../../converters/account-converter';
import { HistoryKeys } from '../../../enums/history-state-enum';
import env from '../../../env/env';
import { useSnackbarV2 } from '../../../provider/SnackbarProviderV2';
import useCachedSpaceReservation from '../../../redux/hooks/useCachedSpaceReservation';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import usePath from '../../../redux/hooks/usePath';
import CommonRequest from '../../../requests/common-request';
import { PATH_IDS, Path } from '../../../router/Routes';
import {
  CANCEL_PARTICIPANT_ERROR,
  CANCEL_PARTICIPANT_SUCCESS,
  FAILED_TO_OPEN_KEY,
  INVITATION_LINK_COPIED,
  OPEN_KEY_SUCCESSFULLY,
  SUCCESS_CHECKOUT
} from '../../../texts/snackbar-text';
import { partialBuilder } from '../../../utils/common-util';
import {
  buildCancelPolicyDescription,
  getSpaceReservationStatusLabel,
  isReservationTimeFinished,
  toStatusFromSpaceReservation,
  useCreditCardPayment
} from '../../../utils/space-reservation-util';
import { getSpaceAvailabilityAtOfTargetDate, isReferableReservation } from '../../../utils/space-utils';
import AccountReservationDetailPaymentMethod from './account-reservation-payment-method/AccountReservationDetailPaymentMethod';

const SHOW_SNACK_BAR_TIME_MS = 10000;

interface SnackbarParameter {
  message: Message;
  status: SnackbarStatus;
  autoHideDuration?: Milliseconds;
}

interface P {
  base: BaseDto;
  user: User;
}

const AccountReservationDetail: React.FC<P> = React.memo(props => {
  const { base, user } = props;
  const { params } = usePath();
  const { openBasePath, openPath } = usePath();
  const unmountRef = useUnmountRef();
  const { clearCachedSpaceReservation } = useCachedSpaceReservation();
  const { openSnackbar } = useSnackbarV2();
  const { commonRequest } = useCommonRequest();

  const spaceReservationId = useMemo<SpaceReservationId>(() => params[SPACE_RESERVATION_ID], [params]);

  const [reservation, setReservation] = useSafeState<SpaceReservation | undefined>(unmountRef);
  const [qrCode, setQrCode] = useSafeState<Code>(unmountRef, EMPTY);
  const [isLoading, setIsLoading] = useSafeState<boolean>(unmountRef, false);
  const [isThumbnailExisting, setIsThumbnailExisting] = useSafeState<boolean>(unmountRef, true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [isAbsenceModalOpen, setIsAbsenceModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [isKeyOpening, setIsKeyOpening] = useSafeState<boolean>(unmountRef, false);
  const [spaceUsages, setSpaceUsages] = useSafeState<SpaceUsagesForTime>(unmountRef);
  const [isLimited, setIsLimited] = useSafeState<boolean>(unmountRef, false);
  const [limitedErrorMessage, setLimitedErrorMessage] = useSafeState<Message | undefined>(unmountRef);
  const [cancelPolicies, setCancelPolicies] = useSafeState<CancelPolicy[]>(unmountRef, []);
  const [stripeCheckoutSessionUrl, setStripeCheckoutSessionUrl] = useSafeState<URL | undefined>(unmountRef);
  const [stripeCheckoutSessionExpiresAt, setStripeCheckoutSessionExpiresAt] = useSafeState<DateStr | undefined>(
    unmountRef
  );
  const [reservedSnackbarParameter, setReservedSnackbarParameter] = useSafeState<SnackbarParameter | undefined>(
    unmountRef
  );

  const isInitialized = useRef<boolean>(false);

  const participants = useMemo<SpaceParticipant[]>(() => reservation?.participants ?? [], [reservation]);
  const reservedSpaceName = useMemo<Name>(() => {
    if (!reservation) return EMPTY;
    return toSpaceName(reservation) ?? EMPTY;
  }, [reservation]);

  const reservationOptions = useMemo<SpaceReservationOption[]>(() => {
    return (
      reservation?.options?.sort((a, b) =>
        a.baseResourceCategoryOption &&
        b.baseResourceCategoryOption &&
        a.baseResourceCategoryOption.order < b.baseResourceCategoryOption.order
          ? -1
          : 1
      ) ?? []
    );
  }, [reservation]);

  const cancelDeadlineDate = useMemo<Date | undefined>(
    () => reservation?.startAt && subDays(reservation.startAt, base.spaceDueDays),
    [base, reservation]
  );

  const [contract, setContract] = useSafeState<ContractV2 | undefined>(unmountRef, undefined);
  const [hasChildReservation, setHasChildReservation] = useSafeState<boolean>(unmountRef, false);
  const [isCancelable, setIsCancelable] = useSafeState<boolean>(unmountRef, false);
  const [isReservationTimeBefore, setIsReservationTimeBefore] = useSafeState<boolean>(unmountRef, false);
  const [isCheckedIn, setIsCheckedIn] = useSafeState<boolean>(unmountRef, false);
  const [isUsableUnlockKey, setIsUsableUnlockKey] = useSafeState<boolean>(unmountRef, false);
  const [isReservationTimeAfter, setIsReservationTimeAfter] = useSafeState<boolean>(unmountRef, false);
  const [isReservationTimeEnded, setIsReservationTimeEnded] = useSafeState<boolean>(unmountRef, true);
  const [isReservationCheckOuted, setIsReservationCheckOuted] = useSafeState<boolean>(unmountRef, false);
  const [isExtendable, setIsExtendable] = useSafeState<boolean>(unmountRef, false);

  const startLabel = useMemo<Label>(() => {
    switch (reservation?.space?.baseResourceCategory?.resourceCategoryDef?.reservationType) {
      case SpaceReservationType.DATE:
        return 'チェックイン';
      case SpaceReservationType.TIME:
      default:
        return '利用開始日時';
    }
  }, [reservation]);

  const endLabel = useMemo<Label>(() => {
    switch (reservation?.space?.baseResourceCategory?.resourceCategoryDef?.reservationType) {
      case SpaceReservationType.DATE:
        return 'チェックアウト';
      case SpaceReservationType.TIME:
      default:
        return '利用終了日時';
    }
  }, [reservation]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!reservation || !cancelDeadlineDate) return;
      const { space, startAt, endAt, checkinAt, checkoutAt } = reservation;
      const { permittedEntryMinutes, bufferMinutes, isReservationExtendable } = space!;
      setIsCancelable(isBefore(new Date(), cancelDeadlineDate));
      setIsReservationTimeBefore(isBefore(new Date(), startAt!));
      setIsCheckedIn(isBefore(startAt!, new Date()) || (!!checkinAt && isBefore(checkinAt, new Date())));
      setIsExtendable(isReservationExtendable ?? false);
      setIsUsableUnlockKey(
        isBefore(subMinutes(startAt!, permittedEntryMinutes), new Date()) &&
          isBefore(new Date(), addMinutes(endAt!, bufferMinutes))
      );
      setIsReservationTimeAfter(!isBefore(new Date(), endAt!));
      setIsReservationTimeEnded(isReservationTimeFinished(reservation));
      setIsReservationCheckOuted(!!checkoutAt);
    }, 1000);

    return () => clearInterval(interval);
  }, [
    reservation,
    setIsCancelable,
    setIsExtendable,
    cancelDeadlineDate,
    setIsReservationTimeBefore,
    setIsCheckedIn,
    setIsUsableUnlockKey,
    setIsReservationTimeAfter,
    setIsReservationTimeEnded,
    setIsReservationCheckOuted
  ]);

  const isTimeReservationType = useMemo<boolean>(() => {
    return reservation?.space?.baseResourceCategory?.resourceCategoryDef?.reservationType === SpaceReservationType.TIME;
  }, [reservation]);

  const isReservationUser = useMemo<boolean>(() => {
    if (!reservation) return false;
    const participant = reservation.participants?.find(row => row.user?.userId === user.userId);
    return (
      reservation?.createdUser?.userId === user.userId || participant?.participantDiv === SpaceParticipantDiv.OWNER
    );
  }, [reservation, user]);

  const isParticipant = useMemo<boolean>(() => {
    if (!reservation) return false;
    return reservation.participants?.some(row => row.user?.userId === user.userId) ?? false;
  }, [reservation, user]);

  const isParticipantsCapacityReached = useMemo<boolean>(() => {
    if (!participants || !reservation || !reservation.space || reservation.space.permitCapacityExceedance) return false;
    const capacity = reservation.space?.capacity ?? -1;
    if (capacity < 0) return false;
    return participants.length >= capacity;
  }, [reservation, participants]);

  const isTimeSpanedReservation = useMemo<boolean>(() => {
    return reservation?.space?.reservationType === SpaceReservationType.TIME;
  }, [reservation]);

  const isNotAvailableReservation = useMemo<boolean>(() => {
    return (
      !reservation ||
      !(
        reservation.status === SpaceReservationStatus.CONFIRMED ||
        reservation.status === SpaceReservationStatus.PROVISIONAL
      ) ||
      isReservationTimeEnded ||
      !isReferableReservation(reservation, user)
    );
  }, [reservation, isReservationTimeEnded, user]);

  const isProvisionalReservation = useMemo<boolean>(() => {
    return (
      !!reservation &&
      reservation.status === SpaceReservationStatus.PROVISIONAL &&
      !isReservationTimeFinished(reservation) &&
      isReferableReservation(reservation, user)
    );
  }, [reservation, user]);

  const isShownUnlockKey = useMemo<boolean>(() => {
    if (!reservation || isNotAvailableReservation) return false;
    const { space } = reservation;
    return (
      base.spaceManagement === SpaceManagement.BITKEY &&
      space?.spaceManagement === SpaceManagement.BITKEY &&
      !isReservationTimeAfter &&
      !isReservationCheckOuted &&
      !isProvisionalReservation &&
      isParticipant
    );
  }, [
    base,
    reservation,
    isNotAvailableReservation,
    isReservationTimeAfter,
    isReservationCheckOuted,
    isProvisionalReservation,
    isParticipant
  ]);
  const isShownCheckout = useMemo<boolean>(() => {
    return (
      !isNotAvailableReservation &&
      (isCheckedIn || !isReservationTimeBefore) &&
      !isProvisionalReservation &&
      isParticipant
    );
  }, [isCheckedIn, isReservationTimeBefore, isNotAvailableReservation, isProvisionalReservation, isParticipant]);
  const isShownReturnPayment = useMemo<boolean>(() => {
    if (!reservation) return false;
    return (
      reservation.status === SpaceReservationStatus.PAYMENT_IN_PROGRESS ||
      reservation.status === SpaceReservationStatus.ADDITIONAL_PAYMENT_IN_PROGRESS
    );
  }, [reservation]);
  const isShownExtend = useMemo<boolean>(() => {
    return !isNotAvailableReservation && !isCancelable && isReservationUser && isTimeSpanedReservation && isExtendable;
  }, [isCancelable, isNotAvailableReservation, isReservationUser, isTimeSpanedReservation, isExtendable]);
  const isShownEdit = useMemo<boolean>(() => {
    return (
      !isNotAvailableReservation && isCancelable && isReservationUser && isTimeReservationType && !hasChildReservation
    );
  }, [hasChildReservation, isCancelable, isNotAvailableReservation, isReservationUser, isTimeReservationType]);
  const isShownProvsionalEdit = useMemo<boolean>(() => {
    return (
      isProvisionalReservation && isCancelable && isReservationUser && isTimeReservationType && !hasChildReservation
    );
  }, [hasChildReservation, isCancelable, isProvisionalReservation, isReservationUser, isTimeReservationType]);
  const isShownCancel = useMemo<boolean>(() => {
    return !isNotAvailableReservation && isCancelable && isReservationUser;
  }, [isCancelable, isNotAvailableReservation, isReservationUser]);
  const isShownProvsionalCancel = useMemo<boolean>(() => {
    return isProvisionalReservation && isCancelable && isReservationUser;
  }, [isCancelable, isProvisionalReservation, isReservationUser]);

  const isShowSpacePaymentStatus = useMemo<boolean>(() => {
    if (!contract) return true;
    return false;
  }, [contract]);

  const [isEnabledExtend, errorMessageForExtension] = useMemo((): [boolean, Message] => {
    if (isCancelable || !reservation || !spaceUsages) return [false, EMPTY];
    const { endAt, space } = reservation;

    const startAts = spaceUsages[space!.spaceId].map(usage => usage.start).filter(startAt => endAt! <= startAt);

    const nextUsageStartAt = hasLength(startAts) ? min(startAts) : undefined;
    if (nextUsageStartAt && isLessThan(differenceInMinutes(nextUsageStartAt, endAt!), space!.reservationUnit)) {
      return [false, '次の予約が入っているため延長できません。'];
    }

    const { spaceEndAt } = getSpaceAvailabilityAtOfTargetDate(endAt!, base.timezone, space?.availabilities);
    if (!spaceEndAt || isEqual(endAt!, spaceEndAt)) return [false, '営業終了時間のため延長できません'];

    if (limitedErrorMessage) return [false, limitedErrorMessage];

    return [true, EMPTY];
  }, [base, isCancelable, limitedErrorMessage, reservation, spaceUsages]);

  const cancelPolicyDescription = useMemo<Description>(
    () => buildCancelPolicyDescription(cancelPolicies),
    [cancelPolicies]
  );
  const cancelPolicyAdditionalInfo = useMemo<Description>(() => base.spaceCancelPolicy || EMPTY, [base]);
  const cancelPolicyRemarks = useMemo<Remarks | undefined>(() => {
    const isUsePayment =
      useCreditCardPayment(base, contract, new Date(), user) &&
      reservation?.paymentMethod === ReservationPaymentMethod.CREDIT_CARD;
    return isUsePayment
      ? `※キャンセルポリシーに基づき、キャンセル料金を差し引いた後の金額が返金されます。${BREAK}※クレジットカード決済の場合は追加で予約金額の3.6%が手数料として差し引かれます。`
      : undefined;
  }, [base, contract, user, reservation]);
  const hasCancelPolicy = useMemo<boolean>(() => {
    return !!cancelPolicyDescription || !!cancelPolicyAdditionalInfo;
  }, [cancelPolicyDescription, cancelPolicyAdditionalInfo]);

  const reservationURLToShare = useMemo<URL | undefined>(() => {
    if (!reservation) return;
    const { startAt, endAt } = reservation;
    if (!spaceReservationId || !startAt || !endAt) return;

    const startAtForQueryParam = format(startAt, 'yyyy/MM/dd HH:mm:ss').toString();
    const endAtForQueryParam = format(endAt, 'yyyy/MM/dd HH:mm:ss').toString();

    const queryParamOfSpaceReservationId = `${SHARED_SPACE_RESERVATION_ID}=${spaceReservationId}`;
    const queryParamOfStartAt = `${SHARED_SPACE_RESERVATION_START_AT}=${startAtForQueryParam}`;
    const queryParamOfEndAt = `${SHARED_SPACE_RESERVATION_END_AT}=${endAtForQueryParam}`;

    const pathToOpen = `${embedIdInPath(Path.ACCOUNT_SHARE, PATH_IDS, [
      base.baseCode
    ])}?${queryParamOfSpaceReservationId}&${queryParamOfStartAt}&${queryParamOfEndAt}`;

    return constructExternalLink(env, pathToOpen);
  }, [base, reservation, spaceReservationId]);

  const planName = useMemo<Name>(() => {
    if (!hasLength(reservation?.space?.plans)) return EMPTY;
    return reservation!.space!.plans[FIRST_INDEX].planName;
  }, [reservation]);

  const checkoutMessage = useMemo<Message>(() => {
    if (!reservation) return EMPTY;
    if ((reservation.space?.capacity ?? MINUS_ONE) > ONE) {
      return `利用終了後は、他の${
        reservation.space?.baseResourceCategory?.categoryLabel ?? '会議室'
      }利用者も含めて入室できなくなります。よろしいですか？`;
    }
    return `利用終了後は入室できなくなります。よろしいですか？`;
  }, [reservation]);

  const getContract = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchContractV2ByUserRequest>().baseId(base.baseId).userId(user.userId).build();
    const { contract } = await CommonRequest.call<FetchContractV2ByUserRequest, FetchContractV2ByUserResponse>(
      FETCH_CONTRACT_V2_BY_USER,
      request
    );
    setContract(contract);
  }, [base, user]);

  const fetchSpaceReservation = useSafeCallback(async (): Promise<SpaceReservation> => {
    const request = builder<FetchSpaceReservationRequest>().spaceReservationId(spaceReservationId).build();

    const { spaceReservation, childSpaceReservations } = await CommonRequest.call<
      FetchSpaceReservationRequest,
      FetchSpaceReservationResponse
    >(FETCH_SPACE_RESERVATION, request);

    if (spaceReservation) setReservation(spaceReservation);
    setHasChildReservation(hasLength(childSpaceReservations));
    return spaceReservation;
  }, [setHasChildReservation, setReservation, spaceReservationId]);

  const fetchQrCode = useSafeCallback(
    async (reservation: SpaceReservation): Promise<void> => {
      const spaceManagement = reservation.space?.spaceManagement;
      if (!base.spaceManagement || !spaceManagement) return;
      const request = builder<CreateQrCodesForEachSpaceReservationRequest>()
        .user(user)
        .spaceReservations([reservation])
        .build();
      const response = await CommonRequest.call<
        CreateQrCodesForEachSpaceReservationRequest,
        CreateQrCodesForEachSpaceReservationResponse
      >(CREATE_QRCODES_FOR_EACH_SPACE_RESERVATION, request);
      if (!response) return;

      const qrCode = response.qrcodes.find(qrcode => qrcode.spaceReservationId === reservation.spaceReservationId);
      if (qrCode) setQrCode(toQrCode(spaceManagement, qrCode.buffer!));
    },
    [base, setQrCode, user]
  );

  const buildSpaceUsages = useSafeCallback(
    async (reservation: SpaceReservation): Promise<void> => {
      if (!reservation) return;
      setIsLoading(true);

      const reservationType = reservation.space?.baseResourceCategory?.resourceCategoryDef?.reservationType
        ? reservation.space.baseResourceCategory.resourceCategoryDef.reservationType
        : SpaceReservationType.TIME;

      const request = builder<FetchSpaceUsagesRequest>()
        .spaceIds([reservation.space!.spaceId])
        .baseId(base.baseId)
        .fromDate(toBeginningOfDay(new Date(reservation.startAt!))!)
        .toDate(toEndOfDay(new Date(reservation.endAt!))!)
        .reservationType(reservationType)
        .userId(user?.userId)
        .build();
      const response = await CommonRequest.call<FetchSpaceUsagesRequest, FetchSpaceUsagesResponse>(
        FETCH_SPACE_USAGES,
        request
      );
      setSpaceUsages(response.spaceUsages);
      setIsLoading(false);
    },
    [base.baseId, setIsLoading, setSpaceUsages, user?.userId]
  );

  const checkoutSpaceReservation = useSafeCallback(async (): Promise<void> => {
    setIsLoading(true);
    const request = builder<CheckoutSpaceReservationRequest>().spaceReservationId(spaceReservationId).build();
    const { checkoutAt } = await CommonRequest.call<CheckoutSpaceReservationRequest, CheckoutSpaceReservationResponse>(
      CHECKOUT_SPACE_RESERVATION,
      request
    );
    if (checkoutAt) setReservation(reservation => partialBuilder(reservation, { checkoutAt }));

    setIsCheckoutModalOpen(false);
    setIsLoading(false);
    if (checkoutAt) openSnackbar(SUCCESS_CHECKOUT, 'success', 6000);
  }, [openSnackbar, setIsCheckoutModalOpen, setIsLoading, setReservation, spaceReservationId]);

  const checkSpaceReservationLimit = useSafeCallback(
    async (reservation: SpaceReservation): Promise<void> => {
      const request = builder<CheckSpaceReservationLimitRequest>()
        .baseId(base.baseId)
        .userId(user.userId)
        .start(reservation.startAt)
        .end(addMinutes(reservation.endAt!, reservation.space!.reservationUnit))
        .spaceId(reservation.space?.spaceId)
        .spaceReservationId(spaceReservationId)
        .build();
      const { isLimited, errorMessage } = await CommonRequest.call<
        CheckSpaceReservationLimitRequest,
        CheckSpaceReservationLimitResponse
      >(CHECK_SPACE_RESERVATION_LIMIT, request);

      setIsLimited(isLimited);
      setLimitedErrorMessage(errorMessage);
      setIsCheckoutModalOpen(false);
    },
    [base.baseId, setIsCheckoutModalOpen, setIsLimited, setLimitedErrorMessage, user.userId]
  );
  const fetchCancelPolicies = useSafeCallback(
    async (baseId: BaseId, spaceId: SpaceId): Promise<void> => {
      const request = builder<FetchCancelPoliciesRequest>().baseId(baseId).spaceId(spaceId).build();
      const { cancelPolicies } = await CommonRequest.call<FetchCancelPoliciesRequest, FetchCancelPoliciesResponse>(
        FETCH_CANCEL_POLICIES,
        request
      );
      setCancelPolicies(cancelPolicies);
    },
    [setCancelPolicies]
  );
  const fetchStripeCheckoutSessionUrl = useSafeCallback(
    async (baseId: BaseId, spaceReservationId: SpaceReservationId, base: BaseDto): Promise<void> => {
      if (!baseId || !spaceReservationId) return;
      if (
        base.enabledFunctions?.some(e => e.toggleCode === BaseFunctionToggleCode.FUNCTION_USE_RESERVATION_SPACE_PAYMENT)
      ) {
        const request = builder<FetchStripeCheckoutSessionUrlRequest>()
          .baseId(baseId)
          .spaceReservationId(spaceReservationId)
          .build();
        const response = await CommonRequest.call<
          FetchStripeCheckoutSessionUrlRequest,
          FetchStripeCheckoutSessionUrlResponse
        >(FETCH_STRIPE_CHECKOUT_SESSION_URL, request);
        setStripeCheckoutSessionUrl(response?.url);
        setStripeCheckoutSessionExpiresAt(response?.expiresAtString);
      }
    },
    [setStripeCheckoutSessionExpiresAt, setStripeCheckoutSessionUrl]
  );
  const cancelInvitation = useSafeCallback(async (): Promise<void> => {
    if (!hasLength(participants)) return;
    const participant = participants.find(participant => participant.user?.userId === user.userId);
    if (!participant) return;
    setIsLoading(true);
    const request = builder<DeleteSpaceParticipantRequest>()
      .baseId(base.baseId)
      .spaceReservationId(spaceReservationId)
      .spaceParticipantId(participant.spaceParticipantId)
      .build();
    const { spaceParticipantId } = await CommonRequest.call<
      DeleteSpaceParticipantRequest,
      DeleteSpaceParticipantResponse
    >(DELETE_SPACE_PARTICIPANT, request);

    openSnackbar(
      spaceParticipantId ? CANCEL_PARTICIPANT_SUCCESS : CANCEL_PARTICIPANT_ERROR,
      spaceParticipantId ? 'success' : 'error',
      3000
    );
    if (!spaceParticipantId) return;
    setIsLoading(false);
    openBasePath(Path.ACCOUNT_MY_SCHEDULES);
  }, [base.baseId, openBasePath, openSnackbar, participants, setIsLoading, spaceReservationId, user.userId]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const reservation = await fetchSpaceReservation();
    clearCachedSpaceReservation();
    if (!reservation) return;
    const promises = [
      fetchQrCode(reservation),
      buildSpaceUsages(reservation),
      checkSpaceReservationLimit(reservation),
      fetchCancelPolicies(reservation.space!.base!.baseId, reservation.space!.spaceId),
      fetchStripeCheckoutSessionUrl(reservation.space?.base?.baseId ?? EMPTY, reservation.spaceReservationId, base),
      getContract()
    ];
    await Promise.all(promises);
  }, [
    fetchSpaceReservation,
    getContract,
    clearCachedSpaceReservation,
    fetchQrCode,
    buildSpaceUsages,
    checkSpaceReservationLimit,
    fetchCancelPolicies,
    fetchStripeCheckoutSessionUrl
  ]);

  useEffect(() => {
    if (isInitialized.current) return;
    initialize();
    isInitialized.current = true;
  }, [initialize]);

  const handleShareClicked = useSafeCallback(async (): Promise<void> => {
    if (navigator.share) {
      await navigator.share({ url: reservationURLToShare });
      return;
    }

    copyText(reservationURLToShare!);
    openSnackbar(INVITATION_LINK_COPIED, 'success', 3000);
  }, [reservationURLToShare, openSnackbar]);

  const reserveSnackbar = (message: Message, status: SnackbarStatus, autoHideDuration: Milliseconds) =>
    setReservedSnackbarParameter(
      builder<SnackbarParameter>().message(message).status(status).autoHideDuration(autoHideDuration).build()
    );

  useEffect(() => {
    if (reservedSnackbarParameter) {
      const { message, status, autoHideDuration } = reservedSnackbarParameter;
      openSnackbar(message, status, autoHideDuration);
      setReservedSnackbarParameter(undefined);
    }
  }, [reservedSnackbarParameter, setReservedSnackbarParameter, openSnackbar]);

  const openSpaceKey = useSafeCallback(async (): Promise<void> => {
    if (!reservation || !reservation.space) return;
    setIsKeyOpening(true);

    const request = builder<OperateSpaceKeyRequest>()
      .baseId(base.baseId)
      .spaceId(reservation.space.spaceId)
      .operation(KeyOperation.UNLOCK)
      .userId(user.userId)
      .spaceReservationId(reservation.spaceReservationId)
      .build();
    const response = await CommonRequest.call<OperateSpaceKeyRequest, OperateSpaceKeyResponse>(
      OPERATE_SPACE_KEY,
      request
    );

    if (!response || response.result === KeyOperationResult.FAIL) {
      reserveSnackbar(FAILED_TO_OPEN_KEY, 'error', SHOW_SNACK_BAR_TIME_MS);
    } else if (response.status === KeyOperationStatus.OPEN || KeyOperationStatus.UNKNOWN) {
      reserveSnackbar(OPEN_KEY_SUCCESSFULLY, 'success', SHOW_SNACK_BAR_TIME_MS);
    }

    setIsKeyOpening(false);
    setReservation(partialBuilder(reservation, { checkinAt: new Date() }));
  }, [base.baseId, openSnackbar, reservation, setIsKeyOpening, setReservation, user.userId]);

  const openMySchedule = useSafeCallback(
    (deleted: boolean): void => {
      openBasePath(Path.ACCOUNT_MY_SCHEDULES, { [HistoryKeys.DELETED]: deleted });
    },
    [openBasePath]
  );

  const deleteReservation = useSafeCallback(async (): Promise<void> => {
    setIsLoading(true);
    const request = builder<UpdateSpaceReservationStatusRequest>()
      .spaceReservationIds([spaceReservationId])
      .userId(user.userId)
      .baseId(base.baseId)
      .status(SpaceReservationStatus.CANCELED)
      .build();
    await commonRequest<UpdateSpaceReservationStatusRequest, UpdateSpaceReservationStatusResponse>(
      UPDATE_SPACE_RESERVATION_STATUS,
      request
    );

    setIsLoading(false);
    openMySchedule(true);
  }, [base.baseId, commonRequest, openMySchedule, setIsLoading, spaceReservationId, user.userId]);

  const saveSpaceParticipant = useSafeCallback(async (): Promise<void> => {
    if (!reservation) return;
    setIsLoading(true);
    const request = builder<SaveSpaceParticipantRequest>()
      .baseId(base.baseId)
      .userId(user.userId)
      .participantDiv(SpaceParticipantDiv.MEMBER)
      .spaceReservationId(reservation.spaceReservationId)
      .build();

    await commonRequest<SaveSpaceParticipantRequest, SaveSpaceParticipantResponse>(SAVE_SPACE_PARTICIPANT, request);

    await fetchSpaceReservation();

    setIsLoading(false);
  }, [base.baseId, commonRequest, fetchSpaceReservation, reservation, setIsLoading, user.userId]);

  const openSpaceReservationExtensionScreen = useSafeCallback((): void => {
    openPath(embedIdInPath(Path.RESERVE_SPACE_EXTENSION, PATH_IDS, [base.baseCode, spaceReservationId]));
  }, [base, openPath, spaceReservationId]);

  const openSpaceReservationEditScreen = useSafeCallback((): void => {
    openPath(embedIdInPath(Path.RESERVE_SPACE_EDIT, PATH_IDS, [base.baseCode, spaceReservationId]));
  }, [base, openPath, spaceReservationId]);

  const openStripePaymentScreen = useSafeCallback((): void => {
    if (!stripeCheckoutSessionUrl) return;
    window.location.href = stripeCheckoutSessionUrl;
  }, [stripeCheckoutSessionUrl]);

  return (
    <Component
      loading={!isInitialized.current}
      style={styleForComponent}
      className='reservation-detail-v2'
      title={reservation && reservation.reservationName}
    >
      {reservation && (
        <Container>
          <ThumbnailWrapper>
            <Thumbnail
              src={isThumbnailExisting && reservation.space?.photoURL ? reservation.space.photoURL : mojaco_break}
              onError={() => setIsThumbnailExisting(false)}
            />
          </ThumbnailWrapper>
          <BackButtonWrapper>
            <BackButtonV2 label='戻る' onClick={() => openMySchedule(false)} />
          </BackButtonWrapper>
          <Content>
            <ReservationNameWrapper>
              {reservation.reservationName || `${reservation.space?.baseResourceCategory?.categoryLabel || EMPTY}予約`}
            </ReservationNameWrapper>
            {isReservationTimeAfter && !isNotAvailableReservation && (
              <InformationPanel status='error' isBold title='利用終了時刻を過ぎています' />
            )}
            {isReservationCheckOuted && <InformationPanel status='error' isBold title='利用終了済みです' />}
            <CardWrapper>
              <CardRow>
                <LabelWrapper>
                  <LargeLabel text='予約ステータス' />
                </LabelWrapper>
                <StatusV2
                  status={toStatusFromSpaceReservation(reservation)}
                  label={getSpaceReservationStatusLabel(reservation)}
                />
              </CardRow>
              <Border />
              <CardRow>
                <LabelWrapper>
                  <LargeLabel text={startLabel} />
                </LabelWrapper>
                <Text>
                  {reservation.startAt ? format(reservation.startAt, 'yyyy/MM/dd(E) HH:mm', { locale: ja }) : EMPTY}
                </Text>
              </CardRow>
              <Border />
              <CardRow>
                <LabelWrapper>
                  <LargeLabel text={endLabel} />
                </LabelWrapper>
                <Text>
                  {reservation.endAt ? format(reservation.endAt, 'yyyy/MM/dd(E) HH:mm', { locale: ja }) : EMPTY}
                </Text>
              </CardRow>
              <Border />
              {planName && (
                <>
                  <CardRow>
                    <LabelWrapper>
                      <LargeLabel text='タイプ' />
                    </LabelWrapper>
                    <Text>{planName}</Text>
                  </CardRow>
                  <Border />
                </>
              )}
              <CardRow>
                <LabelWrapper>
                  <LargeLabel text='施設' />
                </LabelWrapper>
                <Text>{reservedSpaceName}</Text>
              </CardRow>
              {isShownUnlockKey && (
                <>
                  <Border />
                  <CardRow align='start'>
                    <LabelWrapper>
                      <LargeLabel text='入室' />
                    </LabelWrapper>
                    {isUsableUnlockKey && (
                      <UnlockKeysWrapper>
                        {reservation.space?.canOpenByQrCode && (
                          <QrCodeArea>
                            {reservation.space.spaceManagement === SpaceManagement.DENSO && <QrCode src={qrCode} />}
                            {reservation.space.spaceManagement === SpaceManagement.BITKEY && (
                              <QRCode size={120} color='white' url={qrCode} />
                            )}
                          </QrCodeArea>
                        )}
                        {reservation.space?.canOpenByUnlockButton &&
                          (isKeyOpening ? (
                            <CircularLoader />
                          ) : (
                            <LargeButton
                              isFullWidth
                              width={160}
                              type={isCheckedIn ? 'secondary' : 'primary'}
                              startIcon={<LockOpenIcon />}
                              label='解錠'
                              onClick={openSpaceKey}
                            />
                          ))}
                      </UnlockKeysWrapper>
                    )}
                    {!isUsableUnlockKey && <Text>ご利用可能時間になりましたら解錠キーが表示されます</Text>}
                  </CardRow>
                </>
              )}
              {isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USE_SPACE_CHECKOUT) &&
                isShownCheckout && (
                  <>
                    <Border />
                    <CardRow>
                      <LabelWrapper>
                        <LargeLabel text='退室' />
                      </LabelWrapper>
                      <LargeButton
                        isFullWidth
                        width={160}
                        type={isReservationTimeAfter ? 'primary' : 'tertiary'}
                        label='利用を終了する'
                        onClick={() => setIsCheckoutModalOpen(true)}
                      />
                    </CardRow>
                  </>
                )}
              {reservationOptions.map((option, index) => (
                <React.Fragment key={`reservation-option-${index}`}>
                  <Border />
                  <CardRow>
                    <LabelWrapper>
                      <LargeLabel text={option.baseResourceCategoryOption?.optionLabel} />
                    </LabelWrapper>
                    <Text>{option.value}</Text>
                  </CardRow>
                </React.Fragment>
              ))}
              <Border />
              {isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USE_RESERVATION_SPACE_PAYMENT) &&
                isShowSpacePaymentStatus && <AccountReservationDetailPaymentMethod reservation={reservation} />}
              <CardRow>
                <LabelWrapper>
                  <LargeLabel text='備考' />
                </LabelWrapper>
                <Pre>{reservation.remarks || 'なし'}</Pre>
              </CardRow>
            </CardWrapper>

            {isShownReturnPayment && !!stripeCheckoutSessionUrl && !!stripeCheckoutSessionExpiresAt && (
              <EditWrapper>
                <LargeButton
                  width={160}
                  isFullWidth
                  type='tertiary'
                  label='料金の支払い'
                  onClick={openStripePaymentScreen}
                />
                <DeadlineText>
                  お支払い期限は{format(new Date(stripeCheckoutSessionExpiresAt), 'yyyy/MM/dd HH:mm')}までです。{'\n'}
                  期限までにお支払いが完了しない場合は
                  {reservation.status === SpaceReservationStatus.PAYMENT_IN_PROGRESS && 'この予約は取り消されます。'}
                  {reservation.status === SpaceReservationStatus.ADDITIONAL_PAYMENT_IN_PROGRESS &&
                    '予約時間の変更・延長を行う前の予約時間に戻ります。'}
                </DeadlineText>
              </EditWrapper>
            )}
            {isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USE_SPACE_RESERVE_EXTENSION) &&
              isShownExtend && (
                <ExtensionWrapper>
                  <LargeButton
                    isFullWidth
                    type='tertiary'
                    label='利用時間を延長'
                    onClick={openSpaceReservationExtensionScreen}
                    disabled={isLimited || !isEnabledExtend}
                  />
                  {errorMessageForExtension && (
                    <InformationPanel
                      title={`延長できません（最低延長時間: ${reservation.space!.reservationUnit} 分）`}
                      status='error'
                    >
                      {errorMessageForExtension}
                    </InformationPanel>
                  )}
                </ExtensionWrapper>
              )}
            {(isShownEdit || isShownProvsionalEdit) && (
              <EditWrapper>
                <LargeButton
                  width={160}
                  isFullWidth
                  type='tertiary'
                  label='利用日時の変更'
                  onClick={openSpaceReservationEditScreen}
                />
                <DeadlineText>{format(cancelDeadlineDate!, 'yyyy/MM/dd HH:mm')}まで日時の変更ができます。</DeadlineText>
              </EditWrapper>
            )}
            {isProvisionalReservation && (
              <InformationPanel isBold status='default' title='参加者の招待'>
                予約ステータスが「確定済み」になり次第、招待を行うことができます。
              </InformationPanel>
            )}
            {!isNotAvailableReservation && !isProvisionalReservation && (
              <>
                <ListWrapper>
                  <Title>{participants.length || 0}名の参加者</Title>
                  <ListV2
                    rows={participants.map(participant =>
                      builder<ListRow>()
                        .icon(participant.user?.photoURL || mojaco_break)
                        .text(toFullName(participant.user))
                        .subText(
                          participant.participantDiv === SpaceParticipantDiv.OWNER ||
                            participant.user?.userId === reservation.createdUser?.userId
                            ? '主催者'
                            : EMPTY
                        )
                        .secondaryAction(
                          !isNotAvailableReservation && participant.user?.userId === user.userId ? (
                            <CloseButtonWrapper>
                              <IconButtonV2
                                size='medium'
                                icon={<CloseIcon />}
                                onClick={() => setIsAbsenceModalOpen(true)}
                              />
                            </CloseButtonWrapper>
                          ) : (
                            <></>
                          )
                        )
                        .build()
                    )}
                  />
                </ListWrapper>
                {!hasLength(participants) && (
                  <InformationPanel isBold status='error' title='利用者が登録されていません。'>
                    この予定に利用者として参加するか、招待用のリンクを利用者に共有し、参加を依頼してください。
                  </InformationPanel>
                )}
                {!participants.find(participant => participant.user?.userId === user.userId) &&
                  !isParticipantsCapacityReached && (
                    <LargeButton
                      isFullWidth
                      type='tertiary'
                      label='利用者として参加'
                      disabled={isParticipantsCapacityReached}
                      onClick={saveSpaceParticipant}
                    />
                  )}
                {!isParticipantsCapacityReached && (
                  <LargeButton isFullWidth type='tertiary' label='招待用のリンクを共有' onClick={handleShareClicked} />
                )}
              </>
            )}
            {(isShownCancel || isShownProvsionalCancel) && (
              <Footer>
                <LargeButton
                  isFullWidth
                  label={`${isProvisionalReservation ? '仮' : EMPTY}予約をキャンセル`}
                  onClick={() => setIsDeleteModalOpen(true)}
                />
              </Footer>
            )}
          </Content>
          <DialogV2
            width={hasCancelPolicy ? 800 : undefined}
            height={hasCancelPolicy ? 496 : undefined}
            isOpen={isDeleteModalOpen}
            headerLabel={`${isProvisionalReservation ? '仮' : EMPTY}予約の取り消し`}
            buttonsOnTheRight={[
              <LargeButton key='cancel' label='閉じる' onClick={() => setIsDeleteModalOpen(false)} />,
              <LargeButton type='primary' key='delete' label='予約取り消し' onClick={deleteReservation} />
            ]}
            onClose={() => setIsDeleteModalOpen(false)}
          >
            <CancelDialogContainer>
              <CancelDialogDescription>予約を取り消します。よろしいですか？</CancelDialogDescription>
              {!!cancelPolicyRemarks && <CancelDialogRemarks>{cancelPolicyRemarks}</CancelDialogRemarks>}
              {hasCancelPolicy && (
                <CancelDialogPolicyContainer>
                  <CancelDialogPolicyTitle>キャンセルポリシー</CancelDialogPolicyTitle>
                  <CancelDialogPolicyDesc>{cancelPolicyDescription}</CancelDialogPolicyDesc>
                  {!!cancelPolicyAdditionalInfo && (
                    <CancelDialogPolicyDetail>
                      <div>詳細は以下のとおりです。</div>
                      <div>{cancelPolicyAdditionalInfo}</div>
                    </CancelDialogPolicyDetail>
                  )}
                </CancelDialogPolicyContainer>
              )}
            </CancelDialogContainer>
          </DialogV2>
          <DefaultModal
            isOpen={isCheckoutModalOpen}
            headerLabel='利用終了'
            rightButtonProps={{ label: '利用終了', onClick: checkoutSpaceReservation }}
            onClose={() => setIsCheckoutModalOpen(false)}
          >
            <Text>{checkoutMessage}</Text>
          </DefaultModal>
          <DefaultModal
            isOpen={isAbsenceModalOpen}
            headerLabel='参加キャンセル'
            rightButtonProps={{ label: '参加キャンセル', onClick: cancelInvitation }}
            onClose={() => setIsAbsenceModalOpen(false)}
          >
            <Text>参加キャンセル後は解錠できなくなります。よろしいですか？</Text>
          </DefaultModal>
        </Container>
      )}
      {isInitialized.current && !reservation && (
        <Container>
          <BackButtonWrapper>
            <BackButtonV2 label='戻る' onClick={() => openMySchedule(false)} />
          </BackButtonWrapper>
          <Thumbnail src={mojaco_sorry} />
          <CenteringText>指定した予約は見つかりませんでした。</CenteringText>
        </Container>
      )}
      <ScreenLoaderV2 loading={isLoading} />
    </Component>
  );
});

AccountReservationDetail.displayName = 'AccountReservationDetail';
export default AccountReservationDetail;

const styleForComponent: CSSProperties = {
  width: '100%',
  height: '100%'
};

const Container = styled.div`
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  margin: 0 auto;
  padding-bottom: ${themeV2.mixins.v2.spacing * 15}px;
`;

const ThumbnailWrapper = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const BackButtonWrapper = styled.div`
  width: 100%;
  padding: ${themeV2.mixins.v2.spacing}px;
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 0 ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing * 3}px;
  gap: 16px;
`;

const ReservationNameWrapper = styled.div`
  ${themeV2.mixins.v2.typography.headLine.medium}
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: 12px;
`;

const CardRow = styled.div<{ align?: 'start' | 'center' }>`
  display: flex;
  align-items: ${({ align = 'center' }) => align};
`;

const UnlockKeysWrapper = styled(CardRow)`
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  width: 100%;
  align-items: flex-start;
`;

const ExtensionWrapper = styled(CardRow)`
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  width: 100%;
`;

const EditWrapper = styled(CardRow)`
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing / 2}px;
  width: 100%;
  font-size: 14px;
`;
const DeadlineText = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV2.mixins.v2.color.font.gray};
  width: 100%;
  white-space: pre-wrap;
`;

const LabelWrapper = styled.div`
  min-width: 120px;
  margin-right: ${themeV2.mixins.v2.spacing}px;
`;

const Text = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
  white-space: pre-wrap;
`;

const Pre = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
  word-wrap: break-word;
  white-space: pre-wrap;
`;

const CenteringText = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV2.mixins.v2.color.font.gray};
  text-align: center;
`;

const Border = styled.div`
  border-top: 1px dashed ${themeV2.mixins.v2.color.border.gray};
  margin: ${themeV2.mixins.v2.spacing * 2}px 0;
`;

const QrCodeArea = styled.div`
  width: 100%;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const QrCode = styled.img`
  width: 120px;
  height: 120px;
  -webkit-user-drag: none;
`;

const ListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.div`
  ${themeV2.mixins.v2.typography.title.large};
  color: ${themeV2.mixins.v2.color.font.black};
  margin-left: ${themeV2.mixins.v2.spacing}px;
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing}px;
  border-top: 1px solid ${themeV2.mixins.v2.color.border.gray};
  padding-top: ${themeV2.mixins.v2.spacing * 2}px;
`;

const LargeButton = styled(ButtonV2).attrs(() => ({ size: 'large' }))``;

const LargeLabel = styled(LabelV2).attrs(() => ({ fontSize: 'large' }))``;

const CancelDialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  overflow-y: scroll;
  height: max-content;
`;

const CancelDialogDescription = styled.div`
  align-self: stretch;
  color: ${themeV2.mixins.v2.color.font.black};
  ${themeV2.mixins.v2.typography.body.medium}
  line-height: 150%;
  white-space: pre-wrap;
`;

const CancelDialogRemarks = CancelDialogDescription;

const CancelDialogPolicyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  height: max-content;
  margin-top: ${themeV2.mixins.v2.spacing * 2}px;
`;

const CancelDialogPolicyTitle = styled.div`
  width: 100%;
  flex: 1 0 0;
  color: ${themeV2.mixins.v2.color.font.black};
  ${themeV2.mixins.v2.typography.title.large}
  line-height: 125%;
`;
const CancelDialogPolicyDesc = styled.div`
  color: ${themeV2.mixins.v2.color.font.black};
  ${themeV2.mixins.v2.typography.body.medium}
  line-height: 150%;
  white-space: pre-wrap;
`;
const CancelDialogPolicyDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 1.5}px;
  color: ${themeV2.mixins.v2.color.font.black};
  ${themeV2.mixins.v2.typography.body.medium}
  line-height: 150%;
  white-space: pre-wrap;
  /* margin-top: ${themeV2.mixins.v2.spacing * 2}px; */
`;
const CloseButtonWrapper = styled.div`
  margin-left: auto;
`;
