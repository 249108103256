import {
  ButtonV2,
  ColgroupV2,
  Component,
  LabelV2,
  ScreenLoaderV2,
  TableV2,
  TbodyV2,
  TdV2,
  TheadV2,
  themeV2,
  ThV2,
  TrV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  BaseFunctionToggleCode,
  BillingIdV2,
  BillingLogV2,
  BillingStatus,
  BillingV2,
  ContractSortColumn,
  CreditCard,
  FETCH_BILLING_V2_FOR_ADMIN,
  FETCH_CREDIT_CARD_FOR_ADMIN,
  FetchBillingV2ForAdminRequest,
  FetchBillingV2ForAdminResponse,
  FetchCreditCardForAdminRequest,
  FetchCreditCardForAdminResponse,
  isBaseFunctionToggleEnabled,
  PAY_BILLINGS_V2_FOR_ADMIN,
  PayBillingsV2ForAdminRequest,
  PayBillingsV2ForAdminResponse,
  PaymentMethod,
  PriceUtils,
  SAVE_BILLING_LOG_V2_FOR_ADMIN,
  SAVE_BILLINGS_V2_FOR_ADMIN,
  SaveBillingLogV2ForAdminRequest,
  SaveBillingLogV2ForAdminResponse,
  SaveBillingsV2ForAdminRequest,
  SaveBillingsV2ForAdminResponse,
  Sort,
  SpaceReservation,
  TaxDiv,
  User
} from '@atomica.co/irori';
import { Hour, Index, Key, Label as LabelType } from '@atomica.co/types';
import {
  builder,
  calcTimeDiff,
  embedIdInPath,
  EMPTY,
  hasLength,
  isGreaterThanZero,
  isLessThanZero,
  Language,
  ONE,
  toHour,
  toZonedTimeFromUtc,
  uuid,
  ZERO
} from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import { CheckCircleOutline, InfoOutlined, OpenInNew, WarningRounded } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { format } from 'date-fns';
import React, { ReactNode, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import DefaultModal from '../../../components/modal/DefaultModal';
import {
  BillingRelation,
  InvoiceDetail,
  InvoiceHeader,
  InvoicePriceSummary,
  PriceDescription,
  toBillingRelation,
  toInvoiceDetails,
  toInvoiceHeader,
  toInvoicePriceSummary
} from '../../../converters/billing-v2-converter';
import useCachedSearchContractInfo, { CachedSearchContractInfo } from '../../../redux/hooks/useCachedContractList';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import usePath from '../../../redux/hooks/usePath';
import { Path, PATH_IDS } from '../../../router/Routes';
import ItemService from '../../../services/item-service';
import {
  BILLING_DETAIL_PAYMENT_METHOD_LABEL,
  BILLING_PAYMENT_METHOD_LABEL,
  CONFIRM_BILLING,
  HONORIFIC_TYPE_LABEL,
  PAYMENT_EXEC_SUCCESS_BILLING,
  PAYMENT_FAIL_BILLING,
  UNDO_CONFIRED_BILLING
} from '../../../texts/billing-v2-text';
import { TAX_DIV_LABELS } from '../../../texts/tax-text';
import { toTimeStr } from '../../../utils/date-util';

const CONFIRMED_BILLING_STATUS: BillingStatus[] = [BillingStatus.CONFIRMED, BillingStatus.PAYMENT_COMPLETED];

const isConfirmed = (billingStatus: BillingStatus): boolean => {
  return CONFIRMED_BILLING_STATUS.includes(billingStatus);
};

interface P {
  confirmed: boolean;
  base: BaseDto;
  user: User;
  billingId: BillingIdV2;
  billing: BillingV2;
  reservations: SpaceReservation[];
  setConfirmed: (value: boolean) => void;
  setSelectedTabIdx: (value: Index) => void;
}

const BillingDetails: React.FC<P> = React.memo(props => {
  const { confirmed, base, user, billingId, reservations, setConfirmed, setSelectedTabIdx } = props;
  const { openPathInNewTab } = usePath();
  const { saveCachedSearchContractInfo } = useCachedSearchContractInfo();
  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const [showLoader, setShowLoader] = useSafeState(unmountRef, false);
  const [billing, setBilling] = useSafeState<BillingV2>(unmountRef, props.billing);

  const invoiceHeader = useMemo((): InvoiceHeader | undefined => toInvoiceHeader(billing), [billing]);
  const invoiceDetails = useMemo((): InvoiceDetail[] => toInvoiceDetails(billing), [billing]);
  const invoicePriceSummary = useMemo((): InvoicePriceSummary | undefined => toInvoicePriceSummary(billing), [billing]);
  const billingRelation = useMemo((): BillingRelation | undefined => toBillingRelation(billing), [billing]);

  const [isResendMailModalOpen, setIsResendMailModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [creditCard, setCreditCard] = useSafeState<CreditCard | undefined>(unmountRef);

  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useSafeState<boolean>(unmountRef, false);

  const isPayWithStripe = useMemo(
    () => isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USE_STRIPE),
    [base]
  );

  const isPayWithSaison = useMemo(
    () => isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USE_SAISON),
    [base]
  );

  const isUseContractPayment = useMemo(() => isPayWithSaison || isPayWithStripe, [isPayWithSaison, isPayWithStripe]);

  const isBillingForExternalService = useMemo(() => {
    const isStripePayment = billing?.paymentMethod === PaymentMethod.CREDIT_CARD && isPayWithStripe;
    const isSaisonPayment =
      [PaymentMethod.BANK_TRANSFER, PaymentMethod.INVOICE].some(e => e === billing?.paymentMethod) && isPayWithSaison;

    return isStripePayment || isSaisonPayment;
  }, [billing?.paymentMethod, isPayWithSaison, isPayWithStripe]);

  const usageMsecOfThisMonth = useMemo<number>(() => {
    const contractUsages = billing?.contract?.contractUsages;
    if (!hasLength(contractUsages)) return 0;
    const zonedCutOffDate = toZonedTimeFromUtc(billing.cutoffDate, base.timezone);
    const thisYearAndMonth = format(zonedCutOffDate, 'yyyyMM');
    const thisMonthContract = contractUsages.find(contractUsage => contractUsage.yearAndMonth === thisYearAndMonth);
    return thisMonthContract?.usageMsec ?? 0;
  }, [base.timezone, billing]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    if (isPayWithStripe && billing?.contract?.paymentMethod === PaymentMethod.CREDIT_CARD) {
      const fetchCreditCardRequest = builder<FetchCreditCardForAdminRequest>()
        .baseId(base.baseId)
        .contractId(billing.contract.contractId)
        .build();
      const res = await commonRequest<FetchCreditCardForAdminRequest, FetchCreditCardForAdminResponse>(
        FETCH_CREDIT_CARD_FOR_ADMIN,
        fetchCreditCardRequest
      );
      setCreditCard(res?.creditCard);
    }
    setConfirmed(isConfirmed(billing?.billingStatus));
  }, [
    isPayWithStripe,
    billing?.contract?.paymentMethod,
    billing?.contract?.contractId,
    billing?.billingStatus,
    setConfirmed,
    base.baseId,
    commonRequest,
    setCreditCard
  ]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    setBilling(props.billing);
  }, [setBilling, props.billing]);

  const calcTotalConferenceUsageHours = useSafeCallback((): Hour => {
    if (!hasLength(reservations)) return ZERO;
    return reservations.reduce((pre, curr) => {
      const baseResourceCategory = curr.space?.baseResourceCategory;
      if (!baseResourceCategory?.isContractPlanResourceUsageSummaryTarget) return pre;
      return pre + toHour(calcTimeDiff(curr.startAt!, curr.endAt!));
    }, ZERO);
  }, [reservations]);

  const handleConfirmationClicked = useSafeCallback(async (): Promise<void> => {
    setShowLoader(true);

    const billingToSave = builder<BillingV2>()
      .billingId(billing.billingId)
      .billingNo(billing.billingNo)
      .billingStatus(!confirmed ? BillingStatus.CONFIRMED : BillingStatus.UNCONFIRMED)
      .billingName(billing.billingName)
      .contractorInfo(billing.contractorInfo)
      .remarks(billing.remarks)
      .billingDate(billing.billingDate)
      .cutoffDate(billing.cutoffDate)
      .paymentDueDate(billing.paymentDueDate)
      .paymentMethod(billing.paymentMethod)
      .payableAccount(billing.payableAccount)
      .taxIncludedTotalPrice(billing.taxIncludedTotalPrice)
      .taxExcludedTotalPrice(billing.taxExcludedTotalPrice)
      .contract(billing.contract)
      .base(base)
      .build();

    const billingRequest = builder<SaveBillingsV2ForAdminRequest>()
      .baseId(base.baseId)
      .billings([billingToSave])
      .build();
    await commonRequest<SaveBillingsV2ForAdminRequest, SaveBillingsV2ForAdminResponse>(
      SAVE_BILLINGS_V2_FOR_ADMIN,
      billingRequest
    );

    const operation = confirmed ? UNDO_CONFIRED_BILLING : CONFIRM_BILLING;
    const billingLog = builder<BillingLogV2>()
      .billingLogId(uuid())
      .billing(billingToSave)
      .operation(operation)
      .createdUser(user)
      .build();
    const billingLogRequest = builder<SaveBillingLogV2ForAdminRequest>()
      .baseId(base.baseId)
      .billingLogs([billingLog])
      .build();
    await commonRequest<SaveBillingLogV2ForAdminRequest, SaveBillingLogV2ForAdminResponse>(
      SAVE_BILLING_LOG_V2_FOR_ADMIN,
      billingLogRequest
    );
    const request = builder<FetchBillingV2ForAdminRequest>().baseId(base.baseId).billingId(billingId).build();
    const { billing: confirmedBilling } = await commonRequest<
      FetchBillingV2ForAdminRequest,
      FetchBillingV2ForAdminResponse
    >(FETCH_BILLING_V2_FOR_ADMIN, request);

    setBilling(confirmedBilling);
    setConfirmed(isConfirmed(confirmedBilling.billingStatus));
    setShowLoader(false);
  }, [base, confirmed, billing, setConfirmed, user, setShowLoader]);

  const handleActionButtonClicked = useSafeCallback(async (): Promise<void> => {
    if (
      billing?.billingStatus === BillingStatus.CONFIRMED ||
      billing?.paymentMethod === PaymentMethod.CASH ||
      !isBillingForExternalService
    ) {
      await handleConfirmationClicked();
      return;
    }

    setIsOpenConfirmDialog(true);
  }, [billing?.billingStatus, billing?.paymentMethod, setIsOpenConfirmDialog, handleConfirmationClicked]);

  const paymentBillings = useSafeCallback(async (): Promise<void> => {
    setShowLoader(true);
    setIsOpenConfirmDialog(false);

    const paymentRequest = builder<PayBillingsV2ForAdminRequest>()
      .baseId(base.baseId)
      .billingId(billing.billingId)
      .build();
    const { result: paymentResult, billing: payedBilling } = await commonRequest<
      PayBillingsV2ForAdminRequest,
      PayBillingsV2ForAdminResponse
    >(PAY_BILLINGS_V2_FOR_ADMIN, paymentRequest);

    const billingLog = builder<BillingLogV2>()
      .billingLogId(uuid())
      .billing(billing)
      .operation(paymentResult ? PAYMENT_EXEC_SUCCESS_BILLING : PAYMENT_FAIL_BILLING)
      .createdUser(user)
      .build();
    const billingLogRequest = builder<SaveBillingLogV2ForAdminRequest>()
      .baseId(base.baseId)
      .billingLogs([billingLog])
      .build();
    await commonRequest<SaveBillingLogV2ForAdminRequest, SaveBillingLogV2ForAdminResponse>(
      SAVE_BILLING_LOG_V2_FOR_ADMIN,
      billingLogRequest
    );

    payedBilling && setBilling(payedBilling);
    payedBilling && setConfirmed(isConfirmed(payedBilling.billingStatus));
    setShowLoader(false);
  }, [
    base.baseId,
    billing,
    commonRequest,
    confirmed,
    setBilling,
    setConfirmed,
    setShowLoader,
    user,
    setIsOpenConfirmDialog
  ]);

  const forwardToContractDetails = useSafeCallback((): void => {
    const contractsInfo = builder<CachedSearchContractInfo>()
      .contracts([billing.contract!])
      .selectedContractIds([billing.contract!.contractId])
      .offset(ZERO)
      .searchingWord(EMPTY)
      .sortKey(ContractSortColumn.CONTRACT_NO)
      .sort(Sort.DESC)
      .totalRecordCount(ONE)
      .build();

    saveCachedSearchContractInfo(contractsInfo);
    openPathInNewTab(
      embedIdInPath(Path.CONTRACT_DETAILS_V2_DETAIL, PATH_IDS, [base.baseCode, billing.contract!.contractId])
    );
  }, [base, openPathInNewTab, billing, saveCachedSearchContractInfo]);

  const constructPriceSummaryRow = useSafeCallback(
    (taxDiv: TaxDiv, desc: PriceDescription, idx?: Index): ReactNode | undefined => {
      const { price, tax, taxRate } = desc;
      // HACK: isLessThanZeroで本来は処理できるができないため、price === zeroを利用して判定を行っている
      if (isLessThanZero(price)) return;
      if (price === ZERO) return;
      return (
        <InvoicePriceCategory key={`${taxDiv}${idx || ZERO}`}>
          <InvoicePriceRow>
            <InvoicePriceCategoryLabel>
              {taxDiv === TaxDiv.EXEMPT ? TAX_DIV_LABELS[taxDiv] : `${TAX_DIV_LABELS[taxDiv]} ${taxRate}%対象`}
            </InvoicePriceCategoryLabel>
            <InvoicePriceCategoryValue>{`¥${price.toLocaleString()}`}</InvoicePriceCategoryValue>
          </InvoicePriceRow>

          {taxDiv !== TaxDiv.EXEMPT && (
            <InvoicePriceRow>
              <InvoicePriceTaxLabel>(内消費税等</InvoicePriceTaxLabel>
              <InvoicePriceTaxValue>{`¥${tax.toLocaleString()})`}</InvoicePriceTaxValue>
            </InvoicePriceRow>
          )}
        </InvoicePriceCategory>
      );
    },
    []
  );

  const convertHeaderLabel = useSafeCallback((): LabelType => {
    const status = billing?.billingStatus;
    switch (status) {
      case BillingStatus.CONFIRMED:
        return '確定済みの請求です。';
      case BillingStatus.UNCONFIRMED:
        return '未確定の請求です。';
      case BillingStatus.PAYMENT_COMPLETED:
        return '決済成功の請求です。';
      case BillingStatus.PAYMENT_ERROR:
        return '決済エラーの請求です。';
      default:
        return EMPTY;
    }
  }, [billing]);

  const generateHeaderButtonProps = useSafeCallback((): Partial<Parameters<typeof ButtonV2>['0']> & {
    fontColor: string;
  } => {
    switch (billing?.billingStatus) {
      case BillingStatus.UNCONFIRMED:
        return { label: '請求金額を確定する', type: 'tertiary', fontColor: '#E05245' };
      case BillingStatus.PAYMENT_ERROR:
        return { label: '請求金額を確定する', type: 'tertiary', fontColor: '#2B2B2A' };
      default:
        return { label: '未確定に戻す', type: 'tertiary', fontColor: '#2B2B2A' };
    }
  }, [billing]);

  const headerIcon = useMemo((): JSX.Element => {
    switch (billing?.billingStatus) {
      case BillingStatus.UNCONFIRMED:
        return <StyledInfoOutlined />;
      case BillingStatus.PAYMENT_ERROR:
        return <StyledErrorOutlined />;
      case BillingStatus.CONFIRMED:
      case BillingStatus.PAYMENT_COMPLETED:
        return <StyledCheckCircleOutlined />;
      default:
        return <></>;
    }
  }, [billing]);

  const isCreditCardExpired = useSafeCallback((creditCard: CreditCard) => {
    const now = new Date();
    const thisMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    const expirationDate = new Date(creditCard.expYear, creditCard.expMonth, 1);
    return thisMonth > expirationDate;
  }, []);

  const displayCreditCardExpiredAlert = useSafeCallback(() => {
    return (
      billing?.billingStatus !== BillingStatus.PAYMENT_COMPLETED &&
      creditCard &&
      billing.contract?.paymentMethod === PaymentMethod.CREDIT_CARD &&
      isCreditCardExpired(creditCard)
    );
  }, [billing?.billingStatus, billing?.contract?.paymentMethod, creditCard, isCreditCardExpired]);

  return (
    <Component className='billing-detail'>
      <Container data-testid='billing-details-screen'>
        {displayCreditCardExpiredAlert() && (
          <AlertContent>
            <ConfirmedWrapperForContractPayment status={BillingStatus.PAYMENT_ERROR}>
              <Flex>
                {headerIcon}
                <AlertLabelForContractPayment>クレジットカードが利用できません。</AlertLabelForContractPayment>
              </Flex>
            </ConfirmedWrapperForContractPayment>
            <AlertTextWrapper>
              クレジットカードの有効期限が切れています。契約先の担当者に、クレジットカードの変更を依頼してください。
              <ButtonV2
                type={'primary'}
                label={'変更を依頼'}
                onClick={() => {
                  setIsResendMailModalOpen(true);
                }}
              />
            </AlertTextWrapper>
            <DefaultModal
              isOpen={isResendMailModalOpen}
              headerLabel={'クレジットカードの変更'}
              rightButtonProps={{ label: '送信', onClick: () => alert('メール再送信') }}
              onClose={() => setIsResendMailModalOpen(false)}
            >
              <ModalContent>{`契約先担当者のメールアドレス\n（${billing.contract?.contractorEmail}）宛に、クレジットカードの変更を案内するメールを送付します。`}</ModalContent>
            </DefaultModal>
          </AlertContent>
        )}
        <Contents>
          {billing && (
            <DetailContent>
              {isUseContractPayment ? (
                <ConfirmedWrapperForContractPayment status={billing?.billingStatus}>
                  <Flex>
                    {headerIcon}
                    <LabelForContractPayment>{convertHeaderLabel()}</LabelForContractPayment>
                  </Flex>
                  {billing?.billingStatus !== BillingStatus.PAYMENT_COMPLETED && (
                    <StyledButtonV2 {...generateHeaderButtonProps()} onClick={handleActionButtonClicked} />
                  )}
                </ConfirmedWrapperForContractPayment>
              ) : (
                <ConfirmedWrapper confirmed={confirmed}>
                  <Label confirmed={confirmed}>{confirmed ? '確定済みの請求です' : '未確定の請求です'}</Label>
                  <ButtonV2
                    type={confirmed ? 'default' : 'primary'}
                    label={confirmed ? '未確定に戻す' : '請求金額を確定する'}
                    onClick={handleConfirmationClicked}
                  />
                </ConfirmedWrapper>
              )}
              <Invoice>
                <StyledInvoiceHeader>
                  <HeaderRow>
                    <HeaderLabel>請求番号:</HeaderLabel>
                    {invoiceHeader ? <HeaderText>{invoiceHeader.billingNo}</HeaderText> : <Skeleton />}
                  </HeaderRow>
                  <HeaderRow>
                    <HeaderLabel>請求日:</HeaderLabel>
                    {invoiceHeader ? (
                      <HeaderText>{format(invoiceHeader.billingDate, 'yyyy/MM/dd')}</HeaderText>
                    ) : (
                      <Skeleton />
                    )}
                  </HeaderRow>
                </StyledInvoiceHeader>

                <Separator />

                <InvoiceBody>
                  <InvoiceTitle>請求情報</InvoiceTitle>
                  <InvoiceTransfer>
                    <div>
                      <Contractor>
                        {invoiceHeader ? (
                          `${invoiceHeader.contractorName} ${HONORIFIC_TYPE_LABEL[invoiceHeader.honorificType]}`
                        ) : (
                          <CustomSkeleton />
                        )}
                      </Contractor>
                      <Text>{invoiceHeader ? invoiceHeader.contractorInfo : <CustomSkeleton />}</Text>
                    </div>

                    <div>
                      <Owner>{base.organization!.organizationName}</Owner>
                      <Text>〒{base.postalCode}</Text>
                      <Text>{base.address1}</Text>
                      {!!base.address2 && <Text>{base.address2}</Text>}
                      <Text>TEL: {base.phoneNumber}</Text>
                    </div>
                  </InvoiceTransfer>

                  <InvoiceName>件名：{invoiceHeader ? invoiceHeader.billingName : <CustomSkeleton />}</InvoiceName>

                  <InvoiceInfo>
                    <InvoiceInfoRow data-testid={'billing-amount'}>
                      <InvoiceInfoLabel>
                        <InvoiceInfoLabelText size='xLarge'>ご請求金額（税込）</InvoiceInfoLabelText>
                      </InvoiceInfoLabel>

                      {billing ? (
                        <InvoiceInfoValue size='large'>
                          ￥{billing.taxIncludedTotalPrice?.toLocaleString() ?? EMPTY}
                        </InvoiceInfoValue>
                      ) : (
                        <CustomSkeleton />
                      )}
                    </InvoiceInfoRow>

                    <InvoiceInfoRow>
                      <InvoiceInfoLabel>
                        <InvoiceInfoLabelText>お支払期日</InvoiceInfoLabelText>
                      </InvoiceInfoLabel>
                      {invoiceHeader ? (
                        <InvoiceInfoValue>
                          {invoiceHeader.paymentDueDate
                            ? format(new Date(invoiceHeader.paymentDueDate), 'yyyy/MM/dd')
                            : '未設定'}
                        </InvoiceInfoValue>
                      ) : (
                        <CustomSkeleton />
                      )}
                    </InvoiceInfoRow>

                    <InvoiceInfoRow>
                      <InvoiceInfoLabel>
                        {invoiceHeader ? (
                          <InvoiceInfoLabelText>
                            {isUseContractPayment
                              ? 'お支払い方法'
                              : BILLING_DETAIL_PAYMENT_METHOD_LABEL[invoiceHeader.paymentMethod]}
                          </InvoiceInfoLabelText>
                        ) : (
                          <CustomSkeleton />
                        )}
                      </InvoiceInfoLabel>

                      {isUseContractPayment ? (
                        <InvoiceInfoValue>
                          {!invoiceHeader && <CustomSkeleton />}
                          {invoiceHeader && BILLING_PAYMENT_METHOD_LABEL[invoiceHeader.paymentMethod]}
                        </InvoiceInfoValue>
                      ) : (
                        <InvoiceInfoValue>
                          {!invoiceHeader && <CustomSkeleton />}
                          {invoiceHeader &&
                            invoiceHeader.paymentMethod === PaymentMethod.BANK_TRANSFER &&
                            invoiceHeader.bankAccount}
                          {invoiceHeader &&
                            invoiceHeader.paymentMethod === PaymentMethod.CREDIT_CARD &&
                            BILLING_PAYMENT_METHOD_LABEL[PaymentMethod.CREDIT_CARD]}
                          {invoiceHeader &&
                            invoiceHeader.paymentMethod === PaymentMethod.INVOICE &&
                            invoiceHeader.payableAccount}
                        </InvoiceInfoValue>
                      )}
                    </InvoiceInfoRow>
                  </InvoiceInfo>

                  <StyledInvoiceDetail>
                    <ContentSubTitle>品目</ContentSubTitle>
                    <TableV2 showBorder={true} shape='circle'>
                      <ColgroupV2 />
                      <ColgroupV2 width={80} />
                      <ColgroupV2 width={100} />
                      <ColgroupV2 width={120} />
                      <ColgroupV2 width={80} />
                      <TheadV2>
                        <TrV2>
                          <ThV2 horizonPadding={8}>品目</ThV2>
                          <ThV2 horizonPadding={8}>単価（円）</ThV2>
                          <ThV2 horizonPadding={8}>数量・単位</ThV2>
                          <ThV2 horizonPadding={8}>課税</ThV2>
                          <ThV2 horizonPadding={8}>金額（円）</ThV2>
                        </TrV2>
                      </TheadV2>
                      <TbodyV2>
                        {hasLength(invoiceDetails) &&
                          invoiceDetails.map((detail: InvoiceDetail, rowIdx: Index) => (
                            <TrV2 key={`detail-tr${rowIdx}`}>
                              <TdV2 horizonPadding={8}>{detail.itemName}</TdV2>
                              <TdV2 align='right' horizonPadding={8}>
                                {detail.unitPrice.toLocaleString()}
                              </TdV2>
                              <TdV2 horizonPadding={8}>
                                {detail.quantity}
                                {detail.unit && detail.unit.unitName}
                              </TdV2>
                              <TdV2 horizonPadding={8}>{ItemService.getTaxLabel(detail.item)}</TdV2>
                              <TdV2 align='right' horizonPadding={8}>
                                {PriceUtils.applyAmountPrice(detail.unitPrice, detail.quantity).toLocaleString()}
                              </TdV2>
                            </TrV2>
                          ))}
                      </TbodyV2>
                    </TableV2>
                  </StyledInvoiceDetail>

                  {!!invoicePriceSummary && (
                    <InvoicePrice>
                      {invoicePriceSummary.included &&
                        Object.keys(invoicePriceSummary.included).map((key: Key, idx: Index) => {
                          return constructPriceSummaryRow(TaxDiv.INCLUDED, invoicePriceSummary.included[key], idx);
                        })}

                      {invoicePriceSummary.excluded &&
                        Object.keys(invoicePriceSummary.excluded).map((key: Key, idx: Index) => {
                          return constructPriceSummaryRow(TaxDiv.EXCLUDED, invoicePriceSummary.excluded[key], idx);
                        })}

                      {isGreaterThanZero(invoicePriceSummary.exempt.price) &&
                        constructPriceSummaryRow(TaxDiv.EXEMPT, invoicePriceSummary.exempt)}

                      <Separator />

                      <InvoicePriceRow>
                        <InvoicePriceLabel>請求金額合計</InvoicePriceLabel>
                        <InvoicePriceLabel>
                          ¥{billing.taxIncludedTotalPrice?.toLocaleString() ?? EMPTY}
                        </InvoicePriceLabel>
                      </InvoicePriceRow>
                    </InvoicePrice>
                  )}
                </InvoiceBody>
              </Invoice>
              <DefaultModal
                isOpen={isOpenConfirmDialog}
                headerLabel={'決済の確認'}
                rightButtonProps={{ label: 'はい', onClick: paymentBillings }}
                onClose={() => setIsOpenConfirmDialog(false)}
              >
                <ModalContent>
                  {billing.paymentMethod === PaymentMethod.INVOICE ||
                  billing.paymentMethod === PaymentMethod.BANK_TRANSFER
                    ? '決済を実行すると、セゾンインボイスの規定に沿うように請求日・お支払期日が更新されます\n' +
                      '決済を実行してもよろしいですか？'
                    : '決済を実行してもよろしいですか？'}
                </ModalContent>
              </DefaultModal>
            </DetailContent>
          )}

          <RelationContent>
            <RelationTitle>関連情報</RelationTitle>
            <Separator />
            {!!billingRelation && (
              <>
                <RelationContract>
                  <RelationSubTitle>契約情報</RelationSubTitle>
                  <LabelV2 text='契約中のプラン' />
                  <BasicPlanField>
                    <BasicPlanName>{billingRelation.contractPlanmName}</BasicPlanName>
                    <BasicPlanInfo>{`会議室利用: ${
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      parseFloat(billingRelation.freeTier as any) || ZERO
                    }時間まで無料`}</BasicPlanInfo>
                    <BasicPlanInfo>{`会議室利用料の割引率: ${billingRelation.discountRate}%`}</BasicPlanInfo>
                  </BasicPlanField>
                </RelationContract>

                {!!billing?.contract?.contractPlan?.usableHours && (
                  <CustomRow>
                    <LabelV2 text='ご利用状況' />
                    <BasicPlanField data-testid='contract-usage-msec'>
                      <BasicPlanName>{toTimeStr(usageMsecOfThisMonth, Language.JAPANESE)}</BasicPlanName>
                    </BasicPlanField>
                  </CustomRow>
                )}

                <LinkButton label='契約詳細へ' onClick={forwardToContractDetails} endIcon={<CustomOpenInNewIcon />} />

                <ContentSubTitle>会議室利用状況</ContentSubTitle>
                <CustomRow>
                  <LabelV2 text='会議室利用時間合計' />
                  <BasicPlanField>
                    <BasicPlanName>{calcTotalConferenceUsageHours()}時間</BasicPlanName>
                  </BasicPlanField>
                  {!!billingRelation.freeTier && (
                    <>
                      <LabelV2 text='残り無料枠' />
                      <BasicPlanField>
                        <BasicPlanName>
                          {isGreaterThanZero(billingRelation.freeTier - calcTotalConferenceUsageHours())
                            ? billingRelation.freeTier - calcTotalConferenceUsageHours()
                            : ZERO}
                          時間/
                          {billingRelation.freeTier}時間
                        </BasicPlanName>
                      </BasicPlanField>
                    </>
                  )}
                </CustomRow>
                <LinkButton label='予約利用明細を確認' onClick={() => setSelectedTabIdx(ONE)} />
              </>
            )}

            {!billingRelation && <LabelV2 text='関連情報はありません' />}
          </RelationContent>
        </Contents>
      </Container>

      <ScreenLoaderV2 loading={showLoader} />
    </Component>
  );
});

BillingDetails.displayName = 'BillingDetails';
export default BillingDetails;

const Container = styled.div`
  width: 100%;
`;

const Contents = styled.div`
  display: grid;
  grid-template-areas:
    'detail relation'
    'detail .';
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 2fr 1fr;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px 0 ${themeV2.mixins.v2.spacing * 10}px;
`;

const ModalContent = styled.div`
  white-space: pre-wrap;
`;

const AlertContent = styled.div`
  background-color: ${themeV2.mixins.v2.color.background.white};
  grid-area: detail;
  height: min-content;
  border-radius: 6px;
  padding: 0;
`;

const DetailContent = styled.div`
  background-color: ${themeV2.mixins.v2.color.background.white};
  grid-area: detail;
  height: min-content;
  border-radius: 6px;
  padding: 0;
`;

const AlertTextWrapper = styled.div`
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const Invoice = styled.div`
  padding: ${themeV2.mixins.v2.spacing * 3}px;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const ConfirmedWrapper = styled.div<{ confirmed: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ confirmed }) =>
    confirmed ? themeV2.mixins.v2.color.status.successPale : themeV2.mixins.v2.color.background.lightGray};
  border-radius: 6px 6px 0 0;
  padding: ${`${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px`};
`;

const ConfirmedWrapperForContractPayment = styled.div<{ status: BillingStatus }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ status }) => {
    switch (status) {
      case BillingStatus.UNCONFIRMED:
        return '#EF766B'; // FIXME
      case BillingStatus.CONFIRMED:
      case BillingStatus.PAYMENT_COMPLETED:
        return '#008C7B';
      case BillingStatus.PAYMENT_ERROR:
        return '#A30A04';
      default:
        return '#EF766B';
    }
  }};
  border-radius: 6px 6px 0 0;
  padding: ${`${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px`};
`;

const StyledInvoiceHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
`;

const HeaderRow = styled.div`
  display: flex;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const HeaderLabel = styled(Typography)`
  ${themeV2.mixins.v2.typography.body.large};
`;

const HeaderText = styled(Typography)`
  ${themeV2.mixins.v2.typography.body.large};
  width: 120px;
`;

const InvoiceBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${themeV2.mixins.v2.spacing}px;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
`;

const Separator = styled.hr`
  width: 100%;
  border-bottom: ${themeV2.mixins.v2.color.border.gray};
  margin: 0;
`;

const InvoiceTitle = styled(Typography)`
  ${themeV2.mixins.v2.typography.title.xLarge};
  font-size: 28px;
  text-align: center;
`;

const InvoiceTransfer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
`;

const InvoiceInfo = styled.div`
  border: 1px solid ${themeV2.mixins.v2.color.border.gray};
`;

const InvoiceInfoRow = styled.div<{ direction?: 'row' | 'column' }>`
  width: 100%;
  display: flex;
  flex-direction: ${({ direction = 'row' }) => direction};
  align-items: center;
  &:not(&:last-child) {
    border-bottom: 1px solid ${themeV2.mixins.v2.color.border.gray};
  }
`;

const InvoiceInfoLabel = styled.div`
  display: flex;
  align-items: center;
  min-height: 64px;
  background-color: ${themeV2.mixins.v2.color.background.lightGray};
`;

const InvoiceInfoLabelText = styled.div<{ size?: 'medium' | 'large' | 'xLarge' }>`
  ${({ size = 'medium' }) => `${themeV2.mixins.v2.typography.title[size]}`}
  width: 280px;
  padding: ${themeV2.mixins.v2.spacing * 3}px ${themeV2.mixins.v2.spacing * 2}px;
  align-items: center;
`;

const InvoiceInfoValue = styled.div<{ size?: 'medium' | 'large' }>`
  ${({ size = 'medium' }) => `
    ${themeV2.mixins.v2.typography.title[size]}
    ${
      size === 'medium' &&
      `
      font-weight: 400;
      color: ${themeV2.mixins.v2.color.font.gray};
    `
    }
  `}
  flex: 1;
  padding: ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing * 2}px;
  align-items: center;
`;

const StyledInvoiceDetail = styled.div``;

const Text = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
  white-space: pre-wrap;
`;

const Contractor = styled(Typography)`
  width: 366px;
  height: 52px;
  line-height: 26px;
  ${themeV2.mixins.v2.typography.title.large};
  margin-bottom: ${themeV2.mixins.v2.spacing}px;
`;

const Owner = styled(Typography)`
  width: 366px;
  height: 26px;
  line-height: 26px;
  ${themeV2.mixins.v2.typography.body.large};
  margin-bottom: ${themeV2.mixins.v2.spacing}px;
`;

const InvoiceName = styled.div`
  ${themeV2.mixins.v2.typography.headLine.small};
  margin-top: ${themeV2.mixins.v2.spacing}px;
`;

const ContentSubTitle = styled.div`
  ${themeV2.mixins.v2.typography.title.medium};
  color: ${themeV2.mixins.v2.color.font.black};
  padding: ${themeV2.mixins.v2.spacing}px 0;
  width: 100%;
`;

const Label = styled.div<{ confirmed: boolean }>`
  ${themeV2.mixins.v2.typography.label.large};
  color: ${({ confirmed }) => (confirmed ? themeV2.mixins.v2.color.font.green : themeV2.mixins.v2.color.font.black)};
`;

const AlertLabelForContractPayment = styled.div`
  ${themeV2.mixins.v2.typography.label.large};
  color: ${themeV2.mixins.v2.color.font.white};
`;

const LabelForContractPayment = styled.div`
  ${themeV2.mixins.v2.typography.label.large};
  color: ${themeV2.mixins.v2.color.font.white};
`;

const CustomRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing / 2}px;
`;

const BasicPlanField = styled.div`
  background-color: ${themeV2.mixins.v2.color.background.lightGray};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  gap: ${themeV2.mixins.v2.spacing / 4}px;
`;

const BasicPlanName = styled(Typography)`
  ${themeV2.mixins.v2.typography.label.large};
  color: #4f4f4f; //FIXME
`;

const BasicPlanInfo = styled(Typography)`
  color: #666666;
  ${themeV2.mixins.v2.typography.body.small};
  line-height: 150%;
`;

const InvoicePrice = styled.div`
  width: 50%;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: right;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const InvoicePriceRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InvoicePriceCategory = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const InvoicePriceCategoryLabel = styled.div`
  ${themeV2.mixins.v2.typography.label.large};
  color: ${themeV2.mixins.v2.color.font.black};
  font-size: 16px;
`;

const InvoicePriceCategoryValue = styled.div`
  ${themeV2.mixins.v2.typography.label.large};
  color: ${themeV2.mixins.v2.color.font.black};
  font-size: 16px;
`;

const InvoicePriceTaxLabel = styled.div`
  ${themeV2.mixins.v2.typography.label.large};
  font-weight: 400;
  color: ${themeV2.mixins.v2.color.font.gray};
`;

const InvoicePriceTaxValue = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
  color: ${themeV2.mixins.v2.color.font.gray};
`;

const InvoicePriceLabel = styled.div`
  ${themeV2.mixins.v2.typography.label.large};
  color: ${themeV2.mixins.v2.color.font.black};
  font-size: 16px;
  padding: ${themeV2.mixins.v2.spacing}px 0;
`;

const RelationContent = styled.div`
  background-color: ${themeV2.mixins.v2.color.background.white};
  border-radius: 6px;
  padding: ${`${themeV2.mixins.v2.spacing * 3}px ${themeV2.mixins.v2.spacing * 3}px`};
  grid-area: relation;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const RelationContract = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const RelationTitle = styled(Typography)`
  ${themeV2.mixins.v2.typography.title.xLarge};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const RelationSubTitle = styled(Typography)`
  ${themeV2.mixins.v2.typography.title.medium};
  color: ${themeV2.mixins.v2.color.font.black};
  padding: ${`${themeV2.mixins.v2.spacing}px 0`};
`;

const LinkButton = styled(ButtonV2)`
  font-weight: 400;
  color: ${themeV2.mixins.v2.color.background.pink} !important;
`;

const CustomOpenInNewIcon = styled(OpenInNew)`
  color: ${themeV2.mixins.v2.color.background.pink};
`;

const CustomSkeleton = styled(Skeleton)`
  width: 100%;
`;

const StyledButtonV2 = styled(ButtonV2)<{ fontColor: string }>`
  color: ${({ fontColor }) => fontColor} !important;
`;

const StyledInfoOutlined = styled(InfoOutlined)`
  color: ${themeV2.mixins.v2.color.background.white} !important;
`;

const StyledCheckCircleOutlined = styled(CheckCircleOutline)`
  color: ${themeV2.mixins.v2.color.background.white} !important;
`;

const StyledErrorOutlined = styled(WarningRounded)`
  color: ${themeV2.mixins.v2.color.background.white} !important;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
