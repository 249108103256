import {
  ButtonV2,
  ColWidth,
  Component,
  DateFieldV2,
  Header,
  PageHeaderV2,
  PAGINATE_HEIGHT,
  SearchListV2,
  SelectBoxV2,
  StatusV2,
  styleForFullExpansion,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  BaseFunctionToggleCode,
  BillingDateColumn,
  BillingIdV2,
  BillingLogV2,
  BillingSortColumn,
  BillingStatus,
  BillingV2,
  DELETE_BILLINGS_V2_FOR_ADMIN,
  DeleteBillingsV2ForAdminRequest,
  DeleteBillingsV2ForAdminResponse,
  isBaseFunctionToggleEnabled,
  PAY_MULTI_BILLINGS_V2_FOR_ADMIN,
  PaymentMethod,
  PayMultiBillingsV2ForAdminRequest,
  PayMultiBillingsV2ForAdminResponse,
  SAVE_BILLING_LOG_V2_FOR_ADMIN,
  SAVE_BILLINGS_V2_FOR_ADMIN,
  SaveBillingLogV2ForAdminRequest,
  SaveBillingLogV2ForAdminResponse,
  SaveBillingsV2ForAdminRequest,
  SaveBillingsV2ForAdminResponse,
  SEARCH_BILLINGS_V2_FOR_ADMIN,
  SearchBillingsV2ForAdminRequest,
  SearchBillingsV2ForAdminResponse,
  Sort,
  User
} from '@atomica.co/irori';
import { Code, Count, DateStr, Id, Index, Name, NoStr, Offset, Operation, PriceStr, Word } from '@atomica.co/types';
import {
  builder,
  embedIdInPath,
  EMPTY,
  hasLength,
  toBeginningOfDay,
  toEndOfDay,
  toFirstDayOfMonth,
  toLastDayOfMonth,
  uuid,
  ZERO
} from '@atomica.co/utils';
import { Add } from '@material-ui/icons';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useRef } from 'react';
import { CSVDownload } from 'react-csv';
import styled from 'styled-components';
import DefaultModal from '../../components/modal/DefaultModal';
import {
  BILLING_ACTION_OPTIONS,
  BILLING_DATE_OPTIONS,
  BILLING_PAYMENT_OPTIONS,
  BILLING_STATUS_OPTIONS,
  BILLING_STATUS_OPTIONS_USE_PAYMENT
} from '../../constants/billing-v2-const';
import { BillingCSV, convertToBillingCSV } from '../../converters/export-converter';
import { BillingAction } from '../../enums/billing-v2-enum';
import { CSVTemplate } from '../../models/common-model';
import { useSnackbarV2 } from '../../provider/SnackbarProviderV2';
import useCachedBillingList, { CachedBillingInfo } from '../../redux/hooks/useCachedBillingList';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import usePath from '../../redux/hooks/usePath';
import { Path, PATH_IDS } from '../../router/Routes';
import {
  BILLING_ACTION_LABEL,
  BILLING_CONFIRMED,
  BILLING_DATE_LABEL,
  BILLING_DELETED,
  BILLING_FAILED,
  BILLING_HEADERS,
  BILLING_PAYED,
  BILLING_PAYMENT_METHOD_LABEL,
  BILLING_STATUS_LABEL
} from '../../texts/billing-v2-text';
import { FAILED_PAYMENT, SUCCEEDED_PAYMENT } from '../../texts/snackbar-text';
import { partialBuilder } from '../../utils/common-util';
import { updateSortCol } from '../../utils/contract-v2-util';

const LIMIT = 50;

const COLUMN_WIDTH: ColWidth = {
  billingStatus: 80,
  billingNo: 80,
  contractorName: 160,
  billingName: 160,
  taxIncludedTotalPrice: 80,
  cutoffDate: 80,
  billingDate: 80
};

const HEADER: Header = {
  billingStatus: { label: 'ステータス' },
  billingNo: { label: '請求番号' },
  contractorName: { label: '法人名' },
  billingName: { label: '件名' },
  taxIncludedTotalPrice: { label: '請求金額' },
  cutoffDate: { label: '請求締め日' },
  billingDate: { label: '請求日' }
};

const SORT_COLUMNS = {
  billingStatus: BillingSortColumn.BILLING_STATUS,
  billingNo: BillingSortColumn.BILLING_NO,
  contractorName: BillingSortColumn.CONTRACTOR_NAME,
  billingName: BillingSortColumn.BILLING_NAME,
  taxIncludedTotalPrice: BillingSortColumn.TAX_INCLUDED_TOTAL_PRICE,
  cutoffDate: BillingSortColumn.CUTOFF_DATE,
  billingDate: BillingSortColumn.BILLING_DATE
};

interface BillingRow {
  id: Id;
  billingStatus: JSX.Element;
  billingNo: NoStr;
  contractorName: Name;
  billingName: Name;
  taxIncludedTotalPrice: PriceStr;
  cutoffDate: DateStr;
  billingDate: DateStr;
}

interface P {
  base: BaseDto;
  user: User;
}

const BillingListScreen: React.FC<P> = React.memo(props => {
  const { base, user } = props;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const csvInstance = useRef<any>();
  const { openPath, openPathInNewTab } = usePath();
  const { cachedBillingInfo, saveCachedBillingInfo, clearCachedBillingInfo } = useCachedBillingList();
  const { commonRequest } = useCommonRequest();
  const { openSnackbar } = useSnackbarV2();

  const unmountRef = useUnmountRef();
  const [showLoader, setShowLoader] = useSafeState<boolean>(unmountRef, true);
  const [offset, setOffset] = useSafeState<Offset>(unmountRef, cachedBillingInfo ? cachedBillingInfo.offset : ZERO);
  const [totalRecordCount, setTotalRecordCount] = useSafeState<Count>(
    unmountRef,
    cachedBillingInfo.totalRecordCount || ZERO
  );
  const [sortKey, setSortKey] = useSafeState<BillingSortColumn>(
    unmountRef,
    cachedBillingInfo?.sortKey ?? BillingSortColumn.BILLING_NO
  );
  const [sort, setSort] = useSafeState<Sort>(unmountRef, cachedBillingInfo?.sort ?? Sort.DESC);
  const [startDate, setStartDate] = useSafeState<Date>(
    unmountRef,
    cachedBillingInfo?.start ? new Date(cachedBillingInfo.start) : toFirstDayOfMonth(new Date(), ZERO)
  );
  const [endDate, setEndDate] = useSafeState<Date>(
    unmountRef,
    cachedBillingInfo?.end ? new Date(cachedBillingInfo.end) : toLastDayOfMonth(new Date(), ZERO)
  );
  const [selectedFilterDate, setSelectedFilterDate] = useSafeState<BillingDateColumn>(
    unmountRef,
    cachedBillingInfo?.filterDate ?? BillingDateColumn.CUTOFF_DATE
  );
  const [selectedStatus, setSelectedStatus] = useSafeState<BillingStatus | undefined>(
    unmountRef,
    cachedBillingInfo?.status
  );
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useSafeState<PaymentMethod | undefined>(
    unmountRef,
    cachedBillingInfo?.paymentMethod
  );
  const [selectedAction, setSelectedAction] = useSafeState<BillingAction>(unmountRef, BillingAction.OPERATE_ALL);
  const [searchingWord, setSearchingWord] = useSafeState<Word>(unmountRef, cachedBillingInfo?.searchingWord ?? EMPTY);
  const [selectedBillingIds, setSelectedBillingIds] = useSafeState<BillingIdV2[]>(
    unmountRef,
    cachedBillingInfo?.billings.length !== cachedBillingInfo.selectedBillingIds.length
      ? cachedBillingInfo.selectedBillingIds
      : []
  );
  const [billings, setBillings] = useSafeState<BillingV2[]>(unmountRef, cachedBillingInfo?.billings ?? []);
  const [csvHeaders, setCsvHeaders] = useSafeState<CSVTemplate[]>(unmountRef, []);
  const [csvContent, setCsvContent] = useSafeState<BillingCSV[]>(unmountRef, []);

  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useSafeState<boolean>(unmountRef, false);

  const baseCode = useMemo((): Code => base.baseCode, [base]);
  const baseId = useMemo((): Id => base.baseId, [base]);

  const isUseContractPayment = useMemo(
    () =>
      [BaseFunctionToggleCode.FUNCTION_USE_SAISON, BaseFunctionToggleCode.FUNCTION_USE_STRIPE].some(e =>
        isBaseFunctionToggleEnabled(base, e)
      ),
    [base]
  );
  const isPayWithStripe = useMemo(
    () => isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USE_STRIPE),
    [base]
  );

  const isPayWithSaison = useMemo(
    () => isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USE_SAISON),
    [base]
  );

  const payBillingForExternalService = useSafeCallback(
    (billings: BillingV2[]): [BillingV2[], BillingV2[]] => {
      if (!billings?.length) return [[], []];

      const SAISON_PAYMENT_METHODS: PaymentMethod[] = [PaymentMethod.BANK_TRANSFER, PaymentMethod.INVOICE];

      const stripePaymentBillings = billings.filter(
        b => b?.paymentMethod === PaymentMethod.CREDIT_CARD && isPayWithStripe
      );

      const saisonPaymentBillings = billings.filter(
        b => b?.paymentMethod && SAISON_PAYMENT_METHODS.includes(b.paymentMethod) && isPayWithSaison
      );

      const paymentBillings = [...stripePaymentBillings, ...saisonPaymentBillings];
      if (!paymentBillings.length) return [[], billings.filter(Boolean)];

      const paymentIds = new Set(paymentBillings.map(b => b.billingId));
      const notPaymentBillings = billings.filter(b => b && !paymentIds.has(b.billingId));

      return [paymentBillings, notPaymentBillings];
    },
    [isPayWithStripe, isPayWithSaison]
  );

  const rows = useMemo<BillingRow[]>(() => {
    return billings.map(billing => {
      const row = builder<BillingRow>()
        .id(billing.billingId)
        .billingStatus(toBillingStatusElement(billing.billingStatus))
        .billingNo(billing.billingNo)
        .contractorName(billing.contract ? billing.contractorName : EMPTY)
        .billingName(billing.billingName)
        .taxIncludedTotalPrice(`¥${billing.taxIncludedTotalPrice?.toLocaleString() ?? EMPTY}`)
        .cutoffDate(format(new Date(billing.cutoffDate), 'yyyy/MM/dd'))
        .billingDate(format(new Date(billing.billingDate), 'yyyy/MM/dd'))
        .build();
      return row;
    });
  }, [billings]);

  const initialize = useSafeCallback((): void => {
    setOffset(ZERO);
    setSelectedBillingIds([]);
  }, [setOffset, setSelectedBillingIds]);

  const handleSortColClicked = useSafeCallback(
    (sortCol: BillingSortColumn | undefined): void => {
      updateSortCol(sortCol, setSortKey, setSort);
    },
    [setSortKey, setSort]
  );

  const openBillingDetailsScreen = useSafeCallback(
    (billingId: BillingIdV2, isMoreHorizMenu?: boolean): void => {
      const allBillingIds = billings.map(billing => billing.billingId);

      const billingInfo = builder<CachedBillingInfo>()
        .offset(offset)
        .sortKey(sortKey)
        .sort(sort)
        .totalRecordCount(isMoreHorizMenu ? selectedBillingIds.length : totalRecordCount)
        .filterDate(selectedFilterDate)
        .start(startDate)
        .end(endDate)
        .status(selectedStatus)
        .paymentMethod(selectedPaymentMethod)
        .searchingWord(searchingWord)
        .selectedBillingIds(isMoreHorizMenu ? selectedBillingIds : allBillingIds)
        .billings(billings)
        .build();

      saveCachedBillingInfo(billingInfo);
      openPath(embedIdInPath(Path.BILLING_DETAILS_V2_DETAIL, PATH_IDS, [baseCode, billingId]));
    },
    [
      baseCode,
      billings,
      selectedBillingIds,
      offset,
      searchingWord,
      sortKey,
      sort,
      selectedFilterDate,
      startDate,
      endDate,
      selectedStatus,
      selectedPaymentMethod,
      totalRecordCount,
      openPath,
      saveCachedBillingInfo
    ]
  );

  const searchBillings = useSafeCallback(async (): Promise<void> => {
    setShowLoader(true);
    const request = builder<SearchBillingsV2ForAdminRequest>()
      .baseId(baseId)
      .word(searchingWord)
      .filterDate(selectedFilterDate)
      .start(toBeginningOfDay(startDate)!) // FIXME
      .end(toEndOfDay(endDate)!) // FIXME
      .limit(LIMIT)
      .offset(offset)
      .sortCol(sortKey)
      .sort(sort);

    if (selectedPaymentMethod) request.paymentMethod(selectedPaymentMethod);
    if (selectedStatus) request.status(selectedStatus);

    const response = await commonRequest<SearchBillingsV2ForAdminRequest, SearchBillingsV2ForAdminResponse>(
      SEARCH_BILLINGS_V2_FOR_ADMIN,
      request.build()
    );
    const { billings, count } = response;
    setBillings(billings);
    setTotalRecordCount(count);
    setShowLoader(false);
  }, [
    baseId,
    selectedFilterDate,
    startDate,
    endDate,
    selectedPaymentMethod,
    selectedStatus,
    sortKey,
    sort,
    searchingWord,
    offset,
    setBillings,
    setShowLoader,
    setTotalRecordCount
  ]);

  const refreshBillings = useSafeCallback(async (): Promise<void> => {
    setOffset(ZERO);
    setSelectedBillingIds([]);
    await searchBillings();
  }, [setOffset, setSelectedBillingIds, searchBillings]);

  useEffect(() => {
    if (cachedBillingInfo) return;
    refreshBillings();
  }, [refreshBillings, sortKey, sort, searchingWord, cachedBillingInfo]);

  const handleOffsetChanged = useSafeCallback(async (): Promise<void> => {
    await searchBillings();
  }, [searchBillings]);

  useEffect(() => {
    handleOffsetChanged();
  }, [handleOffsetChanged, offset]);

  useEffect(() => {
    clearCachedBillingInfo();
  }, [clearCachedBillingInfo]);

  const openRegisterBillingScreen = useSafeCallback((): void => {
    const billingInfo = builder<CachedBillingInfo>()
      .billings(billings)
      .selectedBillingIds(selectedBillingIds)
      .offset(offset)
      .searchingWord(searchingWord)
      .sortKey(sortKey)
      .sort(sort)
      .filterDate(selectedFilterDate)
      .start(startDate)
      .end(endDate)
      .status(selectedStatus)
      .paymentMethod(selectedPaymentMethod)
      .totalRecordCount(totalRecordCount)
      .build();

    saveCachedBillingInfo(billingInfo);
    openPath(embedIdInPath(Path.REGISTER_BILLING_V2, PATH_IDS, [baseCode]));
  }, [
    baseCode,
    billings,
    offset,
    openPath,
    saveCachedBillingInfo,
    searchingWord,
    selectedBillingIds,
    sortKey,
    sort,
    selectedFilterDate,
    startDate,
    endDate,
    selectedStatus,
    selectedPaymentMethod,
    totalRecordCount
  ]);

  const saveBillingLog = useSafeCallback(
    async (billings: BillingV2[], operationFunc: (billingId: BillingIdV2) => Operation): Promise<void> => {
      const billingLogs = billings.map(billing => {
        return builder<BillingLogV2>()
          .billingLogId(uuid())
          .billing(billing)
          .operation(operationFunc(billing.billingId))
          .createdUser(user)
          .build();
      });

      const request = builder<SaveBillingLogV2ForAdminRequest>().baseId(base.baseId).billingLogs(billingLogs).build();
      await commonRequest<SaveBillingLogV2ForAdminRequest, SaveBillingLogV2ForAdminResponse>(
        SAVE_BILLING_LOG_V2_FOR_ADMIN,
        request
      );
    },
    [base, commonRequest, user]
  );

  const saveAsConfirmed = useSafeCallback(
    async (billings: BillingV2[]): Promise<void> => {
      const billingsToUpdate = billings.map(billing =>
        partialBuilder(billing, { billingStatus: BillingStatus.CONFIRMED })
      );
      const request = builder<SaveBillingsV2ForAdminRequest>().baseId(base.baseId).billings(billingsToUpdate).build();
      await commonRequest<SaveBillingsV2ForAdminRequest, SaveBillingsV2ForAdminResponse>(
        SAVE_BILLINGS_V2_FOR_ADMIN,
        request
      );
      await saveBillingLog(billingsToUpdate, BILLING_CONFIRMED);
    },
    [base, commonRequest, saveBillingLog]
  );

  const payMultiBillings = useSafeCallback(
    async (billings: BillingV2[]): Promise<void> => {
      setShowLoader(true);
      const billingIds = billings.map(billing => billing.billingId);
      const request = builder<PayMultiBillingsV2ForAdminRequest>().baseId(base.baseId).billingIds(billingIds).build();
      const { succeededIds, failedIds } = await commonRequest<
        PayMultiBillingsV2ForAdminRequest,
        PayMultiBillingsV2ForAdminResponse
      >(PAY_MULTI_BILLINGS_V2_FOR_ADMIN, request);
      const successBillings = billings.filter(({ billingId }) => succeededIds.includes(billingId));
      const failedBillings = billings.filter(({ billingId }) => failedIds.includes(billingId));
      successBillings.length && (await saveBillingLog(successBillings, BILLING_PAYED));
      failedBillings.length && (await saveBillingLog(failedBillings, BILLING_FAILED));

      setShowLoader(false);
      if (failedBillings.length) {
        openSnackbar(FAILED_PAYMENT, 'error');
      } else {
        openSnackbar(SUCCEEDED_PAYMENT, 'success');
      }
    },
    [base.baseId, commonRequest, saveBillingLog]
  );

  const deleteBilling = useSafeCallback(
    async (billings: BillingV2[]): Promise<void> => {
      const billingIds = billings.map(billing => billing.billingId);
      const request = builder<DeleteBillingsV2ForAdminRequest>().baseId(base.baseId).billingIds(billingIds).build();
      await commonRequest<DeleteBillingsV2ForAdminRequest, DeleteBillingsV2ForAdminResponse>(
        DELETE_BILLINGS_V2_FOR_ADMIN,
        request
      );
      saveBillingLog(billings, BILLING_DELETED);
      clearCachedBillingInfo();
    },
    [saveBillingLog, clearCachedBillingInfo, base.baseId]
  );

  const selectedBillings = useMemo<BillingV2[]>(() => {
    return billings.filter(billing => selectedBillingIds.includes(billing.billingId));
  }, [billings, selectedBillingIds]);

  const confirmAll = useSafeCallback(
    async (selectedBillings: BillingV2[]): Promise<void> => {
      setIsOpenConfirmDialog(false);
      if (isUseContractPayment) {
        const payBillings = selectedBillings.filter(({ billingStatus }) => {
          const _: BillingStatus[] = [
            BillingStatus.UNCONFIRMED,
            BillingStatus.PAYMENT_ERROR,
            BillingStatus.PAYMENT_COMPLETED
          ];
          return _.includes(billingStatus);
        });
        const otherBillings = selectedBillings.filter(({ billingStatus }) => {
          const _: BillingStatus[] = [
            BillingStatus.UNCONFIRMED,
            BillingStatus.PAYMENT_ERROR,
            BillingStatus.PAYMENT_COMPLETED
          ];
          return !_.includes(billingStatus);
        });
        const [pays, noPays] = payBillingForExternalService(payBillings);
        if (pays && hasLength(pays)) await payMultiBillings(pays);
        await saveAsConfirmed([...otherBillings, ...noPays]);
      } else {
        await saveAsConfirmed(selectedBillings);
      }
      initialize();
      await searchBillings();
    },
    [saveAsConfirmed, initialize, searchBillings]
  );

  const exportCSV = useSafeCallback(
    (selectedBillings: BillingV2[]): void => {
      setCsvContent([]);
      setTimeout(() => {
        setCsvHeaders(BILLING_HEADERS);
        setCsvContent(convertToBillingCSV(selectedBillings));
      }, 1000);
    },
    [setCsvHeaders, setCsvContent]
  );

  const exportPDF = useSafeCallback((): void => {}, []);

  const deleteAll = useSafeCallback(
    async (selectedBillings: BillingV2[]): Promise<void> => {
      await deleteBilling(selectedBillings);
      initialize();
      await searchBillings();
    },
    [deleteBilling, initialize, searchBillings]
  );

  const isUseSaisonService = useSafeCallback((): boolean => {
    return billings
      .filter(billing => selectedBillingIds.includes(billing.billingId))
      .some(
        billing =>
          billing.paymentMethod === PaymentMethod.BANK_TRANSFER || billing.paymentMethod === PaymentMethod.INVOICE
      );
  }, [billings, selectedBillingIds]);

  const handleActionSelected = useSafeCallback(
    async (selectedAction: BillingAction): Promise<void> => {
      setSelectedAction(selectedAction);
      switch (selectedAction) {
        case BillingAction.OPEN_SELECTED:
          if (!hasLength(selectedBillingIds)) return;
          openBillingDetailsScreen(selectedBillingIds[ZERO], true);
          break;
        case BillingAction.CONFIRM_AMOUNT:
          setIsOpenConfirmDialog(true);
          break;
        case BillingAction.DOWNLOAD_CSV:
          exportCSV(selectedBillings);
          break;
        // case BillingAction.DOWNLOAD_PDF:
        //   exportPDF(selectedBillings);
        //   break;
        case BillingAction.DELETE_ALL:
          await deleteAll(selectedBillings);
          break;
        default:
          break;
      }
    },
    [
      openBillingDetailsScreen,
      selectedBillingIds,
      confirmAll,
      exportCSV,
      exportPDF,
      deleteAll,
      setSelectedAction,
      selectedBillings
    ]
  );

  const handleSearchingWordChanged = useSafeCallback(
    (word: Word): void => {
      initialize();
      setSearchingWord(word);
    },
    [initialize, setSearchingWord]
  );

  const handleClickRow = useSafeCallback(
    (index: Index): void => {
      const billingId: BillingIdV2 = billings[index].billingId;
      openPathInNewTab(embedIdInPath(Path.BILLING_DETAILS_V2_DETAIL, PATH_IDS, [base.baseCode, billingId]));
    },
    [billings, base, openPathInNewTab]
  );

  const handleBillingDateChanged = useSafeCallback(
    (target: BillingDateColumn): void => {
      initialize();
      setSelectedFilterDate(target);
    },
    [initialize, setSelectedFilterDate]
  );

  const handleStartDateChanged = useSafeCallback(
    (startDate: Date): void => {
      initialize();
      setStartDate(startDate);
    },
    [initialize, setStartDate]
  );

  const handleEndDateChanged = useSafeCallback(
    (endDate: Date): void => {
      initialize();
      setEndDate(endDate);
    },
    [initialize, setEndDate]
  );

  const handleBillingStatusChanged = useSafeCallback(
    (status: BillingStatus): void => {
      initialize();
      setSelectedStatus(status);
    },
    [initialize, setSelectedStatus]
  );

  const handlePaymentMethodChanged = useSafeCallback(
    (paymentMethod: PaymentMethod): void => {
      initialize();
      setSelectedPaymentMethod(paymentMethod);
    },
    [initialize, setSelectedPaymentMethod]
  );

  return (
    <Component style={styleForFullExpansion} className='billing-list-screen'>
      <Container>
        <Content>
          <PageHeaderV2
            title='請求'
            rightComponent={
              <ButtonV2 type='primary' label='新規作成' startIcon={<Add />} onClick={openRegisterBillingScreen} />
            }
          />

          <SearchListV2
            name='請求'
            title={`${totalRecordCount}件の請求`}
            colWidth={COLUMN_WIDTH}
            header={HEADER}
            rows={rows}
            sortKey={sortKey}
            sortCol={SORT_COLUMNS}
            paginateProps={{ offset, limit: LIMIT, totalCount: totalRecordCount, setOffset }}
            isLoaderShown={showLoader}
            selectedIds={selectedBillingIds}
            searchFieldProps={{
              placeholder: '請求番号・請求者・作成者',
              searchingWord,
              onChange: handleSearchingWordChanged
            }}
            headerBottomComponent={
              <ConditionWrapper>
                <FieldWrapper>
                  <SelectBoxV2
                    labels={BILLING_DATE_LABEL}
                    options={BILLING_DATE_OPTIONS}
                    value={selectedFilterDate}
                    onChange={handleBillingDateChanged}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <DateFieldV2 shape='circle' value={startDate} onChange={handleStartDateChanged} />
                </FieldWrapper>
                ～
                <FieldWrapper>
                  <DateFieldV2 shape='circle' value={endDate} onChange={handleEndDateChanged} />
                </FieldWrapper>
                <FieldWrapper>
                  <SelectBoxV2
                    nullable
                    placeholder='ステータス'
                    labels={BILLING_STATUS_LABEL}
                    options={isUseContractPayment ? BILLING_STATUS_OPTIONS_USE_PAYMENT : BILLING_STATUS_OPTIONS}
                    value={selectedStatus || EMPTY}
                    onChange={handleBillingStatusChanged}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <SelectBoxV2
                    nullable
                    placeholder='支払方法'
                    labels={BILLING_PAYMENT_METHOD_LABEL}
                    options={BILLING_PAYMENT_OPTIONS}
                    value={selectedPaymentMethod || EMPTY}
                    onChange={handlePaymentMethodChanged}
                  />
                </FieldWrapper>
              </ConditionWrapper>
            }
            tableRightComponent={
              <>
                <SelectBoxV2
                  labels={BILLING_ACTION_LABEL}
                  options={BILLING_ACTION_OPTIONS}
                  value={selectedAction}
                  onChange={handleActionSelected}
                ></SelectBoxV2>
                {hasLength(csvContent) && <CSVDownload ref={csvInstance} headers={csvHeaders} data={csvContent} />}
              </>
            }
            onClickRow={handleClickRow}
            onClickSortCol={handleSortColClicked}
            setSelectedIds={setSelectedBillingIds}
          />

          <DefaultModal
            isOpen={isOpenConfirmDialog}
            headerLabel={'決済の確認'}
            rightButtonProps={{ label: 'はい', onClick: async () => await confirmAll(selectedBillings) }}
            onClose={() => setIsOpenConfirmDialog(false)}
          >
            <ModalContent>
              {isUseSaisonService()
                ? '決済を実行すると、セゾンインボイスの規定に沿うように請求日・お支払期日が更新されます\n' +
                  '決済を実行してもよろしいですか？'
                : '決済を実行してもよろしいですか？'}
            </ModalContent>
          </DefaultModal>
        </Content>
      </Container>
    </Component>
  );
});

BillingListScreen.displayName = 'BillingListScreen';
export default BillingListScreen;

const toBillingStatusElement = (billingStatus: BillingStatus): JSX.Element => {
  switch (billingStatus) {
    case BillingStatus.CONFIRMED:
      return (
        <StatusV2 width={84} variant='normal' status='success' label={BILLING_STATUS_LABEL[BillingStatus.CONFIRMED]} />
      );
    case BillingStatus.UNCONFIRMED:
      return (
        <StatusV2
          width={84}
          variant='normal'
          status='default'
          label={BILLING_STATUS_LABEL[BillingStatus.UNCONFIRMED]}
        />
      );
    case BillingStatus.PAYMENT_COMPLETED:
      return (
        <StatusV2
          width={84}
          variant='normal'
          status='success'
          label={BILLING_STATUS_LABEL[BillingStatus.PAYMENT_COMPLETED]}
        />
      );
    case BillingStatus.PAYMENT_ERROR:
      return (
        <StatusV2
          width={84}
          variant='normal'
          status='error'
          label={BILLING_STATUS_LABEL[BillingStatus.PAYMENT_ERROR]}
        />
      );
    default:
      throw new Error(`${billingStatus} is out of target.`);
  }
};

const Container = styled.div`
  width: 100%;
  height: calc(100% - ${PAGINATE_HEIGHT}px);
  display: flex;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const ConditionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  margin: ${themeV2.mixins.v2.spacing}px 0px ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing * 3}px;
`;

const FieldWrapper = styled.div`
  width: 160px;
  height: auto;
`;

const ModalContent = styled.div`
  white-space: pre-wrap;
`;
