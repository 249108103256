import {
  BackButtonV2,
  ButtonV2,
  Component,
  IconButtonV2,
  ImageUploadV2,
  InputDateWithLabelV2,
  InputWithLabelV2,
  LabelsForSelectBox,
  LabelV2,
  MasterSearchOption,
  MasterSearchV2,
  PageHeaderV2,
  RadioBoxV2,
  SelectBoxV2,
  SelectBoxWithLabelV2,
  TextFieldV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  BaseFunctionToggleCode,
  DOMAINS,
  EVENT_ID_V2,
  EVENT_SCHEDULE_ID_V2,
  EventHoldingMethod,
  EventIdV2,
  EventScheduleIdV2,
  EventScheduleV2,
  EventV2,
  FETCH_EVENT_SCHEDULE_V2_FOR_ADMIN,
  FETCH_EVENT_V2_FOR_ADMIN,
  FetchEventScheduleV2ForAdminRequest,
  FetchEventScheduleV2ForAdminResponse,
  FetchEventV2ForAdminRequest,
  FetchEventV2ForAdminResponse,
  HTTPS,
  isBaseFunctionToggleEnabled,
  MessageV2,
  RecurrenceTarget,
  RecurrenceTypeEnum,
  ReplacingReservedWordsForEventMessage,
  ReservedWordsForEventMessageEnum,
  SAVE_EVENT_SCHEDULE_V2_FOR_ADMIN,
  SaveEventScheduleV2ForAdminRequest,
  SaveEventScheduleV2ForAdminResponse,
  SEARCH_SPACES_FOR_ADMIN,
  SearchSpacesForAdminRequest,
  SearchSpacesForAdminResponse,
  SendingMethod,
  Space,
  SpaceCategory,
  User
} from '@atomica.co/irori';
import {
  Address,
  Count,
  DateStr,
  Description,
  Message,
  Name,
  Point,
  RRuleStr,
  Time,
  URL,
  Word
} from '@atomica.co/types';
import {
  builder,
  DateUnitEnum,
  embedIdInPath,
  EMPTY,
  EMPTY_HALF_WIDTH,
  getWeeklyRuleString,
  hasLength,
  isEmpty,
  isLessThanZero,
  isNull,
  isNumber,
  ONE,
  parseInt,
  toDatetimeFromRelativeFormat,
  toTimeStr,
  uuid
} from '@atomica.co/utils';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { addYears, format, isAfter, isBefore, isSameDay, subDays, subMonths, subWeeks } from 'date-fns';
import jaLocale from 'date-fns/locale/ja';
import { SnackbarKey, useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import InputWithPreview from '../../components/input/InputWithPreview';
import { MASTER_SEARCH_LIMIT } from '../../constants/contract-v2-const';
import { EventScheduleDeadlineEnum } from '../../enums/event-v2-enum';
import env from '../../env/env';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import usePath from '../../redux/hooks/usePath';
import { Path, PATH_IDS } from '../../router/Routes';
import { ImageService } from '../../services/image-service';
import { DATE_UNIT_LABELS, EVENT_HOLDING_METHOD_LABELS, EVENT_SCHEDULE_DEADLINE_LABELS } from '../../texts/event-text';
import { MESSAGE_DATE_UNIT_LABELS, MESSAGE_SENDING_METHOD } from '../../texts/messages-text';
import { toRecurrenceLabel, toUtcTimeStr, toZonedTimeStr } from '../../utils/date-util';
import EditEventScheduleModal from './modal/EditOrDeleteEventScheduleModal';

interface P {
  isDrawerOpen: boolean;
  base: BaseDto;
  user: User;
  onChange(path: Path): void;
}

const TIME_PATTERN = /^(([01]?[0-9])|(2[0-3])):([0-5][0-9])$/;

const isSendingMethodAuto = (sendingMethod: SendingMethod): boolean => sendingMethod === SendingMethod.AUTO;

const RegisterEventScheduleV2Screen: React.FC<P> = React.memo(props => {
  const { base, onChange, user } = props;
  const { path, params, openPath } = usePath();
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [saving, setSaving] = useSafeState<boolean>(unmountRef, false);
  const [event, setEvent] = useSafeState<EventV2>(unmountRef);
  const [eventSchedule, setEventSchedule] = useSafeState<EventScheduleV2>(unmountRef);
  const [entryMessage, setEntryMessage] = useSafeState<MessageV2>(unmountRef);
  const [remindMessage, setRemindMessage] = useSafeState<MessageV2>(unmountRef);
  const [nameToSave, setNameToSave] = useSafeState<Name>(unmountRef, EMPTY);
  const [descriptionToSave, setDescriptionToSave] = useSafeState<Description>(unmountRef, EMPTY);
  const [thumbnailToSave, setThumbnailToSave] = useSafeState<File>(unmountRef);
  const [startDateToSave, setStartDateToSave] = useSafeState<Date>(unmountRef, new Date());
  const [startTimeToSave, setStartTimeToSave] = useSafeState<Time>(unmountRef, EMPTY);
  const [endDateToSave, setEndDateToSave] = useSafeState<Date>(unmountRef, new Date());
  const [endTimeToSave, setEndTimeToSave] = useSafeState<Time>(unmountRef, EMPTY);

  const [isValidEndDate, setIsValidEndDate] = useSafeState<boolean>(unmountRef, true); // FIXME;
  const [isValidEndTime, setIsValidEndTime] = useSafeState<boolean>(unmountRef, true); // FIXME;
  const [isValidStartDate, setIsValidStartDate] = useSafeState<boolean>(unmountRef, true); // FIXME;
  const [isValidStartTime, setIsValidStartTime] = useSafeState<boolean>(unmountRef, true); // FIXME;
  const [deadlineBeforeDateToSave, setDeadlineBeforeDateToSave] = useSafeState<DateStr | null>(unmountRef, EMPTY);
  const [isValidDeadlineBeforeDateToSave, setIsValidDeadlineBeforeDateToSave] = useSafeState<boolean>(unmountRef, true); // FIXME;
  const [deadlineDateUnitToSave, setDeadlineDateUnitToSave] = useSafeState<DateUnitEnum | null>(unmountRef, null);
  const [deadlineTimeToSave, setDeadlineTimeToSave] = useSafeState<Time>(unmountRef, EMPTY);
  const [deadlineDateType, setDeadlineType] = useSafeState<EventScheduleDeadlineEnum>(
    unmountRef,
    EventScheduleDeadlineEnum.UNTIL_END_TIME
  );

  const [capacityToSave, setCapacityToSave] = useSafeState<Count | null>(unmountRef, null);
  const [recurrenceType, setRecurrenceType] = useSafeState<RecurrenceTypeEnum>(
    unmountRef,
    RecurrenceTypeEnum.NOT_RECURRING
  );
  const [pointsToSave, setPointsToSave] = useSafeState<Point>(unmountRef, 0);
  const [preQuestionnaireUrlToSave, setPreQuestionnaireUrlToSave] = useSafeState<URL>(unmountRef, EMPTY);
  const [postQuestionnaireUrlToSave, setPostQuestionnaireUrlToSave] = useSafeState<URL>(unmountRef, EMPTY);
  const [holdingMethod, setHoldingMethod] = useSafeState<EventHoldingMethod>(unmountRef, EventHoldingMethod.UNDECIDED);
  const [location, setLocation] = useSafeState<Address>(unmountRef, eventSchedule?.location ?? EMPTY);
  const [meetingURL, setMeetingURL] = useSafeState<URL>(unmountRef, EMPTY);

  const [spaces, setSpaces] = useSafeState<Space[]>(unmountRef, []);
  const [isEditEventScheduleModalOpen, setIsEditEventScheduleModalOpen] = useSafeState<boolean>(unmountRef, false);

  const [entrySendingMethodToSave, setEntrySendingMethodToSave] = useSafeState<SendingMethod>(
    unmountRef,
    SendingMethod.NOT_SEND
  );

  const [entryFormSendBeforeDateToSave, setEntryFormSendBeforeDateToSave] = useSafeState<DateStr | null>(
    unmountRef,
    EMPTY
  );
  const [entryFormSendAtUnitToSave, setEntryFormSendAtUnitToSave] = useSafeState<DateUnitEnum | null>(unmountRef, null);

  const [entryEmailMessageToSave, setEntryEmailMessageToSave] = useSafeState<Message>(unmountRef, EMPTY);
  const [entryLineMessageToSave, setEntryLineMessageToSave] = useSafeState<Message>(unmountRef, EMPTY);

  const [reminderSendingMethodToSave, setReminderSendingMethodToSave] = useSafeState<SendingMethod>(
    unmountRef,
    SendingMethod.NOT_SEND
  );

  const [remindSendBeforeDateToSave, setRemindSendBeforeDateToSave] = useSafeState<DateStr>(unmountRef, EMPTY);
  const [remindSendAtUnitToSave, setRemindSendAtUnitToSave] = useSafeState<DateUnitEnum | null>(unmountRef, null);

  const [reminderEmailMessageToSave, setReminderEmailMessageToSave] = useSafeState<Message>(unmountRef, EMPTY);
  const [reminderLineMessageToSave, setReminderLineMessageToSave] = useSafeState<Message>(unmountRef, EMPTY);

  const [notistackKey, setNotistackKey] = useSafeState<SnackbarKey>(unmountRef, EMPTY);

  const isEdit = useMemo<boolean>(() => path === Path.EDIT_EVENT_SCHEDULE_V2, [path]);
  const eventId = useMemo<EventIdV2>(() => params[EVENT_ID_V2], [params]);
  const eventScheduleId = useMemo<EventScheduleIdV2>(
    () => (isEdit ? params[EVENT_SCHEDULE_ID_V2] : uuid()),
    [isEdit, params]
  );

  const isDeadlineNull = useMemo<boolean>(
    () => deadlineDateType === EventScheduleDeadlineEnum.UNTIL_END_TIME,
    [deadlineDateType]
  );

  const calcSendingDate = useSafeCallback(
    (message: MessageV2 | undefined, unit: DateUnitEnum | null, beforeDate: DateStr | null): Date | undefined => {
      if (!unit || !beforeDate || !TIME_PATTERN.test(startTimeToSave)) return;
      if (message) return message.sendAtV2;
      const startDate = new Date(format(startDateToSave, 'yyyy/MM/dd') + EMPTY_HALF_WIDTH + startTimeToSave);
      switch (unit) {
        case DateUnitEnum.DAYS_AGO:
          return subDays(startDate, parseInt(beforeDate));
        case DateUnitEnum.WEEKS_AGO:
          return subWeeks(startDate, parseInt(beforeDate));
        case DateUnitEnum.MONTHS_AGO:
          return subMonths(startDate, parseInt(beforeDate));
        default:
          return startDateToSave;
      }
    },
    [startDateToSave, startTimeToSave]
  );

  const entrySendingDate = useMemo<Date | undefined>(
    () => calcSendingDate(entryMessage, entryFormSendAtUnitToSave, entryFormSendBeforeDateToSave),
    [calcSendingDate, entryFormSendAtUnitToSave, entryFormSendBeforeDateToSave, entryMessage]
  );

  const reminderSendingDate = useMemo<Date | undefined>(
    () => calcSendingDate(remindMessage, remindSendAtUnitToSave, remindSendBeforeDateToSave),
    [calcSendingDate, remindSendAtUnitToSave, remindSendBeforeDateToSave, remindMessage]
  );

  const disabledSaveButton = useMemo<boolean>(() => {
    return (
      !nameToSave ||
      !startDateToSave ||
      !startTimeToSave ||
      !endDateToSave ||
      !endTimeToSave ||
      (holdingMethod === EventHoldingMethod.OFFLINE && !location) ||
      (holdingMethod === EventHoldingMethod.ONLINE && !meetingURL) ||
      isEmpty(pointsToSave) ||
      !isNumber(Number(pointsToSave)) ||
      isLessThanZero(pointsToSave) ||
      (!isDeadlineNull && (isEmpty(deadlineBeforeDateToSave) || !isNumber(Number(deadlineBeforeDateToSave)))) ||
      (!isDeadlineNull && !deadlineDateUnitToSave) ||
      (!isDeadlineNull && !deadlineTimeToSave) ||
      (isSendingMethodAuto(entrySendingMethodToSave) && isEmpty(entryFormSendBeforeDateToSave?.trim())) ||
      (isSendingMethodAuto(entrySendingMethodToSave) && !entryFormSendAtUnitToSave) ||
      (isSendingMethodAuto(reminderSendingMethodToSave) && isEmpty(remindSendBeforeDateToSave?.trim())) ||
      (isSendingMethodAuto(reminderSendingMethodToSave) && !remindSendAtUnitToSave)
    );
  }, [
    deadlineBeforeDateToSave,
    deadlineDateUnitToSave,
    deadlineTimeToSave,
    endDateToSave,
    endTimeToSave,
    holdingMethod,
    location,
    meetingURL,
    entryFormSendBeforeDateToSave,
    entryFormSendAtUnitToSave,
    entrySendingMethodToSave,
    isDeadlineNull,
    nameToSave,
    pointsToSave,
    remindSendAtUnitToSave,
    remindSendBeforeDateToSave,
    reminderSendingMethodToSave,
    startDateToSave,
    startTimeToSave
  ]);

  const locationForReplacing = useMemo<Address>(() => {
    switch (holdingMethod) {
      case EventHoldingMethod.UNDECIDED:
        return EVENT_HOLDING_METHOD_LABELS[holdingMethod];
      case EventHoldingMethod.OFFLINE:
        return location;
      case EventHoldingMethod.ONLINE:
        return meetingURL;
      default:
        return EMPTY;
    }
  }, [holdingMethod, location, meetingURL]);

  const configForReplacingEventMessage = useMemo<ReplacingReservedWordsForEventMessage>(
    () => ({
      [ReservedWordsForEventMessageEnum.userName]: `${user.familyName} ${user.firstName}`,
      [ReservedWordsForEventMessageEnum.eventDate]: `${format(startDateToSave, 'yyyy/MM/dd')}`,
      [ReservedWordsForEventMessageEnum.eventStartAt]: startTimeToSave,
      [ReservedWordsForEventMessageEnum.eventEndAt]: endTimeToSave,
      [ReservedWordsForEventMessageEnum.eventLocation]: locationForReplacing,
      [ReservedWordsForEventMessageEnum.eventEntryForm]: `${HTTPS}://${DOMAINS[env]}${embedIdInPath(
        Path.ACCOUNT_EVENT_ENTRY_FORM,
        PATH_IDS,
        [base.baseCode, eventScheduleId]
      )}`
    }),
    [base, endTimeToSave, eventScheduleId, locationForReplacing, startDateToSave, startTimeToSave, user]
  );

  const recurrenceLabels = useMemo<LabelsForSelectBox>(() => {
    const week = toRecurrenceLabel(startDateToSave, RecurrenceTypeEnum.EVERY_WEEK, jaLocale);
    return {
      [RecurrenceTypeEnum.NOT_RECURRING]: '繰り返さない',
      [RecurrenceTypeEnum.EVERY_WEEK]: week
    };
  }, [startDateToSave]);

  const rrule = useMemo<RRuleStr>(() => {
    const nextYearDate = addYears(startDateToSave, ONE);
    switch (recurrenceType) {
      case RecurrenceTypeEnum.EVERY_WEEK:
        return getWeeklyRuleString(startDateToSave, nextYearDate);
      default:
        return EMPTY;
    }
  }, [recurrenceType, startDateToSave]);

  const recurrenceTarget = useMemo<RecurrenceTarget>(
    () =>
      recurrenceType === RecurrenceTypeEnum.NOT_RECURRING ? RecurrenceTarget.THIS_ONE : RecurrenceTarget.AFTER_THIS,
    [recurrenceType]
  );

  const fetchEvent = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchEventV2ForAdminRequest>().baseId(base.baseId).eventIdV2(eventId).build();
    const { eventV2 } = await commonRequest<FetchEventV2ForAdminRequest, FetchEventV2ForAdminResponse>(
      FETCH_EVENT_V2_FOR_ADMIN,
      request
    );

    if (!eventV2) return;

    setEvent(eventV2);
  }, [base, commonRequest, eventId, setEvent]);

  const fetchEventSchedule = useSafeCallback(async (): Promise<EventScheduleV2 | undefined> => {
    const request = builder<FetchEventScheduleV2ForAdminRequest>()
      .baseId(base.baseId)
      .eventScheduleIdV2(eventScheduleId)
      .build();
    const { eventScheduleV2, messageEntry, messageRemind } = await commonRequest<
      FetchEventScheduleV2ForAdminRequest,
      FetchEventScheduleV2ForAdminResponse
    >(FETCH_EVENT_SCHEDULE_V2_FOR_ADMIN, request);

    setEventSchedule(eventScheduleV2);
    setEntryMessage(messageEntry);
    setRemindMessage(messageRemind);
    setLoaded(true);

    return eventScheduleV2;
  }, [base.baseId, commonRequest, eventScheduleId, setEventSchedule, setEntryMessage, setRemindMessage, setLoaded]);

  const onChangeFile = useSafeCallback(
    async (file: File): Promise<boolean> => {
      setThumbnailToSave(file);
      return true;
    },
    [setThumbnailToSave]
  );

  const getEventScheduleThumbnail = useSafeCallback(
    async (thumbnailURL: URL): Promise<void> => {
      const downloadFile = await ImageService.urlToFile(thumbnailURL);
      downloadFile && (await onChangeFile(downloadFile));
    },
    [onChangeFile]
  );

  const initialize = useSafeCallback(async (): Promise<void> => {
    const [, eventSchedule] = await Promise.all([fetchEvent(), fetchEventSchedule()]);
    if (!eventSchedule || !eventSchedule.thumbnailURL) return;
    await getEventScheduleThumbnail(eventSchedule.thumbnailURL);
  }, [fetchEvent, fetchEventSchedule, getEventScheduleThumbnail]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const setEventScheduleInfo = useSafeCallback(
    (eventSchedule: EventScheduleV2): void => {
      setNameToSave(eventSchedule.name);
      setDescriptionToSave(eventSchedule.description);
      setStartDateToSave(eventSchedule.startAtV2);
      setStartTimeToSave(toTimeStr(eventSchedule.startAtV2));
      setEndDateToSave(eventSchedule.endAtV2);
      setEndTimeToSave(toTimeStr(eventSchedule.endAtV2));
      setDeadlineBeforeDateToSave(eventSchedule.deadlineBeforeDate || EMPTY);
      setDeadlineDateUnitToSave(eventSchedule.deadlineDateUnit ?? null);
      setDeadlineTimeToSave(toZonedTimeStr(eventSchedule.deadlineTime || EMPTY, base.timezone, 'HH:mm'));
      setDeadlineType(
        eventSchedule.deadlineBeforeDate && eventSchedule.deadlineTime
          ? EventScheduleDeadlineEnum.ASSIGN_DATE
          : EventScheduleDeadlineEnum.UNTIL_END_TIME
      );
      setCapacityToSave(eventSchedule.capacity || null);
      setPointsToSave(eventSchedule.points);
      setPreQuestionnaireUrlToSave(eventSchedule.preQuestionnaireURL ?? EMPTY);
      setPostQuestionnaireUrlToSave(eventSchedule.postQuestionnaireURL ?? EMPTY);
      setRecurrenceType(eventSchedule.recurrenceType || RecurrenceTypeEnum.NOT_RECURRING);
      setHoldingMethod(eventSchedule.holdingMethod);
      setLocation(eventSchedule.location ?? EMPTY);
      setMeetingURL(eventSchedule.meetingURL ?? EMPTY);
    },
    [
      base,
      setCapacityToSave,
      setDeadlineBeforeDateToSave,
      setDeadlineDateUnitToSave,
      setDeadlineTimeToSave,
      setDeadlineType,
      setDescriptionToSave,
      setEndDateToSave,
      setEndTimeToSave,
      setHoldingMethod,
      setLocation,
      setMeetingURL,
      setNameToSave,
      setPointsToSave,
      setPostQuestionnaireUrlToSave,
      setPreQuestionnaireUrlToSave,
      setRecurrenceType,
      setStartDateToSave,
      setStartTimeToSave
    ]
  );

  const setEntryMessageInfo = useSafeCallback(
    (entryMessage: MessageV2): void => {
      setEntrySendingMethodToSave(entryMessage.sendMethod);
      setEntryEmailMessageToSave(entryMessage.emailMessage ?? EMPTY);
      setEntryLineMessageToSave(entryMessage.lineMessage ?? EMPTY);
    },
    [setEntrySendingMethodToSave, setEntryEmailMessageToSave, setEntryLineMessageToSave]
  );

  const setRemindMessageInfo = useSafeCallback(
    (remindMessage: MessageV2): void => {
      setReminderSendingMethodToSave(remindMessage.sendMethod);
      setReminderEmailMessageToSave(remindMessage.emailMessage ?? EMPTY);
      setReminderLineMessageToSave(remindMessage.lineMessage ?? EMPTY);
    },
    [setReminderSendingMethodToSave, setReminderEmailMessageToSave, setReminderLineMessageToSave]
  );

  useEffect(() => {
    if (entryMessage && !isEdit) setEntryMessageInfo(entryMessage);
    if (remindMessage && !isEdit) setRemindMessageInfo(remindMessage);
    if (eventSchedule) setEventScheduleInfo(eventSchedule);
  }, [
    eventSchedule,
    entryMessage,
    isEdit,
    remindMessage,
    setEventScheduleInfo,
    setEntryMessageInfo,
    setRemindMessageInfo
  ]);

  const handleBackButtonClicked = useSafeCallback((): void => {
    openPath(embedIdInPath(Path.EVENT_V2_DETAILS, PATH_IDS, [base.baseCode, params[EVENT_ID_V2]]));
  }, [base, params, openPath]);

  const openEventSchedulesV2DetailsScreen = useSafeCallback(
    (eventScheduleId: EventScheduleIdV2): void => {
      onChange(
        embedIdInPath(Path.EVENT_SCHEDULE_V2_DETAILS, PATH_IDS, [base.baseCode, params[EVENT_ID_V2], eventScheduleId])
      );
    },
    [onChange, base, params]
  );

  const saveEventSchedule = useSafeCallback(
    async (recurrenceTarget: RecurrenceTarget): Promise<void> => {
      setSaving(true);
      if (!endDateToSave) return;

      const startAtToSave = new Date(format(startDateToSave, 'yyyy-MM-dd') + EMPTY_HALF_WIDTH + startTimeToSave);
      const endAtToSave = new Date(format(endDateToSave, 'yyyy-MM-dd') + EMPTY_HALF_WIDTH + endTimeToSave);
      const scheduleToSave = builder<EventScheduleV2>()
        .eventScheduleId(eventScheduleId)
        .name(nameToSave)
        .description(descriptionToSave)
        .thumbnailURL(EMPTY)
        .startAtV2(startAtToSave)
        .endAtV2(endAtToSave)
        .deadlineBeforeDate(isDeadlineNull ? null : deadlineBeforeDateToSave)
        .deadlineDateUnit(isDeadlineNull ? null : deadlineDateUnitToSave)
        .deadlineTime(isDeadlineNull ? null : toUtcTimeStr(deadlineTimeToSave))
        .deadlineAtV2(
          toDatetimeFromRelativeFormat(
            startAtToSave,
            deadlineBeforeDateToSave,
            deadlineDateUnitToSave,
            deadlineTimeToSave
          ) || endAtToSave
        )
        .capacity(capacityToSave)
        .points(pointsToSave)
        .preQuestionnaireURL(preQuestionnaireUrlToSave)
        .postQuestionnaireURL(postQuestionnaireUrlToSave)
        .recurrenceType(recurrenceType)
        .rrule(rrule)
        .recurrenceId(isEdit ? (eventSchedule?.recurrenceId ?? null) : uuid())
        .holdingMethod(holdingMethod)
        .location(location)
        .meetingURL(meetingURL)
        .entryFormSendBeforeDate(entryFormSendBeforeDateToSave)
        .entryFormSendAtUnit(entryFormSendAtUnitToSave)
        .entryFormSendTime(startTimeToSave)
        .entryFormSendMethod(entrySendingMethodToSave)
        .entryFormEmailMessage(entryEmailMessageToSave)
        .entryFormLineMessage(entryLineMessageToSave)
        .remindFormSendBeforeDate(remindSendBeforeDateToSave)
        .remindSendAtUnit(remindSendAtUnitToSave)
        .remindSendTime(startTimeToSave)
        .remindSendMethod(reminderSendingMethodToSave)
        .remindEmailMessage(reminderEmailMessageToSave)
        .remindLineMessage(reminderLineMessageToSave)
        .eventV2(event)
        .build();

      const request = builder<SaveEventScheduleV2ForAdminRequest>()
        .baseId(base.baseId)
        .eventScheduleV2(scheduleToSave)
        .recurrenceTarget(recurrenceTarget);

      if (thumbnailToSave) {
        const arrayBuffer = await thumbnailToSave.arrayBuffer();
        const thumbnailBase64 = Buffer.from(arrayBuffer).toString('base64');
        request.thumbnailBase64(thumbnailBase64);
        request.thumbnailContentType(thumbnailToSave.type);
      }

      const response = await commonRequest<SaveEventScheduleV2ForAdminRequest, SaveEventScheduleV2ForAdminResponse>(
        SAVE_EVENT_SCHEDULE_V2_FOR_ADMIN,
        request.build()
      );

      if (!response.eventScheduleIdV2) return;

      openEventSchedulesV2DetailsScreen(response.eventScheduleIdV2);
    },
    [
      base,
      capacityToSave,
      commonRequest,
      deadlineBeforeDateToSave,
      deadlineDateUnitToSave,
      deadlineTimeToSave,
      descriptionToSave,
      endDateToSave,
      endTimeToSave,
      entryEmailMessageToSave,
      entryFormSendAtUnitToSave,
      entryFormSendBeforeDateToSave,
      entryLineMessageToSave,
      entrySendingMethodToSave,
      event,
      eventId,
      eventScheduleId,
      holdingMethod,
      isDeadlineNull,
      isEdit,
      location,
      meetingURL,
      nameToSave,
      openEventSchedulesV2DetailsScreen,
      pointsToSave,
      postQuestionnaireUrlToSave,
      preQuestionnaireUrlToSave,
      recurrenceType,
      remindSendAtUnitToSave,
      remindSendBeforeDateToSave,
      reminderEmailMessageToSave,
      reminderLineMessageToSave,
      reminderSendingMethodToSave,
      rrule,
      setSaving,
      startDateToSave,
      startTimeToSave,
      thumbnailToSave
    ]
  );

  const optionsForMasterSearch = useMemo<MasterSearchOption[]>(() => {
    const spaceOptions = spaces.map(space => ({
      value: space.spaceId,
      label: space.spaceName
    }));
    return spaceOptions;
  }, [spaces]);

  const validateDate = useSafeCallback((): boolean => {
    const errMsgs: Message[] = [];
    if (!TIME_PATTERN.test(startTimeToSave.trim())) {
      setIsValidStartTime(false);
      errMsgs.push('・開始時間が不正です');
    }
    if (!TIME_PATTERN.test(endTimeToSave.trim())) {
      setIsValidEndTime(false);
      errMsgs.push('・終了時間が不正です');
    }
    if (isAfter(new Date(), new Date(`${format(startDateToSave, 'yyyy/MM/dd')} ${startTimeToSave}`))) {
      setIsValidStartDate(isSameDay(new Date(), startDateToSave));
      setIsValidStartTime(false);
      errMsgs.push('・過去の開始日時が指定されています');
    }
    if (isAfter(new Date(), new Date(`${format(endDateToSave, 'yyyy/MM/dd')} ${endTimeToSave}`))) {
      setIsValidEndDate(isSameDay(new Date(), endDateToSave));
      setIsValidEndTime(false);
      errMsgs.push('・過去の終了日時が指定されています');
    }

    if (
      isAfter(
        new Date(`${format(startDateToSave, 'yyyy/MM/dd')} ${startTimeToSave}`),
        new Date(`${format(endDateToSave, 'yyyy/MM/dd')} ${endTimeToSave}`)
      )
    ) {
      setIsValidEndTime(false);
      setIsValidStartTime(false);
      errMsgs.push('・開始日時より古い終了日時が指定されています');
    }
    if (!isDeadlineNull && deadlineBeforeDateToSave) {
      const startDate = new Date(`${format(startDateToSave, 'yyyy/MM/dd')} ${startTimeToSave}`);
      switch (deadlineDateUnitToSave) {
        case DateUnitEnum.DAYS_AGO:
          startDate.setDate(startDate.getDate() - Number(deadlineBeforeDateToSave));
          break;
        case DateUnitEnum.WEEKS_AGO:
          startDate.setDate(startDate.getDate() - Number(deadlineBeforeDateToSave) * 7);
          break;
        case DateUnitEnum.MONTHS_AGO:
          startDate.setMonth(startDate.getMonth() - Number(deadlineBeforeDateToSave));
          break;
        default:
          break;
      }
      if (isAfter(new Date(), new Date(`${format(startDate, 'yyyy/MM/dd')} ${deadlineTimeToSave}`))) {
        setIsValidDeadlineBeforeDateToSave(false);
        errMsgs.push('・申し込み回答締め切りが過去の日時に指定されています');
      }
    }

    if (hasLength(errMsgs)) {
      const key = enqueueSnackbar(EMPTY, {
        autoHideDuration: 15000,
        content: (
          <StyledSnackbar>
            <SnackbarLabel>{errMsgs.join('\n')}</SnackbarLabel>
            <IconButtonV2 icon={<StyledCloseRoundedIcon />} onClick={() => closeSnackbar(key)} />
          </StyledSnackbar>
        )
      });
      setNotistackKey(key);
      return false;
    }
    closeSnackbar(notistackKey);
    return true;
  }, [
    endDateToSave,
    endTimeToSave,
    notistackKey,
    startDateToSave,
    startTimeToSave,
    isDeadlineNull,
    setIsValidEndDate,
    setIsValidEndTime,
    setIsValidStartDate,
    setIsValidStartTime,
    setIsValidDeadlineBeforeDateToSave,
    setNotistackKey
  ]);

  const handleSaveButtonClicked = useSafeCallback((): void => {
    if (!validateDate()) return;
    if (
      isEdit &&
      eventSchedule?.recurrenceType !== RecurrenceTypeEnum.NOT_RECURRING &&
      recurrenceType !== RecurrenceTypeEnum.NOT_RECURRING
    )
      setIsEditEventScheduleModalOpen(true);
    else saveEventSchedule(recurrenceTarget);
  }, [eventSchedule, isEdit, recurrenceTarget, setIsEditEventScheduleModalOpen, saveEventSchedule]);

  const handleSearchSpaces = useSafeCallback(
    async (word: Word): Promise<void> => {
      const request = builder<SearchSpacesForAdminRequest>()
        .baseId(base.baseId)
        .limit(MASTER_SEARCH_LIMIT)
        .offset(0)
        .word(word)
        .categories([SpaceCategory.CONFERENCE])
        .build();
      const response = await commonRequest<SearchSpacesForAdminRequest, SearchSpacesForAdminResponse>(
        SEARCH_SPACES_FOR_ADMIN,
        request
      );
      setSpaces(response.spaces);
      setLocation(word);
    },
    [base, commonRequest, setSpaces, setLocation]
  );

  const handleOptionSelected = useSafeCallback(
    (option: MasterSearchOption): void => {
      const selectedSpace = spaces.find(space => space.spaceId === option.value);
      setLocation(selectedSpace?.spaceName || EMPTY);
    },
    [spaces, setLocation]
  );

  const handleOptionCleared = (): void => {
    setLocation(EMPTY);
  };

  useEffect(() => {
    setIsValidStartDate(true);
    setEndDateToSave(endDate => (isBefore(endDate, startDateToSave) ? startDateToSave : endDate));
  }, [setEndDateToSave, setIsValidStartDate, startDateToSave]);
  useEffect(() => setIsValidStartTime(true), [startTimeToSave, setIsValidStartTime]);

  useEffect(() => {
    setIsValidStartDate(true);
    setStartDateToSave(startDate => (isBefore(endDateToSave, startDate) ? endDateToSave : startDate));
  }, [endDateToSave, setIsValidStartDate, setStartDateToSave]);

  useEffect(() => setIsValidEndTime(true), [endTimeToSave, setIsValidEndTime]);
  useEffect(() => {
    if (isValidEndDate && isValidEndTime && isValidStartDate && isValidStartTime) closeSnackbar(notistackKey);
  }, [isValidEndDate, isValidEndTime, isValidStartDate, isValidStartTime, notistackKey, closeSnackbar]);

  return (
    <Component
      loading={!loaded || saving}
      style={{ width: '100%', height: '100%' }}
      className='register-event-v2-screen'
    >
      <Container>
        <Content>
          <HeaderWrapper>
            <BackButtonV2 label='戻る' onClick={handleBackButtonClicked} />
            <PageHeaderV2 title={`スケジュールの${isEdit ? '編集' : '新規作成'}`} />
          </HeaderWrapper>

          <BasicContent>
            <SubTitle>基本情報</SubTitle>
            <InputWithLabelV2 required text='スケジュール名' value={nameToSave} onChange={setNameToSave} />

            <FullInputFlex>
              <DateWrapper>
                <InputDateWithLabelV2
                  required
                  text='開始日時'
                  value={startDateToSave}
                  onChange={setStartDateToSave}
                  error={!isValidStartDate}
                />
                <InputWithLabelV2
                  placeholder='時刻'
                  value={startTimeToSave}
                  onChange={setStartTimeToSave}
                  errorMessage={!isValidStartTime ? '開始日時が不正です' : EMPTY}
                  inputType='time'
                  text={EMPTY}
                />
              </DateWrapper>
              <DateWrapper>
                <InputDateWithLabelV2
                  required
                  text='終了日時'
                  value={endDateToSave}
                  onChange={setEndDateToSave}
                  error={!isValidEndDate}
                />
                <InputWithLabelV2
                  placeholder='時刻'
                  value={endTimeToSave}
                  onChange={setEndTimeToSave}
                  errorMessage={!isValidEndTime ? '終了日時が不正です' : EMPTY}
                  inputType='time'
                  text={EMPTY}
                />
              </DateWrapper>
            </FullInputFlex>
            <HalfInput>
              <SelectBoxWithLabelV2
                textForLabel='繰り返し'
                options={[RecurrenceTypeEnum.NOT_RECURRING, RecurrenceTypeEnum.EVERY_WEEK]} //FIXME
                labelsForSelectBox={recurrenceLabels}
                onChange={setRecurrenceType}
                value={recurrenceType || RecurrenceTypeEnum.NOT_RECURRING}
              />
            </HalfInput>
            <div>
              <LabelV2 required text='申し込み回答締め切り' />
              <RadioBoxWrapper>
                <RadioBoxV2
                  options={Object.values(EventScheduleDeadlineEnum)}
                  labels={EVENT_SCHEDULE_DEADLINE_LABELS}
                  onChange={setDeadlineType}
                  value={deadlineDateType}
                  direction='column'
                  gap={8}
                />
              </RadioBoxWrapper>
              {deadlineDateType === EventScheduleDeadlineEnum.ASSIGN_DATE && (
                <DateWrapperForDeadline>
                  <TextFieldV2
                    align='right'
                    value={deadlineBeforeDateToSave || EMPTY}
                    onChange={setDeadlineBeforeDateToSave}
                    inputType='number'
                    errorMessage={!isValidDeadlineBeforeDateToSave ? '過去の日時が入力されています' : EMPTY}
                  />
                  <SelectBoxV2
                    nullable
                    labels={DATE_UNIT_LABELS}
                    options={Object.values(DateUnitEnum)}
                    value={deadlineDateUnitToSave}
                    onChange={setDeadlineDateUnitToSave}
                  />
                  <TextFieldV2
                    placeholder='時刻'
                    value={deadlineTimeToSave || EMPTY}
                    onChange={setDeadlineTimeToSave}
                    inputType='time'
                  />
                </DateWrapperForDeadline>
              )}
            </div>
            <InputWithLabelV2
              multiline
              text='スケジュールの詳細'
              value={descriptionToSave}
              onChange={setDescriptionToSave}
            />
            <InputWithLabelV2
              align='right'
              unit='名'
              text='定員'
              inputWidth={80}
              value={isNull(capacityToSave) ? EMPTY : capacityToSave!}
              onChange={value => setCapacityToSave(isEmpty(value) ? null : value)}
            />
            {isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USER_POINT) && (
              <InputWithLabelV2
                required
                align='right'
                unit='ポイント'
                text='消費ポイント'
                inputWidth={80}
                value={pointsToSave}
                onChange={setPointsToSave}
              />
            )}

            <ImageUploadV2
              header='サムネイル写真'
              remarks='推奨比率は 16:9 です。スマホ表示時は 幅 400px × 縦 224px が目安となります。'
              height={224}
              file={thumbnailToSave}
              onChange={onChangeFile}
            />
          </BasicContent>

          <VenueContent>
            <SubTitle>場所</SubTitle>
            <FullInputDiv>
              <LabelV2 text='開催方法' />
              <RadioBoxWrapper>
                <RadioBoxV2
                  options={Object.values(EventHoldingMethod)}
                  labels={EVENT_HOLDING_METHOD_LABELS}
                  onChange={setHoldingMethod}
                  value={holdingMethod}
                  gap={40}
                />
              </RadioBoxWrapper>
            </FullInputDiv>
            {holdingMethod === EventHoldingMethod.OFFLINE && (
              <>
                <LabelV2 required text='場所' />
                <MasterSearchV2
                  allowTextInput
                  labelForNoData='入力した値をそのまま適用'
                  selectedWord={location}
                  limit={MASTER_SEARCH_LIMIT}
                  options={optionsForMasterSearch}
                  onChange={handleSearchSpaces}
                  handleOptionSelected={handleOptionSelected}
                  handleOptionCleared={handleOptionCleared}
                />
              </>
            )}
            {holdingMethod === EventHoldingMethod.ONLINE && (
              <InputWithLabelV2 required text='URL' value={meetingURL} onChange={setMeetingURL} />
            )}
          </VenueContent>

          <QuestionnaireContent>
            <SubTitle>アンケート</SubTitle>
            <Text>アンケートフォームのURLを登録してください。</Text>
            <InputWithLabelV2
              text='事前アンケートフォーム'
              value={preQuestionnaireUrlToSave}
              onChange={setPreQuestionnaireUrlToSave}
            />
            <InputWithLabelV2
              text='事後アンケートフォーム'
              remarks='事後アンケートは、参加者グループの作成後、参加者のグループごとに設定することもできます。'
              value={postQuestionnaireUrlToSave}
              onChange={setPostQuestionnaireUrlToSave}
            />
          </QuestionnaireContent>

          {!isEdit && (
            <>
              <EntryContent>
                <SubTitle>申込みフォーム</SubTitle>
                <FullInputDiv>
                  <LabelV2 text='送信方法' />
                  <RadioBoxWrapper>
                    <RadioBoxV2
                      options={Object.values(SendingMethod)}
                      labels={MESSAGE_SENDING_METHOD}
                      onChange={setEntrySendingMethodToSave}
                      value={entrySendingMethodToSave}
                      gap={40}
                    />
                  </RadioBoxWrapper>
                </FullInputDiv>
                <FullInputFlex>
                  {entrySendingMethodToSave === SendingMethod.AUTO && (
                    <MessageWrapper>
                      <LabelV2 required text='送信日時' />
                      <SendDateWrapper>
                        <SendDateWrapper>
                          <TextFieldV2
                            inputWidth={60}
                            value={entryFormSendBeforeDateToSave || EMPTY}
                            onChange={setEntryFormSendBeforeDateToSave}
                          />
                          <SelectBoxWrapper>
                            <SelectBoxV2
                              options={Object.values(DateUnitEnum)}
                              labels={MESSAGE_DATE_UNIT_LABELS}
                              value={entryFormSendAtUnitToSave}
                              onChange={setEntryFormSendAtUnitToSave}
                            />
                          </SelectBoxWrapper>
                          <UnitWrapper>前</UnitWrapper>
                        </SendDateWrapper>
                        {entrySendingDate && (
                          <DescriptionText>
                            （次回:
                            {format(entrySendingDate, 'yyyy/MM/dd HH:mm')}
                            に送信予定 ）
                          </DescriptionText>
                        )}
                      </SendDateWrapper>
                    </MessageWrapper>
                  )}
                </FullInputFlex>

                {entrySendingMethodToSave !== SendingMethod.NOT_SEND && (
                  <>
                    <InputWithPreview
                      base={base}
                      config={configForReplacingEventMessage}
                      label='EMail メッセージ'
                      value={entryEmailMessageToSave}
                      onChange={setEntryEmailMessageToSave}
                    />
                    <InputWithPreview
                      base={base}
                      config={configForReplacingEventMessage}
                      label='LINE メッセージ'
                      value={entryLineMessageToSave}
                      onChange={setEntryLineMessageToSave}
                    />
                  </>
                )}
              </EntryContent>

              <ReminderContent>
                <SubTitle>リマインド</SubTitle>
                <Text>イベント開催日前に参加者に対して送信するリマインドメッセージを入力します。</Text>
                <FullInputDiv>
                  <LabelV2 text='送信方法' />
                  <RadioBoxWrapper>
                    <RadioBoxV2
                      options={Object.values(SendingMethod)}
                      labels={MESSAGE_SENDING_METHOD}
                      onChange={setReminderSendingMethodToSave}
                      value={reminderSendingMethodToSave}
                      gap={40}
                    />
                  </RadioBoxWrapper>
                </FullInputDiv>

                {reminderSendingMethodToSave === SendingMethod.AUTO && (
                  <FullInputFlex>
                    <MessageWrapper>
                      <LabelV2 required text='送信日時' />
                      <SendDateWrapper>
                        <SendDateWrapper>
                          <TextFieldV2
                            inputWidth={60}
                            value={remindSendBeforeDateToSave}
                            onChange={setRemindSendBeforeDateToSave}
                          />
                          <SelectBoxWrapper>
                            <SelectBoxV2
                              options={Object.values(DateUnitEnum)}
                              labels={MESSAGE_DATE_UNIT_LABELS}
                              value={remindSendAtUnitToSave}
                              onChange={setRemindSendAtUnitToSave}
                            />
                          </SelectBoxWrapper>
                          <UnitWrapper>前</UnitWrapper>
                        </SendDateWrapper>
                        {reminderSendingDate && (
                          <DescriptionText>
                            （次回:
                            {format(reminderSendingDate, 'yyyy/MM/dd HH:mm')}
                            に送信予定 ）
                          </DescriptionText>
                        )}
                      </SendDateWrapper>
                    </MessageWrapper>
                  </FullInputFlex>
                )}

                {reminderSendingMethodToSave !== SendingMethod.NOT_SEND && (
                  <>
                    <InputWithPreview
                      base={base}
                      config={configForReplacingEventMessage}
                      label='EMail メッセージ'
                      value={reminderEmailMessageToSave}
                      onChange={setReminderEmailMessageToSave}
                    />
                    <InputWithPreview
                      base={base}
                      config={configForReplacingEventMessage}
                      label='LINE メッセージ'
                      value={reminderLineMessageToSave}
                      onChange={setReminderLineMessageToSave}
                    />
                  </>
                )}
              </ReminderContent>
            </>
          )}
          <ButtonWrapper>
            <ButtonV2 type='secondary' label='キャンセル' onClick={handleBackButtonClicked} />
            <ButtonV2
              disabled={disabledSaveButton}
              type='primary'
              label={isEdit ? '保存' : '作成'}
              onClick={handleSaveButtonClicked}
            />
          </ButtonWrapper>
        </Content>
        <EditEventScheduleModal
          isOpen={isEditEventScheduleModalOpen}
          editOrDelete='edit'
          onClick={saveEventSchedule}
          onClose={() => setIsEditEventScheduleModalOpen(false)}
        />
      </Container>
    </Component>
  );
});

RegisterEventScheduleV2Screen.displayName = 'RegisterEventScheduleV2Screen';
export default RegisterEventScheduleV2Screen;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding-bottom: ${themeV2.mixins.v2.spacing * 10}px;
`;

const Content = styled.div`
  width: 100%;
  max-width: 768px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  margin: 0 auto ${themeV2.mixins.v2.spacing * 2}px;
  position: relative;
`;

const SubTitle = styled.div`
  ${themeV2.mixins.v2.typography.title.xLarge};
`;

const BasicContent = styled.div`
  background: ${themeV2.mixins.v2.color.background.offWhite};
  border-radius: ${themeV2.mixins.v2.spacing}px;
  padding: ${themeV2.mixins.v2.spacing * 3}px;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const VenueContent = BasicContent;
const QuestionnaireContent = BasicContent;
const EntryContent = BasicContent;
const ReminderContent = BasicContent;

const Text = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
  ${themeV2.mixins.v2.color.font.black};
`;

const DescriptionText = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV2.mixins.v2.color.font.gray};
`;

const HalfInput = styled.div`
  width: 50%;
`;

const FullInputDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const FullInputFlex = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: start;
`;

const RadioBoxWrapper = styled.div`
  margin-top: ${themeV2.mixins.v2.spacing}px;
`;

const ButtonWrapper = styled.div`
  background: ${themeV2.mixins.v2.color.background.white};
  box-shadow:
    0px -4px 8px 3px rgba(0, 0, 0, 0.15),
    0px -1px 3px rgba(0, 0, 0, 0.3);
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing / 2}px;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  z-index: 10;
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const DateWrapper = styled.div`
  width: 48%;
  display: flex;
  gap: ${themeV2.mixins.v2.spacing}px;
  align-items: flex-start;
`;

const SendDateWrapper = styled.div`
  width: max-content;
  display: flex;
  gap: ${themeV2.mixins.v2.spacing}px;
  align-items: center;
`;

const SelectBoxWrapper = styled.div`
  > div {
    width: 120px;
  }
`;

const UnitWrapper = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
`;

const DateWrapperForDeadline = styled(DateWrapper)`
  margin-top: ${themeV2.mixins.v2.spacing / 2}px;
`;

const StyledSnackbar = styled.div`
  ${themeV2.mixins.v2.typography.label.large};
  width: max-content;
  min-width: 360px;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  justify-content: space-between;
  align-items: center;
  color: ${themeV2.mixins.v2.color.font.red};
  background: ${themeV2.mixins.v2.color.background.pinkPale};
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  border-radius: 10px;
`;

const SnackbarLabel = styled.div`
  display: flex;
  align-items: start;
  gap: ${themeV2.mixins.v2.spacing}px;
  white-space: pre-wrap;
`;

const StyledCloseRoundedIcon = styled(CloseRoundedIcon)`
  color: ${themeV2.mixins.v2.color.font.red};
`;
