import {
  Breadcrumb,
  BreadcrumbTrailV2,
  ButtonV2,
  Component,
  MasterSearchOption,
  ScreenLoaderV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  BaseFunctionToggleCode,
  BaseId,
  CONTRACT_ID_V2,
  CREATE_CUSTOMER_FOR_ADMIN,
  CREATE_INITIAL_BILLING_V2_FOR_ADMIN,
  CREATE_SAISON_INVOICE_BUYER_FOR_ADMIN,
  ContractDetailV2,
  ContractIdV2,
  ContractLogV2,
  ContractPlanV2,
  ContractStatusV2,
  ContractV2,
  ContractorType,
  CreateCustomerForAdminRequest,
  CreateCustomerForAdminResponse,
  CreateInitialBillingV2ForAdminRequest,
  CreateInitialBillingV2ForAdminResponse,
  CreateSaisonInvoiceBuyerForAdminRequest,
  CreateSaisonInvoiceBuyerForAdminResponse,
  Customer,
  CustomerId,
  CustomerType,
  DELETE_CONTRACT_DETAILS_V2_FOR_ADMIN,
  DeleteContractDetailsV2ForAdminRequest,
  DeleteContractDetailsV2ForAdminResponse,
  FETCH_CONTRACT_V2_FOR_ADMIN,
  FETCH_TAXES_V2_FOR_ADMIN,
  FETCH_UNITS_V2_FOR_ADMIN,
  FetchContractV2ForAdminRequest,
  FetchContractV2ForAdminResponse,
  FetchTaxesV2ForAdminRequest,
  FetchTaxesV2ForAdminResponse,
  FetchUnitsV2ForAdminRequest,
  FetchUnitsV2ForAdminResponse,
  InflowSource,
  InvoiceDeliveryMethod,
  PaymentMethod,
  PaymentOption,
  RecurrencePattern,
  SAVE_CONTRACT_DETAILS_V2_FOR_ADMIN,
  SAVE_CONTRACT_LOG_V2_FOR_ADMIN,
  SAVE_CONTRACT_V2_FOR_ADMIN,
  SEARCH_CONTRACT_PLANS_V2_FOR_ADMIN,
  SEND_CREDIT_CARD_REGISTER_FORM_FOR_ADMIN,
  SaveContractDetailsV2ForAdminRequest,
  SaveContractDetailsV2ForAdminResponse,
  SaveContractLogV2ForAdminRequest,
  SaveContractLogV2ForAdminResponse,
  SaveContractV2ForAdminRequest,
  SaveContractV2ForAdminResponse,
  SearchContractPlansV2ForAdminRequest,
  SearchContractPlansV2ForAdminResponse,
  SendCreditCardRegisterFormForAdminRequest,
  SendCreditCardRegisterFormForAdminResponse,
  SpacePaymentFrequency,
  TaxV2,
  UPDATE_SAISON_INVOICE_DESTINATION_INFO_FOR_ADMIN,
  UnitV2,
  UpdateSaisonInvoiceDestinationInfoForAdminRequest,
  UpdateSaisonInvoiceDestinationInfoForAdminResponse,
  User,
  isBaseFunctionToggleEnabled
} from '@atomica.co/irori';
import {
  Address,
  City,
  Count,
  Day,
  Email,
  Id,
  Message,
  Name,
  NoStr,
  Phone,
  Point,
  Post,
  Remarks
} from '@atomica.co/types';
import {
  EMPTY,
  MINUS_ONE,
  ONE,
  Prefecture,
  ZERO,
  builder,
  embedIdInPath,
  hasLength,
  isGreaterThan,
  isGreaterThanZero,
  isLessThanZero,
  toBeginningOfDay,
  toEndOfDay,
  toFirstDayOfMonth,
  uuid
} from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { HEADER_HEIGHT } from '../../constants/common-const';
import { MASTER_SEARCH_LIMIT } from '../../constants/contract-v2-const';
import {
  DetailForContractRegisterScreen,
  PlanedBillingAmounts,
  toContractDetailsFromExistingContractDetails,
  toDefaultDiscountDetailsFromPlan,
  toDiscountDetailsFromExistingContractDetails,
  toInitialDisplayContractDetails,
  toPlanedBillingAmounts
} from '../../converters/contract-v2-converter';
import { DisplayTaxes, toDisplayTaxes } from '../../converters/tax-converter';
import useCachedSearchContractInfo, { CachedSearchContractInfo } from '../../redux/hooks/useCachedContractList';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import usePath from '../../redux/hooks/usePath';
import CommonRequest from '../../requests/common-request';
import { PATH_IDS, Path } from '../../router/Routes';
import { createContractLogsFromContract, createContractLogsFromContractDetail } from '../../utils/contract-log-v2-util';
import {
  contractDetailValidater,
  contractValidater,
  getContractFamilyName,
  getContractFirstName
} from '../../utils/contract-v2-util';
import BasicInput from './contract-inputs/BasicInput';
import BasicInputV2, { BasicInputOnChanges, BasicInputValues } from './contract-inputs/BasicInputV2';
import PaymentInput from './contract-inputs/PaymentInput';
import PaymentInputV2, { PaymentInputOnChanges, PaymentInputValues } from './contract-inputs/PaymentInputV2';
import PlanInput, { PlanInputOnChanges, PlanInputValues } from './contract-inputs/PlanInput';
import PlanedBillingDetails from './planed-billing-details/PlanedBillingDetails';

interface P {
  isDrawerOpen: boolean;
  base: BaseDto;
  user: User;
}

export type ValidateContracts = BasicInputValues & PaymentInputValues;

const REGISTER_CONTRACT_HEADER_HEIGHT = 40;
const REGISTER_CONTRACT_CONSOLE_FOOTER_HEIGHT = 64;

const RESISTER_CONTRACT_START_TOP = HEADER_HEIGHT + REGISTER_CONTRACT_HEADER_HEIGHT;

const RegisterContractScreen: React.FC<P> = React.memo(props => {
  const { base, user, isDrawerOpen } = props;
  const { openPath, path, params } = usePath();
  const { commonRequest } = useCommonRequest();

  const { cachedSearchContractInfo, saveCachedSearchContractInfo, clearCachedSearchContractInfo } =
    useCachedSearchContractInfo();
  const contractList = useRef<CachedSearchContractInfo>(cachedSearchContractInfo);

  const isEdit = useMemo<boolean>(() => path === Path.EDIT_CONTRACT_V2, [path]);

  const contractId = useMemo<ContractIdV2>(() => {
    return isEdit ? params[CONTRACT_ID_V2] : uuid();
  }, [isEdit, params]);

  const unmountRef = useUnmountRef();
  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, false);
  const [emptyErrorMessage, setEmptyErrorMessage] = useSafeState<Message>(unmountRef, EMPTY);
  const [invalidErrorMessage, setInvalidErrorMessage] = useSafeState<Message>(unmountRef, EMPTY);
  const [noSelectedPlanMessage, setNoSelectedPlanMessage] = useSafeState<Message>(unmountRef, EMPTY);

  const backToContractListOrContractDetails = useSafeCallback((): void => {
    saveCachedSearchContractInfo(contractList.current);
  }, [contractList, saveCachedSearchContractInfo]);

  const forwardToContractDetails = useSafeCallback(
    (contractId: ContractIdV2): void => {
      isEdit
        ? saveCachedSearchContractInfo(contractList.current)
        : saveCachedSearchContractInfo({
            contracts: contractList.current.contracts,
            selectedContractIds: [contractId],
            offset: contractList.current.offset,
            searchingWord: contractList.current.searchingWord,
            sortKey: contractList.current.sortKey,
            sort: contractList.current.sort,
            totalRecordCount: contractList.current.totalRecordCount
              ? contractList.current.totalRecordCount + ONE
              : contractList.current.contracts.length + ONE
          });
      openPath(embedIdInPath(Path.CONTRACT_DETAILS_V2_DETAIL, PATH_IDS, [base.baseCode, contractId]));
    },
    [path, base, openPath, saveCachedSearchContractInfo]
  );

  useEffect(() => {
    return clearCachedSearchContractInfo();
  }, [clearCachedSearchContractInfo]);

  const breadcrumbs = useMemo(
    (): Breadcrumb[] => [
      {
        label: '戻る',
        path: isEdit
          ? embedIdInPath(Path.CONTRACT_DETAILS_V2_DETAIL, PATH_IDS, [base.baseCode, contractId])
          : embedIdInPath(Path.CONTRACT_LIST_V2, PATH_IDS, [base.baseCode]),
        handlePathChanged: backToContractListOrContractDetails
      }
    ],
    [isEdit, contractId, backToContractListOrContractDetails, base]
  );

  // basic input
  const [contract, setContract] = useSafeState<ContractV2>(unmountRef);
  const [contractNo, setContractNo] = useSafeState<NoStr>(unmountRef, EMPTY);
  const [startDate, setStartDate] = useSafeState<Date>(unmountRef, new Date());
  const [endDate, setEndDate] = useSafeState<Date | null>(unmountRef, null);
  const [contractorType, setContractorType] = useSafeState<ContractorType>(unmountRef, ContractorType.ENTITY);
  const [entityName, setEntityName] = useSafeState<Name>(unmountRef, EMPTY);
  const [familyName, setFamilyName] = useSafeState<Name>(unmountRef, EMPTY);
  const [firstName, setFirstName] = useSafeState<Name>(unmountRef, EMPTY);
  const [familyNameKana, setFamilyNameKana] = useSafeState<Name>(unmountRef, EMPTY);
  const [firstNameKana, setFirstNameKana] = useSafeState<Name>(unmountRef, EMPTY);
  const [representativeFamilyName, setRepresentativeFamilyName] = useSafeState<Name>(unmountRef, EMPTY);
  const [representativeFirstName, setRepresentativeFirstName] = useSafeState<Name>(unmountRef, EMPTY);
  const [representativeFamilyNameKana, setRepresentativeFamilyNameKana] = useSafeState<Name>(unmountRef, EMPTY);
  const [representativeFirstNameKana, setRepresentativeFirstNameKana] = useSafeState<Name>(unmountRef, EMPTY);
  const [contractorFamilyName, setContractorFamilyName] = useSafeState<Name>(unmountRef, EMPTY);
  const [contractorFirstName, setContractorFirstName] = useSafeState<Name>(unmountRef, EMPTY);
  const [contractorFamilyNameKana, setContractorFamilyNameKana] = useSafeState<Name>(unmountRef, EMPTY);
  const [contractorFirstNameKana, setContractorFirstNameKana] = useSafeState<Name>(unmountRef, EMPTY);
  const [contractorPost, setContractorPost] = useSafeState<Post>(unmountRef, EMPTY);
  const [contractorAddress, setContractorAddress] = useSafeState<Address>(unmountRef, EMPTY);
  const [contractorPrefecture, setContractorPrefecture] = useSafeState<Prefecture | undefined>(unmountRef);
  const [contractorCity, setContractorCity] = useSafeState<City>(unmountRef, EMPTY);
  const [billingPost, setBillingPost] = useSafeState<Post>(unmountRef, EMPTY);
  const [billingPrefecture, setBillingPrefecture] = useSafeState<Prefecture | undefined>(unmountRef);
  const [billingCity, setBillingCity] = useSafeState<City>(unmountRef, EMPTY);
  const [billingAddress, setBillingAddress] = useSafeState<Address>(unmountRef, EMPTY);
  const [email, setEmail] = useSafeState<Email>(unmountRef, EMPTY);
  const [phone, setPhone] = useSafeState<Phone>(unmountRef, EMPTY);
  const [remarks, setRemarks] = useSafeState<Remarks>(unmountRef, EMPTY);
  const [inFlowSource, setInFlowSource] = useSafeState<InflowSource>(unmountRef, InflowSource.ORGANIC);
  const [isSameBillingAddressAndContractorAddress, setIsSameBillingAddressAndContractorAddress] = useSafeState<boolean>(
    unmountRef,
    true
  );
  const [customerId, setCustomerId] = useSafeState<Id>(unmountRef, EMPTY);
  const [isInitialized, setIsInitialized] = useSafeState<boolean>(unmountRef, false);

  const basicInputValues: BasicInputValues = useMemo(() => {
    return {
      contractNo,
      startDate,
      endDate,
      contractorType,
      entityName,
      familyName,
      firstName,
      familyNameKana,
      firstNameKana,
      representativeFamilyName,
      representativeFirstName,
      representativeFamilyNameKana,
      representativeFirstNameKana,
      contractorFamilyName,
      contractorFirstName,
      contractorFamilyNameKana,
      contractorFirstNameKana,
      contractorPost,
      contractorAddress,
      contractorPrefecture,
      contractorCity,
      email,
      phone,
      remarks,
      inFlowSource
    };
  }, [
    contractNo,
    startDate,
    endDate,
    contractorType,
    entityName,
    familyName,
    firstName,
    familyNameKana,
    firstNameKana,
    representativeFamilyName,
    representativeFirstName,
    representativeFamilyNameKana,
    representativeFirstNameKana,
    contractorFamilyName,
    contractorFirstName,
    contractorFamilyNameKana,
    contractorFirstNameKana,
    contractorPost,
    contractorAddress,
    contractorPrefecture,
    contractorCity,
    email,
    phone,
    remarks,
    inFlowSource
  ]);

  const handleContractorPostChanged = useSafeCallback(
    (contractorPost: Post) => {
      setContractorPost(contractorPost);
      if (isSameBillingAddressAndContractorAddress) {
        setBillingPost(contractorPost);
      }
    },
    [isSameBillingAddressAndContractorAddress, setContractorPost, setBillingPost]
  );
  const handleContractorPrefectureChanged = useSafeCallback(
    (contractorPrefecture: Prefecture | undefined) => {
      setContractorPrefecture(contractorPrefecture);
      if (isSameBillingAddressAndContractorAddress) {
        setBillingPrefecture(contractorPrefecture);
      }
    },
    [isSameBillingAddressAndContractorAddress, setContractorPrefecture, setBillingPrefecture]
  );

  const handleContractorCityChanged = useSafeCallback(
    (contractorCity: City) => {
      setContractorCity(contractorCity);
      if (isSameBillingAddressAndContractorAddress) {
        setBillingCity(contractorCity);
      }
    },
    [isSameBillingAddressAndContractorAddress, setContractorCity, setBillingCity]
  );
  const handleContractorAddressChanged = useSafeCallback(
    (contractorAddress: Address) => {
      setContractorAddress(contractorAddress);
      if (isSameBillingAddressAndContractorAddress) {
        setBillingAddress(contractorAddress);
      }
    },
    [isSameBillingAddressAndContractorAddress, setContractorAddress, setBillingAddress]
  );

  const basicInputOnChanges = useMemo(
    (): BasicInputOnChanges => ({
      setContractNo,
      setStartDate,
      setEndDate,
      setContractorType,
      setEntityName,
      setFamilyName,
      setFirstName,
      setFamilyNameKana,
      setFirstNameKana,
      setRepresentativeFamilyName,
      setRepresentativeFirstName,
      setRepresentativeFamilyNameKana,
      setRepresentativeFirstNameKana,
      setContractorFamilyName,
      setContractorFirstName,
      setContractorFamilyNameKana,
      setContractorFirstNameKana,
      setContractorPost: handleContractorPostChanged,
      setContractorAddress: handleContractorAddressChanged,
      setContractorPrefecture: handleContractorPrefectureChanged,
      setContractorCity: handleContractorCityChanged,
      setEmail,
      setPhone,
      setRemarks,
      setInFlowSource,
      setCustomerId
    }),
    [
      setContractNo,
      setStartDate,
      setEndDate,
      setContractorType,
      setEntityName,
      setFamilyName,
      setFirstName,
      setFamilyNameKana,
      setFirstNameKana,
      setRepresentativeFamilyName,
      setRepresentativeFirstName,
      setRepresentativeFamilyNameKana,
      setRepresentativeFirstNameKana,
      setContractorFamilyName,
      setContractorFirstName,
      setContractorFamilyNameKana,
      setContractorFirstNameKana,
      handleContractorPostChanged,
      handleContractorPrefectureChanged,
      handleContractorCityChanged,
      handleContractorAddressChanged,
      setEmail,
      setPhone,
      setRemarks,
      setInFlowSource,
      setCustomerId
    ]
  );

  // plan input
  const [plans, setPlans] = useSafeState<ContractPlanV2[]>(unmountRef, []);
  const [selectedPlan, setSelectedPlan] = useSafeState<ContractPlanV2 | undefined>(unmountRef);
  const [usersCount, setUsersCount] = useSafeState<Count>(unmountRef, ONE);
  const [grantedPoints, setGrantedPoints] = useSafeState<Point>(unmountRef, 0);
  const [pointGrantDate, setPointGrantedDate] = useSafeState<Day | null>(unmountRef, null);
  const [discountDetails, setDiscountDetails] = useSafeState<DetailForContractRegisterScreen[]>(unmountRef, []);
  const [monthlyContractDetails, setMonthlyContractDetails] = useSafeState<DetailForContractRegisterScreen[]>(
    unmountRef,
    []
  );
  const [atOnceContractDetails, setAtOnceContractDetails] = useSafeState<DetailForContractRegisterScreen[]>(
    unmountRef,
    []
  );
  const [displayTaxes, setDisplayTaxes] = useSafeState<DisplayTaxes>(unmountRef);
  const [units, setUnits] = useSafeState<UnitV2[]>(unmountRef, []);
  const [taxes, setTaxes] = useSafeState<TaxV2[]>(unmountRef, []);
  const [previousContract, setPreviousContract] = useSafeState<ContractV2>(unmountRef);
  const [previousContractDetails, setPreviousContractDetails] = useSafeState<ContractDetailV2[]>(unmountRef, []);

  const disabledSaveButton = useMemo<boolean>(() => {
    return !selectedPlan;
  }, [selectedPlan]);

  const planInputValues = useMemo(
    (): PlanInputValues => ({
      atOnceContractDetails,
      discountDetails,
      monthlyContractDetails,
      selectedPlan,
      usersCount,
      grantedPoints,
      pointGrantDate
    }),
    [
      atOnceContractDetails,
      discountDetails,
      monthlyContractDetails,
      selectedPlan,
      usersCount,
      grantedPoints,
      pointGrantDate
    ]
  );

  const handlePlanCleared = useSafeCallback((): void => {
    setAtOnceContractDetails([]);
    setDiscountDetails([]);
    setMonthlyContractDetails([]);
    setPlans([]);
    setSelectedPlan(undefined);
  }, [setAtOnceContractDetails, setDiscountDetails, setMonthlyContractDetails, setPlans, setSelectedPlan]);

  const handlePlanNameChanged = useSafeCallback(
    async (planName: Name): Promise<void> => {
      const request = builder<SearchContractPlansV2ForAdminRequest>()
        .baseId(base.baseId)
        .limit(MASTER_SEARCH_LIMIT)
        .offset(ZERO)
        .word(planName)
        .build();
      const response = await commonRequest<SearchContractPlansV2ForAdminRequest, SearchContractPlansV2ForAdminResponse>(
        SEARCH_CONTRACT_PLANS_V2_FOR_ADMIN,
        request
      );
      setPlans(response.contractPlans);
    },
    [base, commonRequest, setPlans]
  );

  const handleSelectPlanChanged = useSafeCallback(
    (option: MasterSearchOption): void => {
      const selectedPlan = plans.find(plan => plan.contractPlanId === option.value);
      const defaultContractDetails = toInitialDisplayContractDetails(selectedPlan);
      const defaultDiscountDetails = toDefaultDiscountDetailsFromPlan(selectedPlan);
      setAtOnceContractDetails(defaultContractDetails[RecurrencePattern.AT_ONCE]);
      setDiscountDetails(defaultDiscountDetails);
      setMonthlyContractDetails(defaultContractDetails[RecurrencePattern.MONTHLY]);
      setSelectedPlan(selectedPlan);
    },
    [plans, setAtOnceContractDetails, setDiscountDetails, setMonthlyContractDetails, setSelectedPlan]
  );

  const planInputOnChanges = useMemo(
    (): PlanInputOnChanges => ({
      setAtOnceContractDetails,
      setDiscountDetails,
      setMonthlyContractDetails,
      setUsersCount,
      setSelectedPlan: handleSelectPlanChanged,
      setGrantedPoints,
      setPointGrantedDate
    }),
    [
      handleSelectPlanChanged,
      setAtOnceContractDetails,
      setDiscountDetails,
      setMonthlyContractDetails,
      setUsersCount,
      setGrantedPoints,
      setPointGrantedDate
    ]
  );

  const initContract = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchContractV2ForAdminRequest>().baseId(base.baseId).contractId(contractId).build();
    const response = await commonRequest<FetchContractV2ForAdminRequest, FetchContractV2ForAdminResponse>(
      FETCH_CONTRACT_V2_FOR_ADMIN,
      request
    );
    setContract(response.contract);
  }, [base, commonRequest, contractId, setContract, setContractNo]);

  const initTaxes = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchTaxesV2ForAdminRequest>().baseId(base.baseId).build();
    const response = await commonRequest<FetchTaxesV2ForAdminRequest, FetchTaxesV2ForAdminResponse>(
      FETCH_TAXES_V2_FOR_ADMIN,
      request
    );
    setTaxes(response.taxes);
    setDisplayTaxes(toDisplayTaxes(response.taxes));
  }, [base, commonRequest, setTaxes, setDisplayTaxes]);

  const initUnits = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchUnitsV2ForAdminRequest>().baseId(base.baseId).build();
    const response = await commonRequest<FetchUnitsV2ForAdminRequest, FetchUnitsV2ForAdminResponse>(
      FETCH_UNITS_V2_FOR_ADMIN,
      request
    );
    setUnits(response.units);
  }, [base, commonRequest, setUnits]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    await Promise.all([initTaxes(), initUnits(), initContract()]);
    setIsInitialized(true);
  }, [initTaxes, initUnits, initContract, setIsInitialized]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (!contract || !hasLength(contract.contractDetails)) return;
    setPreviousContract(contract);
    setPreviousContractDetails(contract.contractDetails || []);

    const contractDetail = toContractDetailsFromExistingContractDetails(contract.contractDetails!);
    setAtOnceContractDetails(contractDetail.at_once);
    setDiscountDetails(toDiscountDetailsFromExistingContractDetails(contract.contractDetails!));
    setMonthlyContractDetails(contractDetail.monthly.filter(detail => detail.recurrenceCount === -1));
    setSelectedPlan(contract.contractPlan);
    setEntityName(contract.entityName ?? EMPTY);
  }, [
    contract,
    setAtOnceContractDetails,
    setDiscountDetails,
    setEntityName,
    setMonthlyContractDetails,
    setPreviousContract,
    setPreviousContractDetails,
    setSelectedPlan
  ]);

  //payment input
  const [paymentMethod, setPaymentMethod] = useSafeState<PaymentMethod>(unmountRef, PaymentMethod.CREDIT_CARD);
  const [paymentOption, setPaymentOption] = useSafeState<PaymentOption>(unmountRef, PaymentOption.SINGLE);
  const [paymentCount, setPaymentCount] = useSafeState<Count>(unmountRef, ONE);
  const [bankAccount, setBankAccount] = useSafeState<string>(unmountRef, EMPTY);
  const [spacePaymentFrequency, setSpacePaymentFrequency] = useSafeState<SpacePaymentFrequency>(
    unmountRef,
    SpacePaymentFrequency.MONTHLY_PAYMENT
  );
  const [invoiceDeliveryMethod, setInvoiceDeliveryMethod] = useSafeState<InvoiceDeliveryMethod>(
    unmountRef,
    InvoiceDeliveryMethod.NONE
  );

  const paymentInputValues: PaymentInputValues = useMemo(
    () => ({
      paymentMethod,
      isSameBillingAddressAndContractorAddress,
      paymentOption,
      paymentCount,
      bankAccount,
      billingPost,
      billingPrefecture,
      billingCity,
      billingAddress,
      spacePaymentFrequency,
      invoiceDeliveryMethod
    }),
    [
      paymentMethod,
      isSameBillingAddressAndContractorAddress,
      paymentOption,
      paymentCount,
      bankAccount,
      billingPost,
      billingPrefecture,
      billingCity,
      billingAddress,
      spacePaymentFrequency,
      invoiceDeliveryMethod
    ]
  );

  const paymentInputOnChanges: PaymentInputOnChanges = useMemo(
    () => ({
      setPaymentMethod,
      setIsSameBillingAddressAndContractorAddress,
      setPaymentOption,
      setPaymentCount,
      setBankAccount,
      setBillingPost,
      setBillingPrefecture,
      setBillingCity,
      setBillingAddress,
      setSpacePaymentFrequency,
      setInvoiceDeliveryMethod
    }),
    [
      setPaymentMethod,
      setIsSameBillingAddressAndContractorAddress,
      setPaymentOption,
      setPaymentCount,
      setBankAccount,
      setBillingPost,
      setBillingPrefecture,
      setBillingCity,
      setBillingAddress,
      setSpacePaymentFrequency,
      setInvoiceDeliveryMethod
    ]
  );

  const [taxIncludedTotalPrice, setTaxIncludedTotalPrice] = useSafeState(unmountRef, ZERO);
  const [taxExcludedTotalPrice, setTaxExcludedTotalPrice] = useSafeState(unmountRef, ZERO);

  useEffect(() => {
    if (!contract) return;
    const checked =
      contract.contractorPost === contract.billingPost && contract.contractorAddress === contract.billingAddress;
    setContractNo(contract.contractNo);
    setStartDate(contract.startDate);
    setEndDate(contract.endDate || null);
    setContractorType(contract.contractorType);
    setEntityName(contract.entityName ?? EMPTY);
    setFamilyName(getContractFamilyName(contract.representativeName));
    setFirstName(getContractFirstName(contract.representativeName));
    setFamilyNameKana(getContractFamilyName(contract.representativeNameKana));
    setFirstNameKana(getContractFirstName(contract.representativeNameKana));
    setRepresentativeFamilyName(getContractFamilyName(contract.representativeName));
    setRepresentativeFirstName(getContractFirstName(contract.representativeName));
    setRepresentativeFamilyNameKana(getContractFamilyName(contract.representativeNameKana));
    setRepresentativeFirstNameKana(getContractFirstName(contract.representativeNameKana));
    setContractorFamilyName(getContractFamilyName(contract.contractorName));
    setContractorFirstName(getContractFirstName(contract.contractorName));
    setContractorFamilyNameKana(getContractFamilyName(contract.contractorNameKana));
    setContractorFirstNameKana(getContractFirstName(contract.contractorNameKana));
    setContractorPost(contract.contractorPost);
    setContractorAddress(contract.contractorAddress);
    setContractorPrefecture(contract.contractorPrefecture);
    setContractorCity(contract.contractorCity);
    setBillingPost(checked ? contract.contractorPost : contract.billingPost);
    setBillingPrefecture(checked ? contract.contractorPrefecture : contract.billingPrefecture);
    setBillingCity(checked ? contract.contractorCity : contract.billingCity);
    setBillingAddress(checked ? contract.contractorAddress : contract.billingAddress);
    setEmail(contract.contractorEmail);
    setPhone(contract.contractorPhone ?? EMPTY);
    setRemarks(contract.remarks ?? EMPTY);
    setInFlowSource(contract.inflowSource);
    setIsSameBillingAddressAndContractorAddress(checked);
    setPaymentMethod(contract.paymentMethod);
    setPaymentCount(contract.paymentCount);
    setBankAccount(contract.bankAccount ?? EMPTY);
    setPaymentOption(
      contract.paymentMethod === PaymentMethod.CREDIT_CARD && contract.paymentCount > ONE
        ? PaymentOption.INSTALLMENT
        : PaymentOption.SINGLE
    );
    setUsersCount(contract.contractUserCount);
    setGrantedPoints(contract.grantedPoints || 0);
    setPointGrantedDate(contract.pointGrantDate || null);
    setTaxIncludedTotalPrice(contract.taxIncludedTotalPrice);
    setTaxExcludedTotalPrice(contract.taxExcludedTotalPrice);
    contract.customer?.customerId && setCustomerId(contract.customer?.customerId);
    setSpacePaymentFrequency(contract.spacePaymentFrequency ?? SpacePaymentFrequency.MONTHLY_PAYMENT);
    setInvoiceDeliveryMethod(contract.invoiceDeliveryMethod ?? InvoiceDeliveryMethod.NONE);
  }, [
    contract,
    setContractNo,
    setStartDate,
    setEndDate,
    setContractorType,
    setEntityName,
    setFamilyName,
    setFirstName,
    setFamilyNameKana,
    setFirstNameKana,
    setGrantedPoints,
    setRepresentativeFamilyName,
    setRepresentativeFirstName,
    setRepresentativeFamilyNameKana,
    setRepresentativeFirstNameKana,
    setContractorFamilyName,
    setContractorFirstName,
    setContractorFamilyNameKana,
    setContractorFirstNameKana,
    setContractorPost,
    setContractorAddress,
    setBillingPost,
    setBillingAddress,
    setEmail,
    setPhone,
    setPointGrantedDate,
    setRemarks,
    setInFlowSource,
    setPaymentMethod,
    setPaymentCount,
    setPaymentOption,
    setBankAccount,
    setUsersCount,
    setTaxIncludedTotalPrice,
    setTaxExcludedTotalPrice,
    setIsSameBillingAddressAndContractorAddress,
    setContractorPrefecture,
    setContractorCity,
    setBillingCity,
    setBillingPrefecture,
    setCustomerId,
    setSpacePaymentFrequency,
    setInvoiceDeliveryMethod
  ]);

  const getValidContractDetails = useSafeCallback(
    (details: DetailForContractRegisterScreen[]): DetailForContractRegisterScreen[] => {
      return details.filter(
        detail =>
          !!detail.itemName && !isLessThanZero(detail.unitPrice) && !isLessThanZero(detail.quantity) && detail.taxDiv
      );
    },
    []
  );

  const getValidDiscountDetails = useSafeCallback(
    (discounts: DetailForContractRegisterScreen[]): DetailForContractRegisterScreen[] => {
      return discounts.filter(
        discount =>
          !!discount.itemName &&
          isLessThanZero(discount.unitPrice) &&
          !!discount.recurrenceCount &&
          isGreaterThanZero(discount.recurrenceCount) &&
          discount.taxDiv
      );
    },
    []
  );

  const isPayWithStripe = useMemo((): boolean => {
    return isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USE_STRIPE);
  }, [base]);

  const isPayWithSaison = useMemo((): boolean => {
    return isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USE_SAISON);
  }, [base]);

  const isUseContractPayment = useMemo(
    (): boolean => isPayWithSaison || isPayWithStripe,
    [isPayWithSaison, isPayWithStripe]
  );

  const currentContract = useMemo((): ContractV2 => {
    const representativeName =
      contractorType === ContractorType.ENTITY
        ? `${representativeFamilyName} ${representativeFirstName}`
        : `${familyName} ${firstName}`;

    const representativeNameKana =
      contractorType === ContractorType.ENTITY
        ? `${representativeFamilyNameKana} ${representativeFirstNameKana}`
        : `${familyNameKana} ${firstNameKana}`;

    const contractorName =
      contractorType === ContractorType.ENTITY
        ? contractorFamilyName && contractorFirstName
          ? `${contractorFamilyName} ${contractorFirstName}`
          : `${representativeFamilyName} ${representativeFirstName}`
        : `${familyName} ${firstName}`;

    const contractorNameKana =
      contractorType === ContractorType.ENTITY
        ? contractorFamilyNameKana && contractorFirstNameKana
          ? `${contractorFamilyNameKana} ${contractorFirstNameKana}`
          : `${representativeFamilyNameKana} ${representativeFirstNameKana}`
        : `${familyNameKana} ${firstNameKana}`;

    const contract = builder<ContractV2>()
      .contractId(contractId)
      .contractNo(contractNo)
      .contractStatus(ContractStatusV2.CONFIRMED)
      .startDate(toBeginningOfDay(startDate)!)
      .contractPlan(selectedPlan!)
      .contractorType(contractorType)
      .entityName(entityName)
      .representativeName(representativeName)
      .representativeNameKana(representativeNameKana)
      .contractorName(contractorName)
      .contractorNameKana(contractorNameKana)
      .contractorPost(contractorPost)
      .contractorAddress(contractorAddress)
      .billingPost(billingPost)
      .billingAddress(billingAddress)
      .contractorEmail(email)
      .contractorPhone(phone)
      .remarks(remarks)
      .inflowSource(inFlowSource)
      .paymentCount(paymentOption === PaymentOption.INSTALLMENT ? paymentCount : ONE)
      .paymentMethod(paymentMethod)
      .bankAccount(bankAccount)
      .taxIncludedTotalPrice(taxIncludedTotalPrice)
      .taxExcludedTotalPrice(taxExcludedTotalPrice)
      .contractUserCount(usersCount)
      .grantedPoints(grantedPoints)
      .pointGrantDate(pointGrantDate)
      .base(base)
      .createdUser(user!)
      .updatedUser(user!);

    if (endDate) {
      contract.endDate(toEndOfDay(endDate)!);
    }

    if (PaymentMethod.CREDIT_CARD) {
      contract.spacePaymentFrequency(spacePaymentFrequency);
    }

    // NOTE: V1,V2では扱う値が異なるため、そのまま、下記パラメータを設定することができなかった。
    // そのため、V2に移行したタイミングで下記を修正し、上記`Buidler<>`のメソッドチェーンを利用してください。
    if (isUseContractPayment) {
      if (contractorPrefecture != undefined) {
        contract.contractorPrefecture(contractorPrefecture);
      }
      if (contractorCity != undefined) {
        contract.contractorCity(contractorCity);
      }
      if (billingPrefecture != undefined) {
        contract.billingPrefecture(billingPrefecture);
      }
      if (billingCity != undefined) {
        contract.billingCity(billingCity);
      }
      if (invoiceDeliveryMethod != undefined) {
        contract.invoiceDeliveryMethod(invoiceDeliveryMethod);
      }
      contract.customer(builder<Customer>().customerId(customerId).build());
    }

    return contract.build();
  }, [
    contractorType,
    representativeFamilyName,
    representativeFirstName,
    familyName,
    firstName,
    representativeFamilyNameKana,
    representativeFirstNameKana,
    familyNameKana,
    firstNameKana,
    contractorFamilyName,
    contractorFirstName,
    contractorFamilyNameKana,
    contractorFirstNameKana,
    contractId,
    contractNo,
    startDate,
    selectedPlan,
    entityName,
    contractorPost,
    contractorAddress,
    billingPost,
    billingAddress,
    email,
    phone,
    remarks,
    inFlowSource,
    paymentOption,
    paymentCount,
    paymentMethod,
    bankAccount,
    taxIncludedTotalPrice,
    taxExcludedTotalPrice,
    usersCount,
    grantedPoints,
    pointGrantDate,
    base,
    user,
    endDate,
    isUseContractPayment,
    spacePaymentFrequency,
    contractorPrefecture,
    contractorCity,
    billingPrefecture,
    billingCity,
    invoiceDeliveryMethod,
    customerId
  ]);
  const currentMonthlyContractDetails = useMemo((): DetailForContractRegisterScreen[] => {
    return getValidContractDetails(monthlyContractDetails);
  }, [monthlyContractDetails, getValidContractDetails]);

  const currentAtOnceContractDetails = useMemo((): DetailForContractRegisterScreen[] => {
    return getValidContractDetails(atOnceContractDetails);
  }, [atOnceContractDetails, getValidContractDetails]);

  const currentContractDetails = useMemo((): DetailForContractRegisterScreen[] => {
    return [...currentMonthlyContractDetails, ...currentAtOnceContractDetails];
  }, [currentMonthlyContractDetails, currentAtOnceContractDetails]);

  const currentDiscountDetails = useMemo((): DetailForContractRegisterScreen[] => {
    return getValidDiscountDetails(discountDetails);
  }, [discountDetails, getValidDiscountDetails]);

  const registerCustomer = useSafeCallback(async (): Promise<CustomerId | undefined> => {
    const customerType = contractorType === ContractorType.PERSON ? CustomerType.PERSON : CustomerType.CORPORATE;
    const customerName = contractorType === ContractorType.PERSON ? familyName.concat(firstName) : entityName;
    if (!customerName) return;
    const request = builder<CreateCustomerForAdminRequest>()
      .baseId(base.baseId)
      .customer(builder<Customer>().customerName(customerName).customerType(customerType).base(base).build())
      .build();
    const response = await commonRequest<CreateCustomerForAdminRequest, CreateCustomerForAdminResponse>(
      CREATE_CUSTOMER_FOR_ADMIN,
      request
    );
    setCustomerId(response.customer?.customerId);
    return response.customer?.customerId;
  }, [contractorType, familyName, firstName, entityName, base, commonRequest, setCustomerId]);

  const upsertSaisonInvoice = useSafeCallback(
    async (previousContract: ContractV2 | undefined, baseId: BaseId, contractId: ContractIdV2): Promise<void> => {
      if (previousContract?.saisonDestinationId) {
        try {
          const updateSaisonInvoiceDestinationInfo = builder<UpdateSaisonInvoiceDestinationInfoForAdminRequest>()
            .baseId(baseId)
            .contractId(contractId)
            .build();
          await commonRequest<
            UpdateSaisonInvoiceDestinationInfoForAdminRequest,
            UpdateSaisonInvoiceDestinationInfoForAdminResponse
          >(UPDATE_SAISON_INVOICE_DESTINATION_INFO_FOR_ADMIN, updateSaisonInvoiceDestinationInfo);
        } catch (e) {
          console.error(e);
        }
      } else {
        try {
          const createSaisonInvoiceRequest = builder<CreateSaisonInvoiceBuyerForAdminRequest>()
            .baseId(baseId)
            .contractId(contractId)
            .build();
          await CommonRequest.call<CreateSaisonInvoiceBuyerForAdminRequest, CreateSaisonInvoiceBuyerForAdminResponse>(
            CREATE_SAISON_INVOICE_BUYER_FOR_ADMIN,
            createSaisonInvoiceRequest
          );
        } catch (e) {
          console.error(e);
        }
      }
    },
    []
  );

  const saveContract = useSafeCallback(async () => {
    if (!user) return;

    let contractDetailEmptyErrorMessage: string[] = [];
    let contractDetailInvalidErrorMessage: string[] = [];

    const { emptyErrorMessage: contractEmptyErrorMessage, invalidErrorMessage: contractInvalidErrorMessage } =
      contractValidater({ ...basicInputValues, ...paymentInputValues }, isUseContractPayment);
    const { emptyErrorMessage, invalidErrorMessage } = contractDetailValidater(planInputValues);
    contractDetailEmptyErrorMessage = emptyErrorMessage;
    contractDetailInvalidErrorMessage = invalidErrorMessage;
    setNoSelectedPlanMessage(EMPTY);

    if (
      hasLength(contractEmptyErrorMessage) ||
      hasLength(contractDetailEmptyErrorMessage) ||
      hasLength(contractInvalidErrorMessage) ||
      hasLength(contractDetailInvalidErrorMessage)
    ) {
      setEmptyErrorMessage(contractEmptyErrorMessage.concat(contractDetailEmptyErrorMessage).join(', '));
      setInvalidErrorMessage(contractInvalidErrorMessage.concat(contractDetailInvalidErrorMessage).join(', '));
      if (!selectedPlan) setNoSelectedPlanMessage('プランを選択してください');
      return;
    }

    setIsLoaderShown(true);

    const contractReq: ContractV2 = currentContract;
    if (isUseContractPayment) {
      const beRegisterCustomerId = customerId || (await registerCustomer());
      if (!beRegisterCustomerId) return setEmptyErrorMessage('契約者');
      currentContract.customer = builder<Customer>().customerId(beRegisterCustomerId).build();
    }

    const contractRequest = builder<SaveContractV2ForAdminRequest>().baseId(base.baseId).contract(contractReq).build();

    const savedContract = await commonRequest<SaveContractV2ForAdminRequest, SaveContractV2ForAdminResponse>(
      SAVE_CONTRACT_V2_FOR_ADMIN,
      contractRequest
    );

    if (!savedContract.contractId) throw new Error();

    let displayOrder = ZERO;
    const monthlyContractDetailToSave = currentMonthlyContractDetails.map((detail: DetailForContractRegisterScreen) => {
      displayOrder += ONE;
      return builder<ContractDetailV2>()
        .contractDetailId(detail.contractDetailId ? detail.contractDetailId : uuid())
        .contract(currentContract)
        .isDisabled(detail.disabled)
        .isDeletable(detail.isDeletable)
        .item(detail.item!)
        .itemName(detail.itemName)
        .recurrenceCount(MINUS_ONE)
        .recurrencePattern(RecurrencePattern.MONTHLY)
        .displayOrder(displayOrder)
        .unitPrice(detail.unitPrice)
        .unitQuantity(detail.quantity)
        .taxDiv(detail.taxDiv!)
        .tax(taxes.find(tax => tax.taxId === detail.taxId)!)
        .unit(units.find(unit => unit.unitId === detail.unitId)!)
        .createdUser(user)
        .updatedUser(user)
        .build();
    });

    const atOnceContractDetailToSave = currentAtOnceContractDetails.map((detail: DetailForContractRegisterScreen) => {
      displayOrder += ONE;
      return builder<ContractDetailV2>()
        .contractDetailId(detail.contractDetailId ? detail.contractDetailId : uuid())
        .contract(currentContract)
        .isDisabled(detail.disabled)
        .isDeletable(detail.isDeletable)
        .item(detail.item!)
        .itemName(detail.itemName)
        .recurrenceCount(ONE)
        .recurrencePattern(RecurrencePattern.AT_ONCE)
        .displayOrder(displayOrder)
        .unitPrice(detail.unitPrice)
        .unitQuantity(detail.quantity)
        .taxDiv(detail.taxDiv!)
        .tax(taxes.find(tax => tax.taxId === detail.taxId)!)
        .unit(units.find(unit => unit.unitId === detail.unitId)!)
        .createdUser(user)
        .updatedUser(user)
        .build();
    });

    const discountContractDetailToSave = currentDiscountDetails.map((discount: DetailForContractRegisterScreen) => {
      displayOrder += ONE;
      return builder<ContractDetailV2>()
        .contractDetailId(discount.contractDetailId ? discount.contractDetailId : uuid())
        .contract(currentContract)
        .isDisabled(discount.disabled)
        .isDeletable(discount.isDeletable)
        .item(discount.item!)
        .itemName(discount.itemName)
        .recurrenceCount(discount.recurrenceCount || ZERO)
        .recurrencePattern(RecurrencePattern.MONTHLY)
        .displayOrder(displayOrder)
        .unitPrice(discount.unitPrice)
        .unitQuantity(discount.quantity || ONE)
        .taxDiv(discount.taxDiv!)
        .tax(taxes.find(tax => tax.taxId === discount.taxId)!)
        .unit(units.find(unit => unit.unitId === discount.unitId)!)
        .createdUser(user)
        .updatedUser(user)
        .build();
    });

    const contractDetailsToSave = [
      ...monthlyContractDetailToSave,
      ...atOnceContractDetailToSave,
      ...discountContractDetailToSave
    ];
    const contractDetailRequest = builder<SaveContractDetailsV2ForAdminRequest>()
      .baseId(base.baseId)
      .contractDetails(contractDetailsToSave)
      .build();
    const savedContractDetail = await commonRequest<
      SaveContractDetailsV2ForAdminRequest,
      SaveContractDetailsV2ForAdminResponse
    >(SAVE_CONTRACT_DETAILS_V2_FOR_ADMIN, contractDetailRequest);
    if (!hasLength(savedContractDetail.contractDetailIds)) throw new Error();

    const contractDetailsToDelete = previousContractDetails.filter(
      prev => !contractDetailsToSave.some(saved => saved.contractDetailId === prev.contractDetailId)
    );

    if (hasLength(contractDetailsToDelete)) {
      const contractDetailsDeleteRequest = builder<DeleteContractDetailsV2ForAdminRequest>()
        .baseId(base.baseId)
        .contractDetailIds(contractDetailsToDelete.map(detail => detail.contractDetailId))
        .build();
      const deletedContractDetails = await commonRequest<
        DeleteContractDetailsV2ForAdminRequest,
        DeleteContractDetailsV2ForAdminResponse
      >(DELETE_CONTRACT_DETAILS_V2_FOR_ADMIN, contractDetailsDeleteRequest);
      if (!hasLength(deletedContractDetails.contractDetailIds)) throw new Error();
    }

    const contractLogsFromContract = await createContractLogsFromContract(previousContract, currentContract, user);

    let contractLogsFromContractDetail: ContractLogV2[] = [];
    if (previousContract) {
      contractLogsFromContractDetail = await createContractLogsFromContractDetail(
        currentContract,
        previousContractDetails,
        contractDetailsToSave,
        user,
        units,
        taxes
      );
    }
    if (isUseContractPayment) {
      switch (contractRequest.contract.paymentMethod) {
        case PaymentMethod.CREDIT_CARD: {
          if (isPayWithStripe) {
            const sendCreditCardRegisterRequest = builder<SendCreditCardRegisterFormForAdminRequest>()
              .baseId(base.baseId)
              .contractId(savedContract.contractId)
              .build();
            await commonRequest<SendCreditCardRegisterFormForAdminRequest, SendCreditCardRegisterFormForAdminResponse>(
              SEND_CREDIT_CARD_REGISTER_FORM_FOR_ADMIN,
              sendCreditCardRegisterRequest
            );
          }
          break;
        }
        case PaymentMethod.BANK_TRANSFER:
        case PaymentMethod.INVOICE: {
          if (isPayWithSaison) {
            await upsertSaisonInvoice(previousContract, base.baseId, savedContract.contractId);
          }
          break;
        }
      }
    }

    if (path === Path.REGISTER_CONTRACT_V2 && currentContract.startDate < toFirstDayOfMonth(new Date(), ONE)) {
      const billingRequest = builder<CreateInitialBillingV2ForAdminRequest>()
        .baseId(base.baseId)
        .contractId(savedContract.contractId)
        .build();
      await commonRequest<CreateInitialBillingV2ForAdminRequest, CreateInitialBillingV2ForAdminResponse>(
        CREATE_INITIAL_BILLING_V2_FOR_ADMIN,
        billingRequest
      );
    }

    const contractLogToSave = builder<SaveContractLogV2ForAdminRequest>()
      .baseId(base.baseId)
      .contractLogs(contractLogsFromContract.concat(contractLogsFromContractDetail))
      .build();
    await commonRequest<SaveContractLogV2ForAdminRequest, SaveContractLogV2ForAdminResponse>(
      SAVE_CONTRACT_LOG_V2_FOR_ADMIN,
      contractLogToSave
    );

    forwardToContractDetails(currentContract.contractId);
    setIsLoaderShown(false);
  }, [
    user,
    basicInputValues,
    paymentInputValues,
    isUseContractPayment,
    planInputValues,
    setNoSelectedPlanMessage,
    setIsLoaderShown,
    contractorType,
    base.baseId,
    currentContract,
    commonRequest,
    currentMonthlyContractDetails,
    currentAtOnceContractDetails,
    currentDiscountDetails,
    previousContractDetails,
    previousContract,
    path,
    forwardToContractDetails,
    setEmptyErrorMessage,
    setInvalidErrorMessage,
    selectedPlan,
    registerCustomer,
    taxes,
    units,
    isPayWithStripe,
    isPayWithSaison
  ]);

  // planed billing amount
  const planedMonthlyBillingAmounts = useMemo((): PlanedBillingAmounts => {
    return toPlanedBillingAmounts(
      currentMonthlyContractDetails,
      discountDetails.filter(discount => discount.recurrenceCount && isGreaterThan(discount.recurrenceCount, ONE))
    );
  }, [currentMonthlyContractDetails, discountDetails]);

  const planedAtOnceBillingAmounts = useMemo((): PlanedBillingAmounts => {
    const amounts = toPlanedBillingAmounts(
      currentContractDetails,
      discountDetails.filter(discount => discount.recurrenceCount)
    );
    setTaxExcludedTotalPrice(amounts.taxExcludedTotalPrice);
    setTaxIncludedTotalPrice(amounts.taxIncludedTotalPrice);
    return amounts;
  }, [currentContractDetails, discountDetails, setTaxExcludedTotalPrice, setTaxIncludedTotalPrice]);

  const handleCancelClicked = useSafeCallback(() => {
    saveCachedSearchContractInfo(contractList.current);
    isEdit
      ? openPath(embedIdInPath(Path.CONTRACT_DETAILS_V2_DETAIL, PATH_IDS, [base.baseCode, contractId]))
      : openPath(embedIdInPath(Path.CONTRACT_LIST_V2, PATH_IDS, [base.baseCode]));
  }, [isEdit, openPath, base, contractId, saveCachedSearchContractInfo]);

  return (
    <Component className='register-contract-screen' loading={!isInitialized} style={styleForComponent}>
      <Container>
        <Header isDrawerOpen={isDrawerOpen}>
          <BreadcrumbTrailWrapper>
            <BreadcrumbTrailV2 breadcrumbs={breadcrumbs} />
          </BreadcrumbTrailWrapper>
        </Header>
        <ContentFrame isDrawerOpen={isDrawerOpen}>
          <DetailGrid>
            <Title>{isEdit ? '契約の編集' : '契約の新規作成'}</Title>
            <InputWrapper>
              {isUseContractPayment ? (
                <BasicInputV2 base={base} values={basicInputValues} onChanges={basicInputOnChanges} isEdit={isEdit} />
              ) : (
                <BasicInput values={basicInputValues} onChanges={basicInputOnChanges} />
              )}
              <PlanInput
                base={base}
                displayTaxes={displayTaxes}
                units={units}
                values={planInputValues}
                onChanges={planInputOnChanges}
                plans={plans}
                handleSearchPlans={handlePlanNameChanged}
                handleClearPlan={handlePlanCleared}
              />
              {isUseContractPayment ? (
                <PaymentInputV2
                  contract={contract}
                  contractorPost={contractorPost}
                  contractorPrefecture={contractorPrefecture}
                  contractorCity={contractorCity}
                  contractorAddress={contractorAddress}
                  values={paymentInputValues}
                  onChanges={paymentInputOnChanges}
                />
              ) : (
                <PaymentInput
                  contract={contract}
                  contractorPost={contractorPost}
                  contractorAddress={contractorAddress}
                  values={paymentInputValues}
                  onChanges={paymentInputOnChanges}
                />
              )}
            </InputWrapper>
          </DetailGrid>
          <BillingGrid>
            <PlanedBillingContent>
              <div>
                <Title>請求予定額</Title>
                <DescriptionLabel>
                  従量課金の品目は含まれていません。利用状況に応じて請求金額は変動します。
                </DescriptionLabel>
              </div>
              {hasLength(currentContractDetails) ? (
                <>
                  <PlanedBillingDetails title='基本料金' planedBillingAmounts={planedMonthlyBillingAmounts} />
                  <PlanedBillingDetails title='初回請求' planedBillingAmounts={planedAtOnceBillingAmounts} />
                </>
              ) : (
                <>
                  <NoBillingMessage>表示する項目がありません</NoBillingMessage>
                  <NoBillingSubMessage>プランを選択すると、金額が反映されます</NoBillingSubMessage>
                </>
              )}
            </PlanedBillingContent>
          </BillingGrid>
        </ContentFrame>

        <Footer hasError={!!emptyErrorMessage || !!noSelectedPlanMessage || !!invalidErrorMessage}>
          {emptyErrorMessage && <ErrorMessage>{emptyErrorMessage}を入力してください</ErrorMessage>}
          {noSelectedPlanMessage && <ErrorMessage>{noSelectedPlanMessage}</ErrorMessage>}
          {invalidErrorMessage && <ErrorMessage>{invalidErrorMessage}の入力は不正です</ErrorMessage>}

          <ButtonWrapper>
            <ButtonV2 type='secondary' label='キャンセル' onClick={handleCancelClicked} />
            <ButtonV2
              type='primary'
              disabled={disabledSaveButton}
              label={isEdit ? '保存' : '作成'}
              onClick={saveContract}
            />
          </ButtonWrapper>
        </Footer>
      </Container>

      <ScreenLoaderV2 loading={isLoaderShown} />
    </Component>
  );
});

RegisterContractScreen.displayName = 'RegisterContractScreen';
export default RegisterContractScreen;

const styleForComponent: CSSProperties = {
  height: '100%'
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const FixedDiv = styled.div`
  position: fixed;
  right: 0;
  transition: ${themeV2.transitions.create(['width', 'right'], {
    easing: themeV2.transitions.easing.easeOut,
    duration: themeV2.transitions.duration.enteringScreen
  })};
  width: 100%;
`;

const Header = styled(FixedDiv)<{ isDrawerOpen: boolean }>`
  background-color: ${themeV2.mixins.v2.color.background.lightGray};
  top: ${HEADER_HEIGHT}px;
  height: ${REGISTER_CONTRACT_HEADER_HEIGHT}px;
`;
const BreadcrumbTrailWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 ${themeV2.mixins.v2.spacing * 3}px;
  display: flex;
  align-items: center;
`;
const ContentFrame = styled(FixedDiv)<{ isDrawerOpen: boolean }>`
  height: calc(100% - ${REGISTER_CONTRACT_CONSOLE_FOOTER_HEIGHT + REGISTER_CONTRACT_HEADER_HEIGHT}px);
  position: fixed;
  top: ${RESISTER_CONTRACT_START_TOP}px;
  padding-left: ${themeV2.mixins.v2.spacing * 3}px;
  display: grid;
  grid-template-areas: 'detail detail detail detail detail detail detail detail billing billing billing billing';
  grid-template-rows: max-content;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: ${themeV2.mixins.v2.spacing * 3}px;
`;
const GridArea = styled.div`
  border-radius: 0;
  height: calc(100vh - 152px);
  overflow: scroll;
  ${themeV2.mixins.v2.scrollbarInvisible};

  @supports (height: 1dvh) {
    height: calc(100dvh - 152px);
  }
`;
const DetailGrid = styled(GridArea)`
  grid-area: detail;
  padding: ${`${themeV2.mixins.v2.spacing * 2}px 0px ${themeV2.mixins.v2.spacing * 10}px`};
`;
const BillingGrid = styled(GridArea)`
  grid-area: billing;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
`;

const Content = styled.div`
  background-color: ${themeV2.mixins.v2.color.background.white};
  border-radius: 6px;
  padding: ${`${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px`};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: ${themeV2.mixins.v2.spacing * 2}px;
  gap: 20px;
`;
const PlanedBillingContent = styled(Content)`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  border-radius: 0;
  gap: ${themeV2.mixins.v2.spacing * 4}px;
  padding-bottom: ${themeV2.mixins.v2.spacing * 15}px;
`;

const Title = styled.div`
  ${themeV2.mixins.v2.typography.headLine.large};
  padding: ${`${themeV2.mixins.v2.spacing}px 0`};
`;

const DescriptionLabel = styled(Typography)`
  ${themeV2.mixins.v2.typography.body.medium};
  // to fix
  color: #666666;
  padding: ${themeV2.mixins.v2.spacing / 4}px 0;
`;

const NoBillingMessage = styled(Typography)`
  ${themeV2.mixins.v2.typography.title.medium};
  text-align: center;
  margin-top: ${themeV2.mixins.v2.spacing * 5}px;
`;

const NoBillingSubMessage = styled(NoBillingMessage)`
  ${themeV2.mixins.v2.typography.body.small};
  margin: ${themeV2.mixins.v2.spacing}px;
`;

const ErrorMessage = styled(Typography)`
  ${themeV2.mixins.v2.typography.body.small};
  color: ${themeV2.mixins.v2.color.font.red};
  text-align: center;
  margin: ${themeV2.mixins.v2.spacing * 2}px;
`;

const Footer = styled(FixedDiv)<{ hasError: boolean }>`
  bottom: 0;
  background-color: ${themeV2.mixins.v2.color.background.white};
  height: ${({ hasError }) =>
    hasError ? REGISTER_CONTRACT_CONSOLE_FOOTER_HEIGHT + 36 : REGISTER_CONTRACT_CONSOLE_FOOTER_HEIGHT}px;
  box-shadow: ${themeV2.mixins.v2.shadow.elevation5};
`;
