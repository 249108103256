import {
  Button,
  CommentBox,
  InputBox,
  MultiCheckBox,
  MultiCheckBoxOption,
  RadioBox,
  Scrollable,
  SelectBox,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef,
  VerticalEllipsisButton,
  VerticalEllipsisButtonOption
} from '@atomica.co/components';
import {
  BaseDto,
  COUNT_SHOT_USAGE,
  CountShotUsageRequest,
  CountShotUsageResponse,
  DELETE_SHOT_USAGE,
  DeleteShotUsageRequest,
  DeleteShotUsageResponse,
  FETCH_SHOT_CATEGORIES,
  FETCH_USER,
  FetchShotCategoriesRequest,
  FetchShotCategoriesResponse,
  FetchUserRequest,
  FetchUserResponse,
  SAVE_SHOT_USAGE,
  SaveShotUsageRequest,
  SaveShotUsageResponse,
  Shot,
  ShotCategory,
  ShotSubcategory,
  toLatestUserPath,
  User
} from '@atomica.co/irori';
import {
  Amount,
  Category,
  Code,
  Count,
  Label,
  Message,
  Name,
  NoStr,
  PersonInCharge,
  Quantity,
  Remarks,
  USER_ID,
  UserId
} from '@atomica.co/types';
import { builder, EMPTY, hasLength, isGreaterThanZero, ONE, scroll, uuid, ZERO } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Screen from '../../components/screen/Screen';
import { SUCCESS } from '../../constants/snackbar-const';
import { fromQueryParams, toShot, toShotPaymentMethodOptions } from '../../converters/shot-usage-converter';
import { Hour, NumberOfPeople } from '../../enums/common-enum';
import { ShotPaymentMethod } from '../../enums/shot-enum';
import { database } from '../../firebase';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import usePath from '../../redux/hooks/usePath';
import ShotPriceService from '../../services/shot-price-service';
import ShotQuantityService from '../../services/shot-quantity-service';
import { HOURS_LABELS, NUMBER_OF_PEOPLE_LABELS } from '../../texts/common-text';
import { NO_PRIVILEGE, NO_VISITOR } from '../../texts/error-message-text';
import { PAYMENT_METHOD_LABELS } from '../../texts/shot-text';
import { SHOT_USAGE_DELETED, SHOT_USAGE_SAVED, SHOT_USAGE_UPDATED } from '../../texts/snackbar-text';
import { getItemCode } from '../../utils/item-util';
import {
  getOneDayDiscountPrice,
  isQuantityFixed,
  isQuantityManual,
  toShotCategoryLabels,
  toShotCategoryOptions
} from '../../utils/shot-category-util';
import { isAdmin, toFullName } from '../../utils/user-util';
import mojaco_greeting from './../../assets/mojaco/mojaco_greeting.png';

const TOP = 'top';

const UPDATE_BUTTON_LABEL = '入力内容を更新する';

const CLOSE_WINDOW_LABEL = 'ウィンドウを閉じる';

const toCategory = (user: User): Category => {
  if (!user) return EMPTY;
  const category = user.companyName || user.userDiv?.userDivName;
  return category ? `${category}の` : EMPTY;
};

enum VerticalEllipsisButtonType {
  EDIT_USAGE = 'edit_usage',
  DELETE_USAGE = 'delete_usage'
}

const VERTICAL_ELLIPSIS_BUTTON_OPTIONS: VerticalEllipsisButtonOption[] = [
  {
    id: VerticalEllipsisButtonType.EDIT_USAGE,
    label: '入力内容を編集する'
  },
  {
    id: VerticalEllipsisButtonType.DELETE_USAGE,
    label: '入力内容を取り消す'
  }
];

interface P {
  base: BaseDto;
  user: User;
}

const ShotReceptionScreen: React.FC<P> = React.memo(props => {
  const { base, user: staff } = props;
  const unmountRef = useUnmountRef();
  const { queryParams } = usePath();
  const { enqueueSnackbar } = useSnackbar();
  const { commonRequest } = useCommonRequest();

  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [editable, setEditable] = useSafeState<boolean>(unmountRef, false);
  const [disabledAllButtons, setDisabledAllButtons] = useSafeState<boolean>(unmountRef, false);
  const [isUsageDeleted, setIsUsageDeleted] = useSafeState<boolean>(unmountRef, false);
  const [errorMessage, setErrorMessage] = useSafeState<Message>(unmountRef);
  const [categories, setCategories] = useSafeState<ShotCategory[]>(unmountRef, []);
  const [selectedCategory, setSelectedCategory] = useSafeState<ShotCategory | undefined>(unmountRef);
  const [selectedSubcategory, setSelectedSubcategory] = useSafeState<ShotSubcategory | undefined>(unmountRef);
  const [visitor, setVisitor] = useSafeState<User>(unmountRef);
  const [shotUsageCount, setShotUsageCount] = useSafeState<Count>(unmountRef);
  const [shotToSave, setShotToSave] = useSafeState<Shot>(unmountRef);
  const [cardNo, setCardNo] = useSafeState<NoStr>(unmountRef, EMPTY);
  const [conferenceUsageHours, setConferenceUsageHours] = useSafeState<Hour | undefined>(unmountRef, Hour.ONE);
  const [conferenceNumberOfPeople, setConferenceNumberOfPeople] = useSafeState<Count | undefined>(unmountRef);
  const [conferenceOneDayUsage, setConferenceOneDayUsage] = useSafeState<boolean>(unmountRef, false);
  const [conferenceOneDayNumberOfPeople, setConferenceOneDayNumberOfPeople] = useSafeState<Count | undefined>(
    unmountRef
  );
  const [receiptName, setReceiptName] = useSafeState<Name>(unmountRef, EMPTY);
  const [taxIncludedUnitPriceLabel, setTaxIncludedUnitPriceLabel] = useSafeState<Label>(unmountRef, EMPTY);
  const [taxIncludedUnitPrice, setTaxIncludedUnitPrice] = useSafeState<Amount>(unmountRef, ZERO);
  const [unitQuantityLabel, setUnitQuantityLabel] = useSafeState<Label>(unmountRef, EMPTY);
  const [unitQuantity, setUnitQuantity] = useSafeState<Quantity>(unmountRef, ZERO);
  const [taxIncludedOptionPriceLabel, setTaxIncludedOptionPriceLabel] = useSafeState<Label>(unmountRef, EMPTY);
  const [taxIncludedOptionPrice, setTaxIncludedOptionPrice] = useSafeState<Amount>(unmountRef, ZERO);
  const [optionQuantityLabel, setOptionQuantityLabel] = useSafeState<Label>(unmountRef, EMPTY);
  const [optionQuantity, setOptionQuantity] = useSafeState<Quantity>(unmountRef, ZERO);
  const [taxIncludedTotalPrice, setTaxIncludedTotalPrice] = useSafeState<Amount>(unmountRef, ZERO);
  const [totalPrice, setTotalPrice] = useSafeState<Amount>(unmountRef, ZERO);
  const [paymentMethod, setPaymentMethod] = useSafeState<ShotPaymentMethod>(unmountRef, ShotPaymentMethod.CASH);
  const [personInCharge, setPersonInCharge] = useSafeState<PersonInCharge>(unmountRef, EMPTY);
  const [remarks, setRemarks] = useSafeState<Remarks>(unmountRef, EMPTY);

  const [prevSelectedCategory, setPrevSelectedCategory] = useSafeState<ShotCategory | undefined>(unmountRef);
  const [prevSelectedSubcategory, setPrevSelectedSubcategory] = useSafeState<ShotSubcategory | undefined>(unmountRef);
  const [prevCardNo, setPrevCardNo] = useSafeState<NoStr>(unmountRef, EMPTY);
  const [prevConferenceUsageHours, setPrevConferenceUsageHours] = useSafeState<Hour | undefined>(unmountRef, Hour.ONE);
  const [prevConferenceNumberOfPeople, setPrevConferenceNumberOfPeople] = useSafeState<Count | undefined>(unmountRef);
  const [prevConferenceOneDayUsage, setPrevConferenceOneDayUsage] = useSafeState<boolean>(unmountRef, false);
  const [prevConferenceOneDayNumberOfPeople, setPrevConferenceOneDayNumberOfPeople] = useSafeState<Count | undefined>(
    unmountRef
  );
  const [prevReceiptName, setPrevReceiptName] = useSafeState<Name>(unmountRef, EMPTY);

  const initialize = useSafeCallback(async (): Promise<void> => {
    if (!isAdmin(staff, base.baseCode)) {
      setErrorMessage(NO_PRIVILEGE);
      return;
    }

    const visitorUserId: UserId = queryParams[USER_ID]!;
    const categoryRequest = builder<FetchShotCategoriesRequest>().baseCode(base.baseCode).build();
    const visitorRequest = builder<FetchUserRequest>().userId(visitorUserId).build();
    const countRequest = builder<CountShotUsageRequest>().userId(visitorUserId).build();

    const [categoryResponse, countResponse, visitorResponse] = await Promise.all([
      commonRequest<FetchShotCategoriesRequest, FetchShotCategoriesResponse>(FETCH_SHOT_CATEGORIES, categoryRequest),
      commonRequest<CountShotUsageRequest, CountShotUsageResponse>(COUNT_SHOT_USAGE, countRequest),
      commonRequest<FetchUserRequest, FetchUserResponse>(FETCH_USER, visitorRequest)
    ]);

    const categories = categoryResponse.categories;
    const count = countResponse.count;
    const visitor = visitorResponse.user;

    if (!visitor) {
      setErrorMessage(NO_VISITOR);
      return;
    }

    const { usageType, usageOption, conferenceUsageType } = queryParams;

    const selectedCategory = categories.find(category => !!category.item && category.item.itemCode === usageType);
    const selectedSubCategory =
      !!selectedCategory && hasLength(selectedCategory.subcategories)
        ? selectedCategory.subcategories!.find(
            category =>
              !!category.item &&
              (category.item.itemCode === usageOption || category.item.itemCode === conferenceUsageType)
          )
        : undefined;

    const shotToSave = fromQueryParams(queryParams, selectedCategory, selectedSubCategory, base, staff, visitor);

    const taxIncludedUnitLabel = ShotPriceService.getTaxIncludedBasePriceLabel(selectedCategory, selectedSubCategory);
    const unitQuantityLabel = ShotQuantityService.getBaseQuantityLabel(selectedCategory);
    const taxIncludedOptionPriceLabel = ShotPriceService.getTaxIncludedOptionPriceLabel(
      base,
      selectedCategory,
      selectedSubCategory,
      shotToSave.conferenceOneDayUsage
    );
    const optionQuantityLabel = ShotQuantityService.getOptionQuantityLabel(
      selectedCategory,
      shotToSave.conferenceOneDayUsage
    );

    setVisitor(visitor);
    setCategories(categories);
    setSelectedCategory(selectedCategory);
    setSelectedSubcategory(selectedSubCategory);
    setShotUsageCount(count);
    setShotToSave(shotToSave);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setConferenceUsageHours(shotToSave.conferenceUsageHours as any);
    setConferenceNumberOfPeople(shotToSave.conferenceNumberOfPeople);
    setConferenceOneDayUsage(!!shotToSave.conferenceOneDayUsage);
    setConferenceOneDayNumberOfPeople(shotToSave.conferenceOneDayNumberOfPeople);
    setReceiptName(shotToSave.receiptName!);
    setCardNo(shotToSave.cardNo!);
    setTaxIncludedUnitPriceLabel(taxIncludedUnitLabel);
    setTaxIncludedUnitPrice(shotToSave.taxIncludedUnitPrice!);
    setUnitQuantityLabel(unitQuantityLabel);
    setUnitQuantity(shotToSave.unitQuantity!);
    setTaxIncludedOptionPriceLabel(taxIncludedOptionPriceLabel);
    setTaxIncludedOptionPrice(shotToSave.taxIncludedOptionPrice!);
    setOptionQuantityLabel(optionQuantityLabel);
    setOptionQuantity(shotToSave.optionQuantity!);
    setTaxIncludedTotalPrice(shotToSave.taxIncludedTotalPrice!);
    setTotalPrice(shotToSave.taxIncludedTotalPrice!);
    setPaymentMethod(shotToSave.paymentMethod! as ShotPaymentMethod);
    setPersonInCharge(shotToSave.personInCharge!);
    setLoaded(true);

    const request = builder<SaveShotUsageRequest>().shot(shotToSave).build();
    const response = await commonRequest<SaveShotUsageRequest, SaveShotUsageResponse>(SAVE_SHOT_USAGE, request);

    if (response && response.shotId) {
      database.ref(toLatestUserPath(base.baseCode)).set({ userId: `${visitorUserId}-${uuid()}` });
      enqueueSnackbar(SHOT_USAGE_SAVED, { variant: SUCCESS });
    }
  }, [
    base,
    commonRequest,
    staff,
    queryParams,
    setErrorMessage,
    setVisitor,
    setCategories,
    setSelectedCategory,
    setSelectedSubcategory,
    setShotUsageCount,
    setShotToSave,
    setReceiptName,
    setCardNo,
    setConferenceUsageHours,
    setConferenceNumberOfPeople,
    setConferenceOneDayUsage,
    setConferenceOneDayNumberOfPeople,
    setTaxIncludedUnitPriceLabel,
    setTaxIncludedUnitPrice,
    setUnitQuantityLabel,
    setUnitQuantity,
    setTaxIncludedOptionPriceLabel,
    setTaxIncludedOptionPrice,
    setOptionQuantityLabel,
    setOptionQuantity,
    setTaxIncludedTotalPrice,
    setTotalPrice,
    setPaymentMethod,
    setPersonInCharge,
    setLoaded,
    enqueueSnackbar
  ]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    setShotToSave(prevShot => {
      if (!prevShot) return prevShot;

      const shotUsageToUpdate = toShot(
        prevShot.shotId,
        base,
        visitor,
        selectedCategory,
        selectedSubcategory,
        conferenceUsageHours,
        conferenceNumberOfPeople,
        conferenceOneDayUsage,
        conferenceOneDayNumberOfPeople,
        receiptName,
        cardNo,
        totalPrice,
        paymentMethod,
        personInCharge,
        remarks
      );

      const taxIncludedUnitLabel = ShotPriceService.getTaxIncludedBasePriceLabel(selectedCategory, selectedSubcategory);
      const unitQuantityLabel = ShotQuantityService.getBaseQuantityLabel(selectedCategory);
      const taxIncludedOptionPriceLabel = ShotPriceService.getTaxIncludedOptionPriceLabel(
        base,
        selectedCategory,
        selectedSubcategory,
        shotUsageToUpdate.conferenceOneDayUsage
      );
      const optionQuantityLabel = ShotQuantityService.getOptionQuantityLabel(
        selectedCategory,
        shotUsageToUpdate.conferenceOneDayUsage
      );

      setTaxIncludedUnitPriceLabel(taxIncludedUnitLabel);
      setTaxIncludedUnitPrice(shotUsageToUpdate.taxIncludedUnitPrice!);
      setUnitQuantityLabel(unitQuantityLabel);
      setUnitQuantity(shotUsageToUpdate.unitQuantity!);
      setTaxIncludedOptionPriceLabel(taxIncludedOptionPriceLabel);
      setTaxIncludedOptionPrice(shotUsageToUpdate.taxIncludedOptionPrice!);
      setOptionQuantityLabel(optionQuantityLabel);
      setOptionQuantity(shotUsageToUpdate.optionQuantity!);
      setTaxIncludedTotalPrice(shotUsageToUpdate.taxIncludedTotalPrice!);
      return shotUsageToUpdate;
    });
  }, [
    setShotToSave,
    base,
    visitor,
    selectedCategory,
    selectedSubcategory,
    conferenceUsageHours,
    conferenceNumberOfPeople,
    conferenceOneDayUsage,
    conferenceOneDayNumberOfPeople,
    receiptName,
    cardNo,
    totalPrice,
    paymentMethod,
    personInCharge,
    remarks,
    setTaxIncludedUnitPriceLabel,
    setTaxIncludedUnitPrice,
    setUnitQuantityLabel,
    setUnitQuantity,
    setTaxIncludedOptionPriceLabel,
    setTaxIncludedOptionPrice,
    setOptionQuantityLabel,
    setOptionQuantity,
    setTaxIncludedTotalPrice
  ]);

  const updateShotUsage = useSafeCallback(async (): Promise<void> => {
    const request = builder<SaveShotUsageRequest>().shot(shotToSave!).build();
    const response = await commonRequest<SaveShotUsageRequest, SaveShotUsageResponse>(SAVE_SHOT_USAGE, request);

    if (response.shotId) {
      database.ref(toLatestUserPath(base.baseCode)).set({ userId: `${shotToSave!.user!.userId}-${uuid()}` });
      enqueueSnackbar(SHOT_USAGE_UPDATED, { variant: SUCCESS });
    }

    setDisabledAllButtons(false);
  }, [base, commonRequest, enqueueSnackbar, setDisabledAllButtons, shotToSave]);

  const deleteShotUsage = useSafeCallback(async (): Promise<void> => {
    const request = builder<DeleteShotUsageRequest>().shotId(shotToSave!.shotId).build();
    await commonRequest<DeleteShotUsageRequest, DeleteShotUsageResponse>(DELETE_SHOT_USAGE, request);
    database.ref(toLatestUserPath(base.baseCode)).set({ userId: `${shotToSave!.user!.userId}-${uuid()}` });
    enqueueSnackbar(SHOT_USAGE_DELETED, { variant: SUCCESS });
    setDisabledAllButtons(false);
  }, [base, commonRequest, enqueueSnackbar, setDisabledAllButtons, shotToSave]);

  const handleVerticalEllipsisButtonClicked = useSafeCallback(
    (id: VerticalEllipsisButtonType): void => {
      switch (id) {
        case VerticalEllipsisButtonType.EDIT_USAGE:
          setPrevSelectedCategory(selectedCategory);
          setPrevSelectedSubcategory(selectedSubcategory);
          setPrevCardNo(cardNo);
          setPrevConferenceUsageHours(conferenceUsageHours);
          setPrevConferenceNumberOfPeople(conferenceNumberOfPeople);
          setPrevConferenceOneDayUsage(conferenceOneDayUsage);
          setPrevConferenceOneDayNumberOfPeople(conferenceOneDayNumberOfPeople);
          setPrevReceiptName(receiptName);
          setEditable(true);
          scroll(TOP);
          break;

        case VerticalEllipsisButtonType.DELETE_USAGE:
          setDisabledAllButtons(true);
          deleteShotUsage();
          setIsUsageDeleted(true);
          break;
      }
    },
    [
      cardNo,
      conferenceNumberOfPeople,
      conferenceOneDayUsage,
      conferenceOneDayNumberOfPeople,
      conferenceUsageHours,
      deleteShotUsage,
      receiptName,
      selectedCategory,
      selectedSubcategory,
      setDisabledAllButtons,
      setEditable,
      setIsUsageDeleted,
      setPrevCardNo,
      setPrevConferenceNumberOfPeople,
      setPrevConferenceOneDayUsage,
      setPrevConferenceOneDayNumberOfPeople,
      setPrevConferenceUsageHours,
      setPrevSelectedCategory,
      setPrevSelectedSubcategory,
      setPrevReceiptName
    ]
  );

  const handleCategoryChanged = useSafeCallback(
    (itemCode: Code): void => {
      const selectedCategory = categories.find(category => !!category.item && category.item.itemCode === itemCode);
      setSelectedCategory(selectedCategory);
      setSelectedSubcategory(undefined);
    },
    [categories, setSelectedCategory, setSelectedSubcategory]
  );

  const handleSubCategoryChanged = useSafeCallback(
    (itemCode: Code): void => {
      const selectedSubCategory = selectedCategory!.subcategories!.find(
        category => !!category.item && category.item.itemCode === itemCode
      );
      setSelectedSubcategory(selectedSubCategory);
    },
    [selectedCategory, setSelectedSubcategory]
  );

  const handleCancelButtonClicked = useSafeCallback((): void => {
    setSelectedCategory(prevSelectedCategory);
    setSelectedSubcategory(prevSelectedSubcategory);
    setCardNo(prevCardNo);
    setConferenceUsageHours(prevConferenceUsageHours);
    setConferenceNumberOfPeople(prevConferenceNumberOfPeople);
    setConferenceOneDayUsage(prevConferenceOneDayUsage);
    setConferenceOneDayNumberOfPeople(prevConferenceOneDayNumberOfPeople);
    setReceiptName(prevReceiptName);
    setEditable(false);
  }, [
    prevCardNo,
    prevConferenceNumberOfPeople,
    prevConferenceOneDayUsage,
    prevConferenceOneDayNumberOfPeople,
    prevConferenceUsageHours,
    prevReceiptName,
    prevSelectedCategory,
    prevSelectedSubcategory,
    setCardNo,
    setConferenceNumberOfPeople,
    setConferenceOneDayUsage,
    setConferenceOneDayNumberOfPeople,
    setConferenceUsageHours,
    setEditable,
    setReceiptName,
    setSelectedCategory,
    setSelectedSubcategory
  ]);

  const options = useMemo<MultiCheckBoxOption[]>(() => {
    return [
      {
        id: 'conference-one-day-usage',
        label: '1DAYも利用する',
        checked: conferenceOneDayUsage
      }
    ];
  }, [conferenceOneDayUsage]);

  return (
    <Screen loading={!loaded} errorMsg={errorMessage} className='reception-screen'>
      <Container>
        <Scrollable>
          <Content>
            {visitor && (
              <MojacoWrapper id={TOP}>
                <CommentBox animation photoURL={mojaco_greeting}>
                  <Greeting>
                    受付おつかれさま！{toCategory(visitor)}
                    {toFullName(visitor)}さんが来たよ！直近１ヶ月で
                    {shotUsageCount}回来てくれているよ！
                  </Greeting>
                </CommentBox>
              </MojacoWrapper>
            )}

            <RadioWrapper>
              <RadioBox
                editable={editable}
                title='本日のご利用内容'
                options={toShotCategoryOptions(categories)}
                labels={toShotCategoryLabels(categories)}
                value={selectedCategory ? getItemCode(selectedCategory.item) : undefined}
                onChange={handleCategoryChanged}
              />
            </RadioWrapper>

            {isQuantityFixed(selectedCategory) && (
              <>
                {hasLength(selectedCategory!.subcategories) && (
                  <RadioWrapper>
                    <RadioBox
                      editable={editable}
                      title='該当するものがあれば、お選びください'
                      options={toShotCategoryOptions(selectedCategory!.subcategories)}
                      labels={toShotCategoryLabels(selectedCategory!.subcategories)}
                      value={selectedSubcategory ? getItemCode(selectedSubcategory.item) : undefined}
                      onChange={handleSubCategoryChanged}
                    />
                  </RadioWrapper>
                )}

                {!!shotToSave && isGreaterThanZero(shotToSave.taxIncludedTotalPrice) && (
                  <InputWrapper>
                    <InputBox
                      editable={editable}
                      type='text'
                      label='領収書の宛名'
                      text={receiptName}
                      onChange={setReceiptName}
                    />
                  </InputWrapper>
                )}

                <InputWrapper>
                  <InputBox
                    required={false}
                    editable={editable}
                    maxLength={10}
                    type='number'
                    label='貸出カードNo.'
                    text={cardNo}
                    onChange={setCardNo}
                  />
                </InputWrapper>
              </>
            )}

            {isQuantityManual(selectedCategory) && (
              <>
                <RadioWrapper>
                  <RadioBox
                    editable={editable}
                    title='設備'
                    options={toShotCategoryOptions(selectedCategory!.subcategories)}
                    labels={toShotCategoryLabels(selectedCategory!.subcategories)}
                    value={selectedSubcategory ? getItemCode(selectedSubcategory.item) : undefined}
                    onChange={handleSubCategoryChanged}
                  />
                </RadioWrapper>

                {isQuantityManual(selectedSubcategory) && (
                  <>
                    <SelectWrapper>
                      <SelectBox
                        editable={editable}
                        title='利用時間'
                        options={Object.values(Hour)}
                        labels={HOURS_LABELS}
                        value={conferenceUsageHours}
                        onChange={setConferenceUsageHours}
                      />
                    </SelectWrapper>

                    <SelectWrapper>
                      <SelectBox
                        editable={editable}
                        title='利用人数'
                        options={Object.values(NumberOfPeople)}
                        labels={NUMBER_OF_PEOPLE_LABELS}
                        value={conferenceNumberOfPeople}
                        onChange={setConferenceNumberOfPeople}
                      />
                    </SelectWrapper>

                    {!!getOneDayDiscountPrice(base) && (
                      <CheckBoxWrapper>
                        <MultiCheckBox
                          editable={editable}
                          title='1DAYの併用有無'
                          options={options}
                          onChange={options => setConferenceOneDayUsage(options[ZERO].checked)}
                        />
                      </CheckBoxWrapper>
                    )}

                    {!!conferenceOneDayUsage && (
                      <>
                        <SelectWrapper>
                          <SelectBox
                            editable={editable}
                            title='1DAYの利用人数'
                            options={Object.values(NumberOfPeople)}
                            labels={NUMBER_OF_PEOPLE_LABELS}
                            value={conferenceOneDayNumberOfPeople}
                            onChange={setConferenceOneDayNumberOfPeople}
                          />
                        </SelectWrapper>
                      </>
                    )}

                    {!!shotToSave && isGreaterThanZero(shotToSave.taxIncludedTotalPrice) && (
                      <InputWrapper>
                        <InputBox
                          editable={editable}
                          type='text'
                          label='領収書の宛名'
                          text={receiptName}
                          onChange={setReceiptName}
                        />
                      </InputWrapper>
                    )}

                    <InputWrapper>
                      <InputBox
                        required={false}
                        editable={editable}
                        maxLength={10}
                        type='number'
                        label='貸出カードNo.'
                        text={cardNo}
                        onChange={setCardNo}
                      />
                    </InputWrapper>
                  </>
                )}

                {isQuantityFixed(selectedSubcategory) && (
                  <>
                    {!!shotToSave && isGreaterThanZero(shotToSave.taxIncludedTotalPrice) && (
                      <InputWrapper>
                        <InputBox
                          editable={editable}
                          type='text'
                          label='領収書の宛名'
                          text={receiptName}
                          onChange={setReceiptName}
                        />
                      </InputWrapper>
                    )}

                    <InputWrapper>
                      <InputBox
                        required={false}
                        editable={editable}
                        maxLength={10}
                        type='number'
                        label='貸出カードNo.'
                        text={cardNo}
                        onChange={setCardNo}
                      />
                    </InputWrapper>
                  </>
                )}
              </>
            )}

            <MojacoWrapper>
              <CommentBox animation photoURL={mojaco_greeting}>
                <Greeting>料金を計算したよ！</Greeting>
              </CommentBox>
            </MojacoWrapper>

            {!!taxIncludedUnitPrice && shotToSave && (
              <InputWrapper>
                <InputBox
                  editable={false}
                  type='text'
                  label={taxIncludedUnitPriceLabel}
                  text={taxIncludedUnitPrice}
                  unit={shotToSave.usageItem?.unit?.unitName}
                  onChange={setTaxIncludedUnitPrice}
                />
              </InputWrapper>
            )}

            {unitQuantity > ONE && (
              <InputWrapper>
                <InputBox
                  editable={false}
                  type='number'
                  label={unitQuantityLabel}
                  text={unitQuantity}
                  onChange={setUnitQuantity}
                />
              </InputWrapper>
            )}

            {!!taxIncludedOptionPrice && (
              <InputWrapper>
                <InputBox
                  editable={false}
                  type='number'
                  label={taxIncludedOptionPriceLabel}
                  text={taxIncludedOptionPrice}
                  onChange={setTaxIncludedOptionPrice}
                />
              </InputWrapper>
            )}

            {optionQuantity > ONE && (
              <InputWrapper>
                <InputBox
                  editable={false}
                  type='number'
                  label={optionQuantityLabel}
                  text={optionQuantity}
                  onChange={setOptionQuantity}
                />
              </InputWrapper>
            )}

            <InputWrapper>
              <InputBox
                editable={false}
                type='number'
                label='合計料金(税込)'
                text={taxIncludedTotalPrice}
                onChange={setTaxIncludedTotalPrice}
              />
            </InputWrapper>

            <InputWrapper>
              <InputBox
                required={true}
                editable={!isUsageDeleted}
                type='number'
                label='請求料金(税込)'
                text={totalPrice}
                onChange={setTotalPrice}
              />
            </InputWrapper>

            {isGreaterThanZero(totalPrice) && (
              <RadioWrapper>
                <RadioBox
                  editable={!isUsageDeleted}
                  title='支払い方法'
                  options={toShotPaymentMethodOptions(base.baseCode)}
                  labels={PAYMENT_METHOD_LABELS}
                  value={paymentMethod}
                  onChange={setPaymentMethod}
                />
              </RadioWrapper>
            )}

            <InputWrapper>
              <InputBox
                editable={!isUsageDeleted}
                type='text'
                label='担当者'
                text={personInCharge}
                onChange={setPersonInCharge}
              />
            </InputWrapper>

            <InputWrapper>
              <InputBox editable={!isUsageDeleted} type='text' label='備考' text={remarks} onChange={setRemarks} />
            </InputWrapper>

            {!editable && (
              <>
                <MultiButtonsArea>
                  <MultiButtonsWrapper>
                    {!isUsageDeleted && (
                      <ButtonWrapper>
                        <Button
                          disabled={disabledAllButtons}
                          type='primary'
                          onClick={() => {
                            setDisabledAllButtons(true);
                            updateShotUsage();
                          }}
                        >
                          <StyledLabel>{UPDATE_BUTTON_LABEL}</StyledLabel>
                        </Button>
                      </ButtonWrapper>
                    )}

                    <ButtonWrapper>
                      <Button disabled={disabledAllButtons} type='secondary' onClick={() => window.close()}>
                        <StyledLabel>{CLOSE_WINDOW_LABEL}</StyledLabel>
                      </Button>
                    </ButtonWrapper>
                  </MultiButtonsWrapper>

                  {!isUsageDeleted && (
                    <EllipsisWrapper>
                      <VerticalEllipsisButton
                        options={VERTICAL_ELLIPSIS_BUTTON_OPTIONS}
                        onClick={handleVerticalEllipsisButtonClicked}
                      />
                    </EllipsisWrapper>
                  )}
                </MultiButtonsArea>
              </>
            )}

            {editable && (
              <MultiButtonsArea>
                <MultiButtonsWrapper>
                  <ButtonWrapper>
                    <Button
                      disabled={disabledAllButtons}
                      type='primary'
                      onClick={() => {
                        setDisabledAllButtons(true);
                        setEditable(false);
                        updateShotUsage();
                      }}
                    >
                      <StyledLabel>保存する</StyledLabel>
                    </Button>
                  </ButtonWrapper>
                  <ButtonWrapper>
                    <Button disabled={disabledAllButtons} type='secondary' onClick={() => handleCancelButtonClicked()}>
                      <StyledLabel>キャンセル</StyledLabel>
                    </Button>
                  </ButtonWrapper>
                </MultiButtonsWrapper>
              </MultiButtonsArea>
            )}

            <BottomArea />
          </Content>
        </Scrollable>
      </Container>
    </Screen>
  );
});

ShotReceptionScreen.displayName = 'ShotReceptionScreen';
export default ShotReceptionScreen;

const Container = styled.div`
  width: 100vw;
  height: 100vh;

  @supports (height: 1dvh) {
    height: 100dvh;
  }
`;

const Content = styled.div`
  max-width: 768px;
  margin: 0 auto;
`;

const MojacoWrapper = styled.div`
  margin: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 4}px ${theme.mixins.spacing}px;
`;

const Greeting = styled(Typography)`
  width: calc(100% - ${theme.mixins.spacing * 3}px);
  height: auto;
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  margin: ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
  ${theme.mixins.underline};
`;

const RadioWrapper = styled.div`
  width: calc(100% - ${theme.mixins.spacing * 8}px);
  height: auto;
  margin: ${theme.mixins.spacing}px ${theme.mixins.spacing * 4}px;
`;

const SelectWrapper = styled.div`
  width: calc(100% - ${theme.mixins.spacing * 8}px);
  height: auto;
  margin: ${theme.mixins.spacing}px ${theme.mixins.spacing * 4}px;
`;

const InputWrapper = styled.div`
  width: calc(100% - ${theme.mixins.spacing * 8}px);
  height: auto;
  margin: ${theme.mixins.spacing}px ${theme.mixins.spacing * 4}px;
`;

const CheckBoxWrapper = styled.div`
  width: calc(100% - ${theme.mixins.spacing * 8}px);
  height: auto;
  margin: ${theme.mixins.spacing}px ${theme.mixins.spacing * 4}px;
`;

const MultiButtonsArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const MultiButtonsWrapper = styled.div`
  width: ${window.innerWidth > 440 ? '384px' : '200px'};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: ${theme.mixins.spacing}px;
`;

const ButtonWrapper = styled.div`
  margin: ${theme.mixins.spacing / 2}px;
`;

const EllipsisWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding-bottom: ${theme.mixins.spacing}px;
`;

const StyledLabel = styled(Typography)`
  width: 160px;
  height: auto;
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
`;

const BottomArea = styled.div`
  width: 100%;
  height: 120px;
`;
